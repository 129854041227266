import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import * as OwlCarousel from 'ngx-owl-carousel-o';
import { ApiService } from 'src/app/services/authService/auth-service.service';

@Component({
	selector: 'app-traveller-fare',
	templateUrl: './traveller-fare.component.html',
	styleUrls: ['./traveller-fare.component.scss']
})
export class TravellerFareComponent implements OnInit {

	@Input() flightData;
	@Input() legs;
	@Input() adultBaseFare;
	@Input() adultTaxFare;
	@Input() adultTotalFare;
	@Input() childBaseFare;
	@Input() childTaxFare;
	@Input() childTotalFare;
	@Input() infBaseFare;
	@Input() infTaxFare;
	@Input() infTotalFare;
	@Input() noOfAdult;
	@Input() noOfChild;
	@Input() noOfInfant;
	@Input() seatCharges;
	@Input() mealCharges;
	@Input() baggageCharges;
	@Input() additionalBaggageCharges = 0;
	@Input() anicillaryCharges = 0;
	@Input() netTotalayable;
	@Input() affiliateMarkup;
	@Input() totalServiceCharge;
	@Input() totalGst;

	@Input() netCommission;
	@Input() tds;
	@Input() netPayable;
	@Input() airlineCode;
	@Input() isOffer;
	@Input() totalServiceFee;
	toggleFare : boolean = true;
	toggleTotalFare : boolean = true;
	toggleAdult : boolean = true;
	toggleChild : boolean = false;
	toggleInfant : boolean = false;
	toggleReIssue : boolean = true;
	@Input() direction;
	@Input() isRoundTrip : boolean;
	@Input() isSpecialRoundTrip : boolean;


	@Input() isReissue : boolean;
	@Input() oldBookingAmount;
	@Input() reIssueData ;
	@Input() insuranceAmountPerPerson ;
	@Input() totalInsuranceAmount ;

	promoCodes: any[] = [];
	appliedPromoCode: string | null = null;
	errorMessage:any;
	promoCodeInput: string = '';
	promoCodeApplied: boolean = false;
	@Input() totalPayable;
	@Output() totalPayableChanged = new EventEmitter<number>();
	@Output() discountApplied = new EventEmitter<any>();
	discountAmount: number = 0;
	// private originalTotal: number;
	showTooltip: boolean = false;

	constructor(
		private commonService: CommonService,
        private apiService: ApiService,

	) 
		{ }

	ngOnInit() {
		this.apiService.promoCodes().subscribe((response: any) => {
			this.promoCodes = response;			
		});
	}

	// function to get date from datetime
	returnDate(date) {
		let d = new Date(date.split("+")[0]);
		let a = d.toDateString().split(' ');
		return a[1] + " "+a[2] + " " + a[3];
		// return d.toDateString()
	}
	// function to get time from datatime
	returnTime(date) {
		let d = new Date(date.split("+")[0]);
		return d.toTimeString().split(' ')[0];
	}

	toggleFareDetails() {
		this.toggleFare = !this.toggleFare;
	}
	toggleTotalFareDetails() {
		this.toggleTotalFare = !this.toggleTotalFare;
		this.toggleFare = true
	}
	toggleAdultDetails() {
		this.toggleAdult = !this.toggleAdult;
		this.toggleFare = true
	}
	toggleChildDetails() {
		this.toggleChild = !this.toggleChild;
		this.toggleFare = true
	}
	toggleInfantDetails() {
		this.toggleInfant = !this.toggleInfant;
		this.toggleFare = true
	}
	toggleReIssueDetails() {
		this.toggleReIssue = !this.toggleReIssue;
	}
	getDuration(dt1, dt2) {
		return this.commonService.timeDuration(dt2, dt1);
	}
	// slider for multiple city traveling
		multicitySlider = {
		loop: true,
		mouseDrag: true,
		touchDrag: true,
		pullDrag: true,
		dots: true,
		// nav: false,
		autoplayHoverPause:true,
		autoplay: true,
		navSpeed: 700,
		navText: ['', ''],
		responsive: {
		  0: {
			items: 1
		  }
		},
	  }	  
	 
	  travellerFareSlider = {
		loop: false,
		mouseDrag: true,
		touchDrag: true,
		pullDrag: true,
		dots: false,
		nav: true,
		autoplayHoverPause:true,
		autoplay: true,
		navSpeed: 700,
		navText: ['', ''],
		responsive: {
		  0: {
			items: 1
		  },
		  480: {
			items: 1
		  },
		  1400: {
			items: 1
		  }
		},
	  }	 

	applyPromoCode() {
		const data = {
			promo_code: this.promoCodeInput,
			totalPayable: this.totalPayable
		}
		this.errorMessage = null;
		this.apiService.applyPromoCodes(data)
		  .subscribe(
			(response:any) => {
			  this.discountAmount = response.discountAmount;
			  this.promoCodeApplied = response.promoCodeApplied;
				  this.discountApplied.emit({
					  discountAmount: this.discountAmount,
					  promoCodeApplied: this.promoCodeApplied,
				  });
			},
			error => {
			  this.showErrorMessage(error.error.message);
			  this.promoCodeApplied = false;
			}
		  );
	  }

	  showErrorMessage(message: string) {
		this.errorMessage = message;
		setTimeout(() => {
			this.errorMessage = null;
		}, 300000);
	}

	onPromoCodeInputChange(value: string) {
		this.promoCodeInput = value.toUpperCase();
	}

	unapplyPromoCode() {
		this.appliedPromoCode = null;
		this.promoCodeApplied = false; 
		this.promoCodeInput = '';
		this.discountAmount = 0;
		this.discountApplied.emit({
			discountAmount : this.discountAmount,
			promoCodeApplied : this.promoCodeApplied
		}); 
	}

}
