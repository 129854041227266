import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { CommonService } from './services/common.service';
import { environment } from '../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';


import * as moment from 'moment-timezone';
import { VersionCheckService } from './version-check.service';
import { WebsocketService } from './services/websocket.service';
import { CentrifugeService } from "src/app/services/centrifuge.service";
import { CookiesService } from './services/cookie.service';
import { BookingRequestService } from "src/app/services/booking-request.service";
import { getCurrentBrowserFingerPrint } from '@rajesh896/broprint.js';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	title = 'app';
	public loading = false;
	public previousUrl: string = undefined;
	public currentUrl: string = undefined;
	customLoadingTemplate;
	env = environment;
	plateform = window.navigator.platform;
	isHeader = true;
	showFooter = true;
	isLogin = false
	appVersionFromApi;
	showStrip = false
	isModalOpen = false
	userInfo: any
	@ViewChild('newRelease') openModal:ElementRef;
	// @ViewChild('closeModal') closeModal:ElementRef;
	currentVersion;
	myAppURL;
	@ViewChild('refreshForm') refreshForm: ElementRef;

	constructor(private commonService: CommonService, private route:Router, public vs : VersionCheckService,
    private centrifugeService: CentrifugeService,
	private bookingRequestService: BookingRequestService,
	private webSocketService : WebsocketService,private cookiesService:CookiesService) {
		if(window.location.href.includes('template')) {
			this.isHeader = false;
		}
		else{
			this.isHeader = true;
		}
		// let userDetail = {"additional_data":{},"address1":"JP KOSMOS","address2":"","city":6217,"compType":"Proprietorship","companyName":"OPSKUBE TRAVEL","country":101,"firstName":"DAUD","gstNum":"06AABCZ2601E1ZT","lastName":"MEHDI","mobile":"8777364622","phone":"9836806237","pincode":"201302","primaryEmail":"PRERAK@OPSKUBE.COM","secondaryEmail":"PINTU@OPSKUBE.COM,PRINCE@OPSKUBE.COM","state":265,"status":"Active","tdsExmptionRate":"","lookBookRatio":"","title":"Mr.","website":"","xlAccountCode":"CO000N","panNumber":"ABCDE2435F","createdBy":1,"createdDate":"2024-02-13T23:17:49.000Z","affiliateId":135,"groupId":17,"groupName":"EXCLUSIVE_NEW","country_name":"India","state_name":"Kerala","city_name":"Aroor","is_di_account":"false","is_whitelabel":"false","enabled_group_fare":"true","is_sub_affiliate":false,"logo_url":"https://mis-portal-docs.s3.ap-south-1.amazonaws.com/affilaite-135/indigo.png","emulateKey":"b98fcbeb43998cbdad316d17cb6a3437ca4393d50a8331dd668182a11fe6f4f1cd94b9667ad4412f72e6deb7fb2f9933fd0864bc6e58667bfe5f871c0f0ffe59980f32968e43612ff2a887e188d15d85c6c1f3","accountKey":"KKP9mqpuBrxMS1yCjje0xg=="}
		// if(!localStorage.getItem('user_details')){
		// 	localStorage.setItem('user_details',JSON.stringify(userDetail))
		// }
		// let token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6IlBSRVJBS0BPUFNLVUJFLkNPTSIsInVzZXJJZCI6MTM1LCJ1c2VyTmFtZSI6IkRBVUQiLCJpc1N1YkFmZmlsaWF0ZSI6ZmFsc2UsImlhdCI6MTcwNzkyMDg5MiwiZXhwIjoxNzM5NDU2ODkyfQ.aOn4ZrkQ1zhnBQLGOTpCd4Xv_O0-aqIc7GLxSy7GVK8'
		// if(!localStorage.getItem('access_token')){
		// 	localStorage.setItem('access_token',token)
		// }
		
		route.events.subscribe( (event) => {
			// if(JSON.parse(localStorage.getItem('authUserDetails'))!= undefined) {
			// 	this.isLogin = true
			// }else{
			// 	this.isLogin = false
			// }
			if(event['url'] != undefined) {
			//    if(event['url'].includes('template') ) {
			// 		this.isHeader = false;
			//   }
			//   else{
			// 		this.isHeader = true;
			//   }
			//   if(this.isLogin){
			// 	this.isHeader = false;
			//   }
			  if (
				event["url"].includes('pages/flight;searchId') ||
				event["url"].includes('pages/traveller')   ||
				event["url"].includes('pages/twoway-traveller')   ||
				event["url"].includes('pages/special-round-trip-traveller')    ||
				event["url"].includes('pages/multi-city-lcc-traveller')   ||
				event["url"].includes('pages/multi-city-gds-traveller')  

			) {
				this.showFooter = false
			}else{
				this.showFooter = true
			}
			}      
		  })
	 }
	async ngOnInit() {
		let deviceId = this.cookiesService.getCookie('deviceId');
		if(!deviceId) {
			let generatedDeviceId:any = await this.generateFingerPrint();
			
			this.cookiesService.setCookie('deviceId',generatedDeviceId, null)
		}
		if(JSON.parse(localStorage.getItem('user_details'))!= undefined) {
			this.isLogin = true
			this.userInfo = JSON.parse(localStorage.getItem('user_details'))
			this.connectToCentri("CF_EVENTS_"+ this.userInfo.xlAccountCode)


		}else{
			this.isLogin = false
		}
		try {
			this.myAppURL = window.location.href;
			this.isModalOpen = false
			if (this.centrifugeService.handler && this.centrifugeService.connected) {
				this.centrifugeService.handler.disconnect();
			}
			this.connectToCentri("version_control")
			
			this.appVersionFromApi = ""
			this.currentVersion =  localStorage.getItem("app-version")
			let latestVersion = await this.getVersion()
			if(latestVersion && latestVersion!="" && latestVersion!= this.currentVersion && (this.isHeader || this.isLogin)){
				this.appVersionFromApi = latestVersion
				this.openVersionControlModal()
			}
		} catch (error) {
			console.error(error)
		}
		this.commonService.accessLoaderStatus.subscribe(value => {
			this.loading = value;
		})
		this.commonService.showFooterValue.subscribe(value=>{
			if(Number(value)==1){
				this.showFooter = true
			}
		})

	}

	async generateFingerPrint() {
		let fingerPrintId;
		await getCurrentBrowserFingerPrint().then(fingerPrint => {
			fingerPrintId = fingerPrint;
		}).catch(error => {
			console.log(error)
		})
		if(fingerPrintId) {
			return fingerPrintId;
		}
		return '';
	}
	getVersion(){
		return new Promise((resolve, reject) => {
			this.commonService.getVersionControl('IBE').subscribe((res : any)=>{
				let respData = JSON.parse(res['_body'])
				if(respData && respData["data"] && respData["data"].length==1){
					resolve(respData["data"][0]["version"])
				}else{
					resolve("")
				}
			
			},error=>{
				this.appVersionFromApi = ""
				resolve("")
				console.log(error)
			})
		})
	}

	openVersionControlModal(){
		this.isModalOpen = true
		this.openModal.nativeElement.click()
	}

	forceReload() {
		this.refreshForm.nativeElement.submit();
	}


	setVersion(){
	   localStorage.setItem("app-version",this.appVersionFromApi)
	   this.showStrip = false
	   this.isModalOpen = false
	   this.forceReload()
	}

	connectToCentri(channelName) {
		try {
		  let ICentrifugeConfig = {
			url: environment.centri_ws,
			token: environment.centri_token,
			user: "Daud",
			timestamp: (Date.now() / 1000) | 0,
			debug: true,
			reconnect: true,
		  };
		  this.centrifugeService.connect(ICentrifugeConfig);
		  this.centrifugeService.handler.subscribe(channelName, (ctx) => {
			  let data = ctx.data
			  let currentVersion = localStorage.getItem('app-version')
				if(data['version'] && data['version']!=currentVersion && (this.isHeader || this.isLogin)){
					this.appVersionFromApi = data['version']
					if(!this.isModalOpen){
					this.showStrip = true
					}
			  	}
				if(data['event']=='refresh_balance'){
					this.bookingRequestService.getCreditLimit(true,0,this.userInfo.affiliateId,"");
				}
				if(data['event']=='refresh_account'){
					localStorage.setItem(
						"user_details",
						JSON.stringify(data["userInfo"])
					);
					this.bookingRequestService.getCreditLimit(true,0,this.userInfo.affiliateId,"");
				}
				if(data['event']=='auto_logout'){
					this.logout()
				}
		  });
		} catch (error) {
		  console.log("error occured to subscribe centrifuge",error);
		}
	  }

	  logout() {
		// localStorage.clear();	
		// this.route.navigate(['login']);
	}


}
