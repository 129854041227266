import { Injectable, ElementRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderServiceService {
  private authUserDetailsSubject = new BehaviorSubject<any>(null);

  private loginModal: ElementRef;

  constructor() { 
    const authUserDetails = JSON.parse(localStorage.getItem('authUserDetails'));
    if (authUserDetails) {
      this.authUserDetailsSubject.next(authUserDetails);
    }
  }

  get authUserDetails$() {
    return this.authUserDetailsSubject.asObservable();
  }

  setAuthUserDetails(details: any) {
    localStorage.setItem('authUserDetails', JSON.stringify(details));
    this.authUserDetailsSubject.next(details);
  }

  logout() {
    localStorage.removeItem('authUserDetails');
    this.authUserDetailsSubject.next(null); 
  }

  setLoginModal(modal: ElementRef) {
    this.loginModal = modal;
  }

  showModal() {
    if (this.loginModal) {
      this.loginModal.nativeElement.click();
    } else {
      console.error('Login modal is not set');
    }
  }
}
