import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

	constructor(private router: Router) { }

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		try {
			// let token = localStorage.getItem('access_token')
			// let token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6IlBSRVJBS0BPUFNLVUJFLkNPTSIsInVzZXJJZCI6MTM1LCJ1c2VyTmFtZSI6IkRBVUQiLCJpc1N1YkFmZmlsaWF0ZSI6ZmFsc2UsImlhdCI6MTcwNzkyMDg5MiwiZXhwIjoxNzM5NDU2ODkyfQ.aOn4ZrkQ1zhnBQLGOTpCd4Xv_O0-aqIc7GLxSy7GVK8'
			// if (token) {
			// 	var current_time = new Date().getTime() / 1000;
			// 	let jwt = this.parseJwt(token)
			// 	if (current_time > jwt.exp) {
			// 		if( window.location.hash.includes("/booking-summary-detail")) {
			// 			localStorage.setItem('reload', window.location.hash)
			// 		}
			// 		// localStorage.removeItem('access_token')
			// 		// localStorage.removeItem('user_details')
			// 		this.router.navigate(['login',{redirectTo : window.location.href}]);
			// 		return true;
			// 	}
			// 	else {
			// 		return true;
			// 	}
			// 	// return true;

			// }
			// else {
			// 	if(window.location.hash.includes("/booking-summary-detail")) {
			// 		localStorage.setItem('reload', window.location.hash)
			// 	}
			// 	// localStorage.removeItem('access_token')
			// 	// localStorage.removeItem('user_details')
			// 	this.router.navigate(['login',{redirectTo : window.location.href}]);
			// 	return false;
			// }
			return true;
		}
		catch (Exception) {
			// localStorage.removeItem('access_token')
			// localStorage.removeItem('user_details')
			// this.router.navigate(['login']);
			return true;
		}

	}

	parseJwt(token) {
		var base64Url = token.split('.')[1];
		var base64 = base64Url.replace('-', '+').replace('_', '/');
		return JSON.parse(window.atob(base64));
	}
}
