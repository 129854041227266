
import { Injectable } from '@angular/core';
import { CommonService } from '../common.service';

import * as moment from 'moment-timezone';

@Injectable({
	providedIn: 'root'
})
export class BookingService {

	constructor(private commonService: CommonService) { }

	getBookings(affiliateId) {
		return this.commonService.getBooking(`booking/affiliateBoooking?affiliateId=${affiliateId}`);
	}
	// getBookingData(id){
	//   return this.commonService.getValue(`affiliate/viewBookingData/${id}`);
	// }
	issueBooking(data) {
		return this.commonService.postValue('bookings/issue', data);
	}

	saveAddonMarkup(data) {
		return this.commonService.postValue('bookings/saveAddonMarkup', data);
	}

	getBookingFilter(data) {
		return this.commonService.getBooking(`booking/bookingfilter${data}`);
	}

	generateBookingRef() {
		return this.commonService.airPostValue('generateBookingRef', {});
	}

	generateInvoiceNum() {
		return this.commonService.airPostValue('generateInvoiceNum', {});
	}

	generateReceiptNum() {
		return this.commonService.airGetValue('last-used-excel-doc-prefix-reciept');
	}

	// 

	getBookingConfirmDetails(invoice_no,type, bookingRef) {
		return this.commonService.getBooking(`booking/getInvoice?invoice_no=${invoice_no}&type=${type}&booking_ref=${bookingRef}`);
	}

	getBookingWithBookingRef(booking_ref) {
		return this.commonService.getBooking(`booking/getBookingWithRef?booking_ref=${booking_ref}`);
	}

	getBookingHistoryBookingRef(booking_ref) {
		return this.commonService.getBooking(`bookingActivity?reference_no=${booking_ref}`);
	}

	getMulticityTicket(booking_ref){
		return this.commonService.getBooking(`booking/getMulticityTicket?booking_ref=${booking_ref}`)
	}


	getSearchedData(booking_ref) {
		return this.commonService.getBooking(`booking/getSearchedData?booking_ref=${booking_ref}`);
	}
	createLiveBooking(data) {
		return this.commonService.postValue('bookings/issue', data);
	}
	gmtDateParser(value) {
		if (value != null) {
			let now = moment().tz('Asia/Kolkata')// add this 4of 4
			value = moment(value).format('YYYY-MM-DD')
			
			return (
				value
			);
		}
	}
	gmtDateParserFrom(value) {
		let stringValue = value.toString()
		if (value != null && !stringValue.includes('-')) {
			let now = moment().tz('Asia/Kolkata')// add this 4of 4
			value = moment(value).format('YYYY-MM-DD')
			// let date = value.getDate();
			// if (date < 10) {
			// 	date = "0" + date;
			// }
			// let month = value.getMonth();
			// if (month < 9) {
			// 	month = "0" + Number(month + 1);
			// }
			// else {
			// 	month = Number(month + 1);
			// }
			// let year = value.getFullYear();
			return (
				value + " " + "00:00:00"
			);
		}
		else{
			return value
		}
	}
	gmtDateParserTo(value) {
		let stringValue = value.toString()
		if (value != null && !stringValue.includes('-')) {
			let now = moment().tz('Asia/Kolkata')// add this 4of 4
			value = moment(value).format('YYYY-MM-DD')
			// let date = value.getDate();
			// if (date < 10) {
			// 	date = "0" + date;
			// }
			// let month = value.getMonth();
			// if (month < 9) {
			// 	month = "0" + Number(month + 1);
			// }
			// else {
			// 	month = Number(month + 1);
			// }
			// let year = value.getFullYear();
			return (
				value + " " + "23:59:59"
			);
		}
		else{
			return value
		}
	}



	// Send SMS

	sendSms(requestData) {
		let sms = "";
		let to = "";
		try {
			let guestName = requestData.travellers[0]['title'] + ' ' + requestData.travellers[0]['first_name'] + ' ' + requestData.travellers[0]['last_name'];
			let smsBody = 'Dear (traveler name), your ticket booked on SG, Departs   PBD 11:05 on 23-Jan-2019.Airline PNR:QD555A'
			let time = requestData.segments[0].legs[0].origin_timestamp.split('T')[1].split(".")[0].split(':').splice(0, 2).join(":")
			let date = new Date(requestData.segments[0].legs[0].origin_timestamp).toUTCString().split(" ").splice(1, 3).join("-")
			let airline_pnr = [];
			for (let i = 0; i < requestData.segments.length; i++) {
				const element = requestData.segments[i];
				for (let j = 0; j < element.legs.length; j++) {
					const elm = element.legs[j];
					let pnr = element.pnr
					if (elm.airline_pnr != undefined && elm.airline_pnr != null) {
						pnr = elm.airline_pnr
					}
					airline_pnr.push(pnr)
				}

			}
			if (requestData.pnr_status == "TICKETED") {
				sms = "Dear " + guestName + ", your ticket booked on " + requestData.segments[0].legs[0].operating_airline_name +
					" Departs " + requestData.segments[0].legs[0].origin_airport_code + " " + time + " on " + date + ". Airline PNR(s) : " + airline_pnr.join(',')

				to = requestData.contactdetails.affiliateAddress.phone
			}
			else if (requestData.pnr_status == "HOLD") {
				sms = "Dear " + guestName + ", your booking on " + requestData.segments[0].legs[0].operating_airline_name + " is " +
					" on hold. " +
					" Booking Id : " + requestData.booking_ref

				to = requestData.contactdetails.affiliateAddress.phone
			}
			if (sms != "" && to != "") {
				this.commonService.SMS(to, sms).subscribe(data => {
					console.log("SMS success", data);
				},
					error => {
						console.log("SMS Error", error);
					})
			}
			let sendToAdmin = true
			if (sendToAdmin) {
				to = '9909988860'
				this.commonService.SMS(to, sms).subscribe(data => {
					console.log("SMS success", data);
				},
					error => {
						console.log("SMS Error", error);
					})
			}
		}
		catch (Exception) {
			console.log("SMS Exception", Exception);
		}


	}
	format(date) {
		return new Date(date).toDateString().slice(4)
	}



	createSsrRequest(data,ssr){
		let requestObj = {
			"bookingId": data.booking_ref,
			"pnr": data.gds_pnr,
			"segments": this.createSsrSegment(data.segments,ssr),
			"ssrs": this.createSSR(data,ssr),
			"supplierInfo": data['supplierInfo'] && data['supplierInfo']['offerDetailForSsrs'] ? data['supplierInfo']['offerDetailForSsrs'] : [],
			"travellers": data['travellers'] ? data['travellers'] : []
		  }
		   

		  return requestObj;
	}

	createSsrSegment(segments,ssr){
		let segmentsArr = []
		  for (let i = 0; i < segments.length; i++) {
			  const segment = segments[i];
			  let segObj = {
				"legs": this.createSsrLegs(segment.legs,ssr),
			  }
			  segmentsArr.push(segObj)
		  }

		  return segmentsArr
	}
	createSsrLegs(legs,ssr){
		let legsArr =  []

		  for (let i = 0; i < legs.length; i++) {
			  const leg = legs[i];
			  let legObj = {
				"arrivalDateTime": leg.destination_timestamp,
				"departureDateTime": leg.origin_timestamp,
				"originAirport": {
					"city": leg.origin_airport_city,
					"code": leg.origin_airport_code,
					"country": leg.origin_airport_country,
					"name": leg.origin_airport_name,
					"terminal": leg.origin_terminal
				  },
				"flightNumber": leg.flight_number,
				"destinationAirport": {
					"city": leg.destination_airport_city,
					"code": leg.destination_airport_code,
					"country": leg.destination_airport_country,
					"name": leg.destination_airport_name,
					"terminal": leg.destination_terminal
				  },
				"technicalStop": leg.is_technical_stop,
				"marketingAirlineCode": leg.marketing_airline_code,
				"marketingAirlineName": leg.marketing_airline_name,
				"operatingAirlineCode": leg.operating_airline_code,
				"operatingAirlineName": leg.operating_airline_name,
			  }

			  legsArr.push(legObj)
		  }
		  return legsArr
	}

	createSSR(data,ssr){
		let segmentsArr = []
		for (let s = 0; s < data.segments.length; s++) {
			const segment = data.segments[s];
			let legArr = [];
			for(let i=0;i<segment.legs.length;i++) {
				let baggages = [];
				let additionalBaggages = [];
				let seats = [];
				let meals = [];
				let ancillaries = [];
				let leg = segment.legs[i]
				 
				if(ssr.baggage != undefined) {
					for (let j = 0; j < data.travellers.length; j++) {
						if(ssr.baggage[s]){
							if(ssr.baggage[s][i] != undefined){
								if (ssr.baggage[s][i].baggage != undefined) {
									 
									if (ssr.baggage[s][i].baggage[j] != undefined && !ssr.baggage[s][i].baggage[j].hasOwnProperty('hasBaggage')) {
										baggages.push(ssr.baggage[s][i].baggage[j]);
									}
								}
							}
						}
					}
				}else{
					baggages = null
				}

				if(ssr.additionalBaggages != undefined) {
					for (let j = 0; j < data.travellers.length; j++) {
						if(ssr.additionalBaggages[s]){
							if(ssr.additionalBaggages[s][i] != undefined){
								if (ssr.additionalBaggages[s][i].additionalBaggage != undefined) {
									 
									if (ssr.additionalBaggages[s][i].additionalBaggage[j] != undefined && !ssr.additionalBaggages[s][i].additionalBaggage[j].hasOwnProperty('hasAdditionalBaggage')) {
										additionalBaggages.push(ssr.additionalBaggages[s][i].additionalBaggage[j]);
									}
								}
							}
						}
					}
				}else{
					additionalBaggages = null
				}

				if(ssr.ancillaries != undefined) {
					for (let j = 0; j < data.travellers.length; j++) {
						if(ssr.ancillaries[s]){
							if(ssr.ancillaries[s][i] != undefined){
								if (ssr.ancillaries[s][i].ancillary != undefined && ssr.ancillaries[s][i].ancillary.length > 0) {
									 
									if (ssr.ancillaries[s][i].ancillary[j] != undefined && !ssr.ancillaries[s][i].ancillary[j].hasOwnProperty('hasAncillary')) {
										let selectedAncillary = []
										for (let iterator of ssr.ancillaries[s][i].ancillary) {
											iterator.selectedItems.forEach(data=>{
												data.title = iterator.title
												data.firstName = iterator.firstName
												data.lastName = iterator.lastName
											})	
											let obj = {
												travellerNumber : iterator.travellerNumber,
												items : iterator.selectedItems
											}
											selectedAncillary.push(obj)
										}
										ancillaries = selectedAncillary;
									}
								}
							}
						}
					}
				}else{
					ancillaries = null
				}
				
				if(ssr.seats != undefined) {
					for (let j = 0; j < data.travellers.length; j++) {
						if(ssr.seats[s] != undefined){
							if (ssr.seats[s][i] != undefined) {
								if (ssr.seats[s][i].seats != undefined) {
									if (ssr.seats[s][i].seats[j] != undefined && !ssr.seats[s][i].seats[j].hasOwnProperty('hasSeat')) {
										if (ssr.seats[s][i].seats[j].item != "") {
											seats.push(ssr.seats[s][i].seats[j]);
										}
									}
								}
							}
						}
					}
					// seats = ssr.seats[i].seats;
				}else{
					seats = null
				}
				if(ssr.meals != undefined) {
					for (let j = 0; j < data.travellers.length; j++) {
						if(ssr.meals[s]){
							if (ssr.meals[s][i] != undefined) {
								if (ssr.meals[s][i].meals != undefined) {
									if (ssr.meals[s][i].meals[j] != undefined && !ssr.meals[s][i].meals[j].hasOwnProperty('hasMeal')) {
										if (ssr.meals[s][i].meals[j].item != "") {
											meals.push(ssr.meals[s][i].meals[j]);
										}
									}
								}
							}
						}
					}
					// meals = ssr.meals[i].meals;
				}else{
					meals = null
				}
				let obj = {
					"supplierInfo": {
						"flightNo":leg.flight_number,
						"depatureDateTime": leg.origin_timestamp,
						"departureStation": leg.origin_airport_code,
						"arrivalStation": leg.destination_airport_code
					},
					"baggages": baggages,
					"additionalBaggages": additionalBaggages,
					"seats": seats,
					"meals": meals,
					"ancillaries": ancillaries,
				}
				legArr.push(obj)
			}
			segmentsArr.push(legArr)
		}
		return segmentsArr;
	}
}
