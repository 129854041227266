import { Injectable } from '@angular/core';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class AffiliateService {

  constructor(private commonService : CommonService) { }

  getCountries(){
    return this.commonService.getValue('common/countries');
  }
  getAccountCreditLimit(id){
    return this.commonService.getAccountValue(`limit/getCreditBalance?affiliate=${id}`);
  }
  getAccountCashLimit(id){
    return this.commonService.getAccountValue(`limit/getCashBalance?affiliate=${id}`);
  }
  getBalance(id,affiliate_code) {
    return this.commonService.getAccountValue(`ledger/getBalance?affiliate=${id}&affiliate_code=${affiliate_code}`);
  }
  getStates(id){
    return this.commonService.getValue(`common/states/${id}`);
  }

  getCities(id){
    return this.commonService.getValue(`common/cities/${id}`);
  }

  createAffiliate(data){
    return this.commonService.postValue(`createAffliate/save`,data);
  }

  viewAirAffiliate(){
    return this.commonService.getAffiliateValue(`airAffiliate/viewAirAffiliate`);
  }
  viewAirAffiliateDetail(){
    return this.commonService.getAffiliateValueWithLoader(`airAffiliate/viewAirAffiliate`);
  }

  getBaggageDetails(id){
    return this.commonService.getValue(`common/getBaggageDetails/${id}`);
  }

  getAffiliateDetails(){
    return this.commonService.getAffiliateValue(`airAffiliate/getAffiliateDetails`);
  }

  getPayementHistory(data){
    return this.commonService.testgetValue(`payment-approval/payment-get?affiliateId=${data}`,data);
  }
 
  onlinePayment(postData) {
    return this.commonService.onlinePayment(postData)
	}

  updateAirAffiliate(data){
    return this.commonService.putValue(`airAffiliate/updateAirAffiliate`,data);
  }

  updateAirPassword(data){
    return this.commonService.putValue(`reset-password/updateAir`,data);
  }

  saveAffiliatePayment(data){
    return this.commonService.postValue(`payment-approval/add`,data);
  }

  saveAccountEntry(data) {
    return this.commonService.postAccountService(`receiptv2/non-verified`, data);
  }

  createRequest(data){
    return this.commonService.postValue(`forgot-password/forgot`,data);
  }

  changePassword(data){
    return this.commonService.postValue(`forgot-password/reset`, data);
  }

  postImage(data){
    return this.commonService.postValue(`airAffiliate/postImage`,data);
  }

  postMarkUp(data){
    return this.commonService.postValue(`affiliateMarkup/addMarkup`,data);
  }

  getAffiliateMarkUp(){
    return this.commonService.getGeneralLedger(`affiliateMarkup/getMarkupDetails`);
  }

  getAffiliateMarkUpById(id){
    return this.commonService.getValue(`affiliateMarkup/getAffiliateMarkUpById/${id}`);
  }

  deleteAffiliateMarkup(id){
    return this.commonService.getValue(`affiliateMarkup/deleteAffiliateMarkup/${id}`);
  }

  getAllAirlineList(){
    return this.commonService.getValue(`common/airlinesList`);
  }

  viewAllAffiliateMarkup(data){
    return this.commonService.postValue(`affiliateMarkup/viewAllAffiliateMarkup`,data);
  }

  saveAffiliateMarkUp(data){
    return this.commonService.postValue(`affiliateMarkup/saveAffiliateMarkUp`,data);
  }
  otpGenerate(data){
    return this.commonService.postValue(`airAffiliate/otp-send`,data)
  }
  getUser(){
    return this.commonService.commonGetApi(`tenant_user`)
  }
  editUser(data, id){
    return this.commonService.commonPutApi(`tenant_user/${id}`,data )
  }
  getTravellerDetails(id){
    return this.commonService.commonGetApi(`travellers?id=${id}`)
  }
  editTravellerDetails(id, data){
    return this.commonService.commonPutApi(`travellers/${id}`, data)
  }
  saveTravellerDetails(TravellerArray){
    return this.commonService.commonPostApi(`travellers`, {TravellerArray})
  }
  deletTravellerDetails(id){
    return this.commonService.commonDeleteApi(`travellers/${id}`)
  }
}
