<div class="seat-container" [@.disabled]="true">
  <mat-tab-group>
    <mat-tab
      *ngFor="let item of seatMapArr; let k = index"
      label="{{ item.flightCode }}-{{ item.flightNo }} ({{
        item.departure
      }} - {{ item.arrival }})"
    >
      <div class="">
        <div class="option2" style="width: auto; overflow-x: scroll">
          <div
            class="seatmap2 row ml-0 mr-0"
            *ngFor="let key of item['keys']; let i = index"
          >
            <div
              class="seatrow2"
              *ngFor="let key of item['values'][i]; let j = index"
            >
              <div
                *ngIf="key.type == 'Reserved'"
                class="seat2 seat2-occupied"
              >
                {{ key["number"] }}
              </div>
              <div
                *ngIf="key.type == 'Restricted' || key.type == 'Unavailable'"
                class="seat2 seat2-unavailable"
              >
                {{ key["number"] }}
              </div>
              <!-- CheckedIn -->
              <div
                *ngIf="key.type == 'CheckedIn'"
                class="seat2 seat2-checked"
              >
                {{ key["number"] }}
              </div>
              <div
                *ngIf="key.type == 'FleetBlocked'"
                class="seat2 seat2-occupied"
              >
                {{ key["number"] }}
              </div>
              <div
                *ngIf="key.type == 'selected'"
                class="seat2 seat2-available"
                (click)="removeSeat(item.travellers[getTravellerIndexBySeatNumber(key,item)]['seat'],item,k,getTravellerIndexBySeatNumber(key,item))"
              >
                {{ key["number"] }}
              </div>
              <!-- {{key?.displayAttribute?.EXITROW}} -->
              <div
                *ngIf="
                  key.type == 'Open' &&
                  key?.displayAttribute?.EXITROW == 'True'
                "
                style="cursor: pointer"
                (click)="selectSeat(key, item, k)"
                class="seat2 seat2-exit-seat"
                [ngClass]="{'seat2-paid': (key.totalFare/80) > 0}"
                title=" {{ (key.totalFare/80)  | currency: 'USD':'symbol-narrow' }}"
              >
                {{ key["number"] }}
              </div>
              <!-- displayAttribute  EXITROW -->
              <!-- Unavailable -->
              <!-- HeldForAnotherSession  Need to show some terms and condition-->
              <div
                *ngIf="
                  (key.type == 'Open' ||
                    key.type == 'HeldForAnotherSession') &&
                  key?.displayAttribute?.EXITROW != 'True'
                "
                style="cursor: pointer"
                (click)="selectSeat(key, item, k)"
                class="seat2"
                [ngClass]="{'seat2-paid': key.totalFare/80 > 0}"
                title=" {{ (key.totalFare/80)  | currency: 'USD':'symbol-narrow' }}"
              >
                {{ key["number"] }}
              </div>
            </div>
            <ng-container *ngIf="(item.cabinInfo && item.cabinInfo[i] && item.cabinInfo[i+1] && item.cabinInfo[i]['aisleSeat'] && item.cabinInfo[i+1]['aisleSeat'] )">
              <ul>
                <li *ngFor="let key of item['values'][i]; let j = index">
                  <label class="ml-4 fa-12p"></label>
                </li>
              </ul>
            </ng-container>
            <ng-container *ngIf="!item.cabinInfo && (item.keys.length / 2 == i + 1)">
              <ul>
                <li *ngFor="let key of item['values'][i]; let j = index">
                  <label class="ml-4 fa-12p"></label>
                </li>
              </ul>
            </ng-container>

          </div>
          <div class="clearfix"></div>
        </div>

        <div class="seat2-options mb-2">
          <div class="legends d-flex align-items-center justify-content-between"><span>Legends</span>
              <div class="d-flex justify-content-center legend-map ">
                  <span
                    title="Free Seats"
                    class="seat2-selected seat2-style cursor-unset ml-2 mr-2"
                  ></span>
                  <span
                  title="Paid Seats"
                  class="seat2-paid-inr seat2-style cursor-unset ml-2 mr-2"
                  ></span>
                  <span
                    title="Selected Seats"
                    class="seat2-available seat2-style cursor-unset ml-2 mr-2"
                  ></span>
                  <span
                    title="Non Reclining Seats"
                    class="seat2-norecline seat2-style cursor-unset ml-2 mr-2"
                  ></span>
                  <span
                    title="Occupied Seats"
                    class="seat2-occupied seat2-style cursor-unset ml-2 mr-2"
                  ></span>
                  <span
                    title="Emergency Exit Seats"
                    class="seat2-exit-seat seat2-style cursor-unset ml-2"
                  ></span>
                </div>
          </div>
          
        </div>
        <ng-container *ngFor="let pass of item.travellers; let l = index">
          <div
            class="col-12 bg-light card pl-3 pr-3 pt-2 pb-2 clearfix m-1" *ngIf="pass.type != 'INF'"
          >
          <div class="d-flex align-items-center justify-content-between">
            <div class="row m-0" style="width: 330px;">
              <div class="col-md-6">
                  <p
                class="passName text-capitalize text-bold text-dark-green m-0"
                
              >
                {{ pass.title }} {{ pass.firstName }} {{ pass.lastName }}
              </p>
              </div>
              <div
                class="col-md-6 d-flex align-items-center justify-content-between"
                *ngIf="pass.seat != undefined"
              >
                <div
                  class="d-flex align-items-center "
                >
                  <div class="text-bold mr-2">{{ pass.seat }} &nbsp;</div>
                  <div class="text-bold">
                     {{ (pass.totalFare/80)  | currency: 'USD':'symbol-narrow' }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        </ng-container>
      </div>
    </mat-tab>
  </mat-tab-group>
  
</div>
