import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/services/authService/auth-service.service';
import { HeaderServiceService } from '../../header-service.service';

declare var FB: any;

@Injectable({
  providedIn: 'root',
})
export class FacebookLoginService {
  userProfile: any = {};

  constructor(private authService : ApiService, private headerService: HeaderServiceService) {}

  checkFacebookLoginStatus(callback: (response: any) => void) {
    if (typeof FB !== 'undefined') {
      FB.getLoginStatus((response) => {
        this.statusChangeCallback(response);
        callback(response);
      });
    } else {
      console.error('Facebook SDK not loaded');
    }
  }

  private statusChangeCallback(response) {
    if (response.status === 'connected') {
    } else {
    }
  }

  loginWithFacebook(callback: (response: any) => void) {
    FB.getLoginStatus((response) => {
      if (response.status === 'connected') {
        FB.logout(() => {
          this.performFacebookLogin(callback);
        });
      } else {
        this.performFacebookLogin(callback);
      }
    });
  }

  private performFacebookLogin(callback: (response: any) => void) {
    FB.login(
      (response) => {
        this.statusChangeCallback(response);
        if (response.status === 'connected') {

          this.fetchUserProfile(response.authResponse.accessToken, callback);
        } else {
          callback(response);
        }
      },
      { scope: 'public_profile,email', auth_type: 'reauthenticate' }
    );
  }

  private fetchUserProfile(accessToken: string, callback: (response: any) => void) {
    FB.api('/me', { fields: 'id,name,email,picture', access_token: accessToken }, (profileResponse) => {
      
      this.bindObjectdata(profileResponse); 
      this.authService.createUser(this.userProfile).subscribe(
        (res:any) => {
          console.log('AuthResponse',res)
          this.headerService.setAuthUserDetails(this.userProfile);
          // localStorage.setItem('authUserDetails', JSON.stringify(this.userProfile));
          callback({ authResponse: accessToken, profile: profileResponse });
        },
        (err) => {
          callback({ authResponse: { accessToken }, profile: profileResponse, error: err });
        }
      );
    });
  }


  // Bind Facebook login data to the service
  bindObjectdata(profileResponse) {
    const [firstname, ...lastnameArray] = profileResponse.name.split(' ');
    const lastname = lastnameArray.join(' ');
  
    this.userProfile = {
      firstName: firstname,
      email: profileResponse.email,
      photoUrl: profileResponse.picture.data.url,
      updatedBy: null,
      updatedOn: null,
      createdBy: null,
      createdOn: null,
      stateId: null,
      t_user_city_id: null,
      t_user_country_id: null,
      tenant_user_type_id: 2,
      tenantId: null,
      groupId: null,
      password: null,
      status: 'ACTIVE',
      code: null,
      phoneNo: null,
      mobileNo: null,
      stateName: null,
      cityName: null,
      countryName: null,
      address1: null,
      address2: null,
      title: null,
      zipCode: null,
      dob: null,
      gender: null,
      lastName: lastname,
      source: 'FACEBOOK',
    };
  }

}
