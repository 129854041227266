import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { ApiService } from 'src/app/services/authService/auth-service.service';
import { HeaderServiceService } from '../../header-service.service';

@Injectable({
  providedIn: 'root'
})
export class FormSignupService {
  userProfile: any = {};

  constructor(private authService : ApiService, private headerService: HeaderServiceService) { }

  async formSignup(response: any): Promise<any> {
    this.bindObjectdata(response);    
    try {
      const res = await this.authService.signUpformUser(this.userProfile).toPromise();
      localStorage.setItem('authUserDetails', JSON.stringify(this.userProfile));
      return res;
    } catch (err) {
      let errorMessage = 'An error occurred';
      if (err.error && err.error.message) {
        errorMessage = err.error.message;
      }
      return Promise.reject(errorMessage);
    }
  }

  async login(response: any): Promise<any> {
    try {
      const res: any = await this.authService.loginUser(response).toPromise();
      console.log('Profile sent to backend successfully', response);
      // localStorage.setItem('authUserDetails', JSON.stringify(res.user));
      this.headerService.setAuthUserDetails(res.user);
      return res;
    } catch (err) {
      let errorMessage = 'An error occurred';
      if (err.error && err.error.message) {
        errorMessage = err.error.message;
      }
      return Promise.reject(errorMessage);
    }
  }

  // Bind form login data to the service
  bindObjectdata(profileResponse) {
    const currentDate = moment().format(); 
 
    this.userProfile = {
      firstName: profileResponse.firstName,
      email: profileResponse.email,
      photoUrl: null,
      updatedBy: null,
      updatedOn: null,
      createdBy: null,
      createdOn: currentDate,
      stateId: null,
      t_user_city_id: null,
      t_user_country_id: null,
      t_user_role_id: 2,
      tenantId: null,
      groupId: null,
      password: profileResponse.password,
      status: 'ACTIVE',
      code: null,
      phoneNo: null,
      mobileNo: null,
      stateName: null,
      cityName: null,
      countryName: null,
      address1: null,
      address2: null,
      title: null,
      zipCode: null,
      dob: null,
      gender: null,
      lastName: profileResponse.lastName,
      source: 'FORM',
    };
  }
}
