import { Injectable } from '@angular/core';

import * as moment from 'moment-timezone';

@Injectable({
	providedIn: 'root'
})
export class BookingService {

	constructor() { }


	bookingRequest(data, flight, limit_type,isSRT=false,subFareType='', xmlSearchSsrLogsKey='',xmlSearchSeatLogsKey='') {
		
		flight = flight[0];
		let supplierCode = flight.supplierCode;
		if (supplierCode == "G9") {
			supplierCode = "Air Arabia";
		}	
		else if(flight.supplierCode == "SG" || flight.supplierCode == "G8" || flight.supplierCode == "6E" || flight.supplierCode == "6E_CORPORATE") {
			supplierCode = flight.fares[0].supplierInfo.SUPPLIER;
		}	
		 
		let isCustomBookingFailed = false;
		let customBookingFailedMessage;
		try {
			if(flight.fares[0].isCustomBookingFailed && flight.fares[0].groupFareInfo){
				isCustomBookingFailed = true;
				let groupId = flight.fares[0].groupFareInfo.requestId
				customBookingFailedMessage = `This is a group booking. Use this group id : ${groupId} to find and complete booking in airline's site. This fare will be available till ${flight.fares[0].groupFareInfo.validTill}`
			}
		} catch (error) {
			
		}

		try{
			if(flight.hasOwnProperty("segments") && flight.segments.length>0){
				if(flight.fares[0].hasOwnProperty("supplierInfo") && flight.fares[0].supplierInfo.hasOwnProperty("ufid")){
					let ufid = flight.fares[0].supplierInfo.ufid
					flight.segments.forEach(element => {
						if(!element['supplierInfo']){
							element['supplierInfo'] = {}
						}
						if(!element['supplierInfo']["ufid"]){
							element['supplierInfo']["ufid"] = ufid
						}
						
					});
				}
			}
		}catch(error){
			console.log("error occured while adding ufid in segment")
		}



		let obj = {
			"hold" : data.isHold,
			"bookingId": data.bookingRefNo,
			"supplierName": supplierCode,
			"limitType" : limit_type,
			"gstDetail": this.bindGstDetails(data),
			"contactdetails": {
				"email": data.email,
				"phone": data.mobile
			},
			"promoCode": "string",
			"selectFlight": {
				"airline": flight.airline,
				"fareRules" : flight.fareRule,
				"baggageRules": null,
				"direction": flight.direction,
				"durationInMinutes": 0,
				"fares": null,
				"handBaggageOnly": true,
				"multiAirline": true,
				"passportRequired": true,
				"segments": flight.segments,
				"selectedFare": flight.fares[0],
				"serviceClass": flight.serviceClass,
				"supplierCode": supplierCode,
				"supplierInfo": flight.supplierInfo,
				"uuid": flight.uuid ? flight.uuid : "",
				"netPayable":flight.netPayable
			},
			"travellers": this.bindTraveller(data,subFareType),
			"ssrSRT": isSRT ? this.bindSRTSeatLegs(data,flight, xmlSearchSsrLogsKey, xmlSearchSeatLogsKey) : this.bindSeatLegs(data, flight, xmlSearchSsrLogsKey, xmlSearchSeatLogsKey),
			"ssrs": this.bindSeatLegs(data, flight, xmlSearchSsrLogsKey, xmlSearchSeatLogsKey),
			"searchedData" : flight,
			"flight_index" : 0,
			"userId": data['userId']
		};

		if(data.hasOwnProperty("hideFaresOnAirlineTicket")){
			obj["hideFaresOnAirlineTicket"] = data.hideFaresOnAirlineTicket
		}
		
		if(isCustomBookingFailed){
			obj['isCustomBookingFailed'] = isCustomBookingFailed
			obj['customBookingFailed'] = isCustomBookingFailed
		}
		if(customBookingFailedMessage){
			obj['customBookingFailedMessage'] = customBookingFailedMessage
		}
		if (data.forceBooking != undefined) {
			obj['isForceBooking'] = data.forceBooking
		}
		if (data.duplicateReference != undefined) {
			obj['duplicateReference'] = data.duplicateReference
		}
		if (data.isInsuranceRequested != undefined) {
			obj['insuranceRequest'] = {
				
				"insuranceRequested" : data.isInsuranceRequested,
				"totalFlightCount" : data.total_flight_count ? data.total_flight_count : 1,
				"currentFlightIndex" : data.current_flight_index ? data.current_flight_index : 1,
				"parentBookingRef" : data.parent_booking_ref ? data.parent_booking_ref : "",
				"insuranceTravellerList":data.insuranceTravellerList
			}
		}
		return obj;
	}
	multiCityBookingRequest(data, flightData, limit_type,i,isSRT = false) {
		
		let userDetails = JSON.parse(localStorage.getItem('user_details'));
		let flight = flightData[i];
		let parent_booking_ref = flightData[0]['bookingId']
		let multicity = {
			parent_booking : parent_booking_ref,
			index : i
		}
		
		flight.supplierInfo['multicity'] =  multicity
		if (flight.fares[0].supplierInfo == null) {
			flight.fares[0].supplierInfo = {}
			flight.fares[0].supplierInfo['multicity'] =  multicity
		}else{
			flight.fares[0].supplierInfo['multicity'] =  multicity
		}
		let supplierCode = flight.supplierCode;
		if (supplierCode == "G9") {
			supplierCode = "Air Arabia";
		}	
		else if(flight.supplierCode == "SG") {
			supplierCode = flight.fares[0].supplierInfo.SUPPLIER;
		}	
		else if(flight.supplierCode == "G8") {
			supplierCode = flight.fares[0].supplierInfo.SUPPLIER;
		}	


		try{
			if(flight.hasOwnProperty("segments") && flight.segments.length>0){
				if(flight.fares[0].hasOwnProperty("supplierInfo") && flight.fares[0].supplierInfo.hasOwnProperty("ufid")){
					let ufid = flight.fares[0].supplierInfo.ufid
					flight.segments.forEach(element => {
						if(!element['supplierInfo']){
							element['supplierInfo'] = {}
						}
						element['supplierInfo']["ufid"] = ufid
						
					});
				}
			}
		}catch(error){
			console.log("error occured while adding ufid in segment")
		}

	
		let obj = {
			"hold" : data.isHold,
			"bookingId": data.bookingRefNo,
			"supplierName": supplierCode,
			"limitType" : limit_type,
			"multicity" : multicity,
			"gstDetail": this.bindGstDetails(data),
			"contactdetails": {
				"email": data.email,
				"phone": data.mobile,
				"affiliateAddress": {
					"address1": userDetails.address1,
					"address2": userDetails.address2,
					"city": userDetails.city_name,
					"companyName": userDetails.companyName,
					"countryCode": "IN",
					"stateCode": userDetails.state_name,
					"zip": userDetails.pincode,
					"xl_code": userDetails.xlAccountCode, 
					"affiliate_id": userDetails.affiliateId, 
					"affiliate_company_name": userDetails.companyName,
					"booked_by_user_name": userDetails.firstName + " "+userDetails.lastName, 
				  },
			},
			"promoCode": "string",
			"selectFlight": {
				"airline": flight.airline,
				"fareRules" : flight.fareRule,
				"baggageRules": null,
				"direction": flight.direction,
				"durationInMinutes": 0,
				"fares": null,
				"handBaggageOnly": true,
				"multiAirline": true,
				"passportRequired": true,
				"segments": flight.segments,
				"selectedFare": flight.fares[0],
				"serviceClass": flight.serviceClass,
				"supplierCode": supplierCode,
				"supplierInfo": flight.supplierInfo,
				"uuid": "string",
				"netPayable":flight.netPayable
			},
			"travellers": this.bindTraveller(data),
			"ssrs": this.bindSeatLegs(data, flight),
			"searchedData" : flight,
			"flight_index" : i
		};
		if (data.forceBooking != undefined) {
			obj['isForceBooking'] = data.forceBooking
		}
		if (data.duplicateReference != undefined) {
			obj['duplicateReference'] = data.duplicateReference
		}
		return obj;
	}

	specialRoundTripBookingRequest(data, flight,segmentIndex,isSRT=false) {
		let userDetails = JSON.parse(localStorage.getItem('user_details'));
		flight = flight[0];
		let supplierCode = flight.supplierCode;
		if (supplierCode == "G9") {
			supplierCode = "Air Arabia";
		}	
		else if(flight.supplierCode == "SG") {
			supplierCode = flight.fares[0].supplierInfo.SUPPLIER;
		}	
		else if(flight.supplierCode == "G8") {
			supplierCode = flight.fares[0].supplierInfo.SUPPLIER;
		}	
		let segment = [];
		segment.push(flight.segments[segmentIndex]);
		let obj = {
			"hold" : data.isHold,
			"bookingId": "",
			"supplierName": supplierCode,
			"gstDetail": this.bindGstDetails(data),
			"contactdetails": {
				"email": data.email,
				"phone": data.mobile,
				"affiliateAddress": {
					"address1": userDetails.address1,
					"address2": userDetails.address2,
					"city": userDetails.city_name,
					"companyName": userDetails.companyName,
					"countryCode": "IN",
					"stateCode": userDetails.state_name,
					"zip": userDetails.pincode
				  },
			},
			"promoCode": "string",
			"selectFlight": {
				"airline": flight.airline,
				"baggageRules": null,
				"direction": flight.direction,
				"durationInMinutes": 0,
				"fares": null,
				"handBaggageOnly": true,
				"multiAirline": true,
				"passportRequired": true,
				"segments": segment,
				"selectedFare": flight.fares[0],
				"serviceClass": flight.serviceClass,
				"supplierCode": supplierCode,
				"supplierInfo": flight.supplierInfo,
				"uuid": "string"
			},
			"travellers": this.bindTraveller(data),
			"ssrs": this.bindSeatLegs(data, flight),
			"searchedData" : flight,
			"flight_index" : 0
			
		};
		if (data.forceBooking != undefined) {
			obj['isForceBooking'] = data.forceBooking
		}
		if (data.duplicateReference != undefined) {
			obj['duplicateReference'] = data.duplicateReference
		}
		return obj;
	}

	bindGstDetails(data) {
		if(data.corporateGstNumber != "") {
			let obj = {
				"companyName":data.corporateName,
				"email": data.corporateEmail,
				"gstNumber": data.corporateGstNumber,
				"phone": data.corporateMobile,
			}
			return obj;
		}
		return null
		
	}

	bindPassportDetails(passengerList) {
		if(passengerList.passportNo != "") {
			let obj = {
				"expiryDate": passengerList.expiryDate,
				"issuingCountry": passengerList.issuingCountry,
				"nationality": passengerList.nationality,
				"number": passengerList.passportNo
			}
			return obj;
		}
		return null;
		
	}

	bindTraveller(data,subFareType='') {
		let travellerArr = [];
		let count : number = 0
		data.passengerList.forEach(el => {
			let type = 'ADT';
			let curDate = new Date();
			let dob = "";
			if (el.passengerType == 'Child') {
				type = 'CHD';
			}
			if (el.passengerType == 'Infant') {
				type = 'INF';
			}
			if(el.dob == undefined || el.dob == "") {
				let pastYear = curDate.getFullYear() - 20;
				if (el.passengerType == 'Child') {
					pastYear = curDate.getFullYear() - 6;
				}
				if (el.passengerType == 'Infant') {
					pastYear = curDate.getFullYear() - 1;
				}
				curDate.setFullYear(pastYear);
				dob = moment(curDate).format('YYYY-MM-DD')// add this 4of 4
			}
			else{
				dob = moment(el.dob).format('YYYY-MM-DD')// add this 4of 4
			}
			let obj = {
				"dob": dob,
				"firstName": el.firstName,
				"lastName": el.lastName,
				"passport": this.bindPassportDetails(el), // Need to make this dynamic 
				"title": el.title,
				"type": type,
				"associateInfo" : el.associateInfo,
				"gender" : el.gender,
				// "frequentFlyerInfos": [
				// 	{
				// 	  "airline": el.frequentflyers,
				// 	  "number": el.frequentflyersNumber
				// 	}
				// ],
				// "selectedMeal": {
				// 	"code": el.mealServices,
				// 	"travellerNumber": count
				//   },
				// "selectedSpecialService": el.specialServices,
			}
			if(subFareType != ''){
				obj['studentId'] = el.studentId
				obj['seniorCitizenId'] = el.seniorCitizenId
				obj['seniorCitizen'] = el.seniorCitizen
				obj['student'] = el.student
			}
			travellerArr.push(obj)
			count++
		});
		return travellerArr;
	}



	bindSeatLegs(data, flight, xmlSearchSsrLogsKey='', xmlSearchSeatLogsKey='') {
		let legArr = [];
		for (let j = 0; j < flight.segments.length; j++) {
			const segment = flight.segments[j];
			for(let i=0;i<segment.legs.length;i++) {
				let baggages = null;
				let additionalBaggages = null;
				let seats = null;
				let meals = null;
				let wheelChairs = null;
				let ancillaries = null;

				if(data.baggage != undefined && data.baggage[i] && data.baggage[i].baggage) {
					baggages = data.baggage[i].baggage;
					
				}
				if(data.additionalBaggage != undefined && data.additionalBaggage[i] && data.additionalBaggage[i].additionalBaggage) {
					additionalBaggages = data.additionalBaggage[i].additionalBaggage;
					
				}
				if(data.ancillary != undefined && data.ancillary[i].ancillary) {
					let selectedAncillary = []
					for (let iterator of data.ancillary[i].ancillary) {
						iterator.selectedItems.forEach(data=>{
							data.title = iterator.title
							data.firstName = iterator.firstName
							data.lastName = iterator.lastName
						})	
						let obj = {
							travellerNumber : iterator.travellerNumber,
							items : iterator.selectedItems
						}
						selectedAncillary.push(obj)
					}
					ancillaries = selectedAncillary;
					
				}
				if(data.seats != undefined && data.seats[i] && data.seats[i].seats && data.seats[i].seats.length > 0) {
					data.seats[i].seats = data.seats[i].seats.filter(data => {
						return data && data.number != null
					})
					seats = data.seats[i].seats;
				}
				if(data.meals != undefined && data.meals[i] && data.meals[i].meals && data.meals[i].meals.length > 0) {
					data.meals[i].meals = data.meals[i].meals.filter(data => {
						return data && data.code != null
					})
					meals = data.meals[i].meals;
					
				}
				if(data.wheelChairs != undefined && data.wheelChairs[i] && data.wheelChairs[i].wheelChairs) {
					wheelChairs = data.wheelChairs[i].wheelChairs;
				}
				let obj = {
					"supplierInfo": {
						"flightNo":segment.legs[i].flightNumber,
						"depatureDateTime": segment.legs[i].departureDateTime,
						"departureStation": segment.legs[i].originAirport.code,
						"arrivalStation": segment.legs[i].destinationAirport.code,
						"BOOKING_REF_TS_KEY_FOR_SSR_LOGS" : xmlSearchSsrLogsKey ? xmlSearchSsrLogsKey : '',
						"BOOKING_REF_TS_KEY_FOR_SEAT_LOGS" : xmlSearchSeatLogsKey ? xmlSearchSeatLogsKey : ''
					},
					"baggages": baggages,
					"additionalBaggages": additionalBaggages,
					"ancillaries": ancillaries,
					"seats": seats,
					"meals": meals,
					"wheelChairs":wheelChairs
				}
				legArr.push(obj)
			}
			
		}
		return legArr;
		
	}
	bindSRTSeatLegs(data, flight, xmlSearchSsrLogsKey='', xmlSearchSeatLogsKey='') {
		let segArr = [];
		for (let j = 0; j < flight.segments.length; j++) {
			const segment = flight.segments[j];
			let legArr = []
			for(let i=0;i<segment.legs.length;i++) {
				let baggages = null;
				let additionalBaggages = null;
				let seats = null;
				let meals = null;
				let wheelChairs = null;
				let ancillaries = null;
				if(data.baggage != undefined && data.baggage[j] && data.baggage[j][i].baggage) {
					baggages = data.baggage[j][i].baggage;
				}
				if(data.additionalBaggage != undefined && data.additionalBaggage[j] && data.additionalBaggage[j][i].additionalBaggage) {
					additionalBaggages = data.additionalBaggage[j][i].additionalBaggage;
				}
				
				if(data.ancillary != undefined && data.ancillary[j] && data.ancillary[j][i] && data.ancillary[j][i].ancillary) {
					let selectedAncillary = []
					for (const iterator of data.ancillary[j][i].ancillary) {
						let obj = {
							travellerNumber : iterator.travellerNumber,
							items : iterator.selectedItems
						}
						selectedAncillary.push(obj)
					}
					ancillaries = selectedAncillary;
					
				}
				if(data.seats != undefined && data.seats[j] && data.seats[j][i].seats && data.seats[j][i].seats.length > 0) {
					data.seats[j][i].seats = data.seats[j][i].seats.filter(data => {
						return data && data.number != null
					})
					seats = data.seats[j][i].seats;
					
				}
				if(data.meals != undefined && data.meals[j] && data.meals[j][i].meals && data.meals[j][i].meals.length > 0) {
					data.meals[j][i].meals = data.meals[j][i].meals.filter(data => {
						return data && data.code != null
					})
					meals = data.meals[j][i].meals;
				}

				if(data.wheelChairs != undefined && data.wheelChairs[j] && data.wheelChairs[j][i].wheelChairs) {
					wheelChairs = data.wheelChairs[j][i].wheelChairs;
				}
				let obj = {
					"supplierInfo": {
						"flightNo":segment.legs[i].flightNumber,
						"depatureDateTime": segment.legs[i].departureDateTime,
						"departureStation": segment.legs[i].originAirport.code,
						"arrivalStation": segment.legs[i].destinationAirport.code,
						"BOOKING_REF_TS_KEY_FOR_SSR_LOGS" : xmlSearchSsrLogsKey ? xmlSearchSsrLogsKey : '',
						"BOOKING_REF_TS_KEY_FOR_SEAT_LOGS" : xmlSearchSeatLogsKey ? xmlSearchSeatLogsKey : ''
					},
					"baggages": baggages,
					"additionalBaggages": additionalBaggages,
					"seats": seats,
					"meals": meals,
					"ancillaries": ancillaries,
					"wheelChairs":wheelChairs
				}
				legArr.push(obj)
			}
			segArr.push(legArr)
			
		}
		return segArr;
		
	}
}




// {
// 	"supplierInfo": {
// 		"flightNo": 171,
// 		"depatureDateTime": "",
// 		"departureStation": "",
// 		"arrivalStation": ""
// 	},
// 	"baggage": [
// 		{
// 			"code": "XB15",
// 			"passenger": 0
// 		}
// 	],
// 	"seats": [
// 		{
// 			"number": "1A",
// 			"passenger": 0
// 		}
// 	],
// 	"meals": [
// 		{
// 			"code": "VGML",
// 			"passenger": 0
// 		}
// 	]
// }