
<footer id="homefooter">
  <div class="footer-box pt-4 pb-4 position-relative">
    <div class="container">
      <div class="row">
        <div class="col-sm-3">
          <img src="assets/v4images/icons/cheap-logo.svg" width="250px" alt="" srcset="">

          <ul class="iata-list">
            <li>
              <span><img src="assets/v4images/icons/bsi.png" width="80px" alt="" srcset=""></span> 
            </li>
            <li>
              <span><img src="assets/v4images/icons/iata.svg" width="60px" alt="" srcset=""></span>
            </li>
          </ul>

          <div class="partner-list">
              <span><img src="assets/v4images/icons/handshake.png" width="20px" height="" alt="" class="mr-2"/> <span style="color:#EF6A24;">Cheap Flight</span> Depot</span>
          </div>

          <div class="card-wrapper">
            <h3>Payment Partner</h3>
          </div>
          <ul class="card-list">
            <li>
             <span>
              <img src="assets/v4images/icons/card.png" width="40px" alt="" srcset=""/>
            </span> 
            </li>
            <li>
              <span>
                <img src="assets/v4images/icons/visa.png" width="40px" alt="" srcset=""/>
              </span>
            </li>
            <li>
              <span>
                <img src="assets/v4images/icons/paypal.webp" width="40px" alt="" srcset=""/>
              </span>
            </li>
          </ul>
        </div>
        <div class="col-sm-9">
          <div class="row">
            <div class="col-md-4 col-lg-4">
              <h4 class="text-white" style="margin-bottom: 20px;">About CheapFlightDepot</h4>
              <ul class="p-0 m-0 list-unstyled footer-menues">
                <li class="list-item"><a href="javascript:void(0)">How to Book</a></li>
                <li class="list-item"><a href="javascript:void(0)">Contact Us</a></li>
                <li class="list-item"><a href="javascript:void(0)">Help Center</a></li>
                <li class="list-item"><a href="javascript:void(0)">Careers</a></li>
                <li class="list-item"><a href="javascript:void(0)">About Us</a></li>
              </ul>

              <h4 class="text-white" style="margin-top: 25px;">Follow us on</h4>
              <div class="social-box ">
                <a href="#" class="link">
                  <span>
                    <img src="assets/v3images/icons/fb.svg" alt="" srcset=""/>
                  </span>
                  Facebook
                </a>
                <a href="#" class="link">
                  <span>
                    <img src="assets/v3images/icons/twitter.svg" alt="" srcset=""/>
                  </span>
                  Twitter
                </a>
                <a href="#" class="link">
                  <span>
                    <img src="assets/v3images/icons/insta.svg" alt="" srcset=""/>
                  </span>
                  Instagram
                </a>
              </div>
            </div>
            <div class="col-md-4 col-lg-4">
              <h4 class="text-white" style="margin-bottom: 20px;">Products</h4>
              <ul class="p-0 m-0 list-unstyled footer-menues">
                <li class="list-item"><a href="javascript:void(0)">Flights</a></li>
                <li class="list-item"><a href="javascript:void(0)">Hotel</a></li>
                <li class="list-item"><a href="javascript:void(0)">Villas</a></li>
                <li class="list-item"><a href="javascript:void(0)">Apartments</a></li>
                <li class="list-item"><a href="javascript:void(0)">Car Rentals</a></li>
                <li class="list-item"><a href="javascript:void(0)">Airport transfer</a></li>
              </ul>
            </div>
            <div class="col-md-4 col-lg-4">
              <h4 class="text-white" style="margin-bottom: 20px;">More</h4>
              <ul class="p-0 m-0 list-unstyled footer-menues">
                <li class="list-item"><a href="javascript:void(0)">CheapFlightDepot Affiliate</a></li>
                <li class="list-item"><a href="javascript:void(0)">Blog</a></li>
                <li class="list-item"><a href="javascript:void(0)">Privacy Policy</a></li>
                <li class="list-item"><a href="javascript:void(0)">Terms & Conditions</a></li>
                <li class="list-item"><a href="javascript:void(0)">Register Your Accommodation</a></li>
                <li class="list-item"><a href="javascript:void(0)">Register Your Experience Business</a></li>
                <li class="list-item"><a href="javascript:void(0)">CheapFlightDepot Press Room</a></li>
              </ul>
              <p class="text-white text-bold" style="margin-top: 20px;">Discover Our App</p>
              <div class="mt-2 d-flex  align-items-center app-stores">
                <a href="" target="_blank"> <img src="assets/v3images/play-store.png" alt="" srcset="" class="mr-2 hover-shadow"  /></a>
                <a href="" target="_blank"> <img src="assets/v3images/app-store.png" alt="" srcset="" class="hover-shadow"  /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="bottom-footer">
    <div class="container d-flex align-items-center justify-content-center">
      <p class="text-white m-0">All Rights Reserved @ 2024 - 2025 <a href="#" class="text-dark-orange">Cheap Flight Depot</a></p>
    </div>
  </div>
</footer>
