import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { AffiliateService } from 'src/app/services/affiliate.service';
import { BookingService } from 'src/app/services/booking/booking.service';
import { MatTabChangeEvent } from '@angular/material';
import { PostService } from 'src/app/services/post.service';
import { BookingService as BookingRequest } from './../traveller/booking.service';
import { environment } from '../../../environments/environment'
import { TravellerService } from 'src/app/services/traveller/traveller.service';
import { BookingRequestService } from 'src/app/services/booking-request.service';
import { ExcelService } from 'src/app/services/excel.service';
import { CalculatePriceService } from 'src/app/services/calculate-price.service';
import { AngularFireDatabase, AngularFireObject } from "@angular/fire/database";
import { Subscription } from 'rxjs';
import { InitialState } from "src/app/store/reducer";
import { Store } from '@ngrx/store';
import * as moment from 'moment-timezone'
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-multi-city-lcc-traveller',
  templateUrl: './multi-city-lcc-traveller.component.html',
  styleUrls: ['./multi-city-lcc-traveller.component.scss']
})
export class MultiCityLccTravellerComponent implements OnInit {

 
  @ViewChild('closeactiveBtn') closeactiveBtn: ElementRef;
  	environment = environment
	seatCharges = 0;
	mealCharges =0;
	baggageCharges=0;
	
	selectedGST : any

	active = false;
	color = 'primary';
	mode = 'indeterminate';
	value = 50;
	travellerForm: FormGroup;
	myDepControl = new FormControl();
	panelOpenState = false;
	departure: any;
	arrival: any;
	departureInboud: any;
	arrivalInbound: any;
	date;
	dateInbound;
	total;
	isOffer = false;
	netPayable: number = 0;
	// selectedFare;
	selectedFares;
	baseFare: any;
	netCommission: number = 0;
	totalFare: number = 0;
	adultBaseFare: number = 0;
	adultTotalFare: number = 0;
	legs = [];
	Inboundlegs = [];
	airlineCode: any;
	adultTaxFare: number = 0;
	childBaseFare: number = 0;
	childTotalFare: number = 0;
	totaltax: number = 0;
	arrivalDate: string;
	departureDate: string;
	departureTime: string;
	arrivalTime: string;
	isDomestic: boolean = true;
	passengerList: FormArray;
	nationalityArr: any = [];
	childTaxFare: number = 0;
	noOfAdult: any;
	noOfChild: any;
	noOfInfant: any;
	tds: number = 0;
	creditDetail: any = [];
	creditLimit: string;
	invalidCredit: boolean = false;
	inBoundCreditLimit;
	inBountInvalidCredit: boolean = false;
	passengerDetails: any;
	isSpiceJet: boolean = false;
	totalPassengerCount: number = 0;
	totalPaxArr: any = [];
	openTravellerTab: boolean = false;
	infBaseFare: number = 0;
	infTotalFare: number = 0;
	infTaxFare: number = 0;
	baggageArr: any = [];
	noOfAdultInbound: any;
	noOfChildInbound: any;
	noOfInfantInbound: any;
	tdsInbound: number = 0;
	netPayableInbound: number = 0;
	selectedFaresInbound;
	baseFareInbound: any;
	netCommissionInbound: number = 0;
	totalFareInbound: any;
	adultBaseFareInbound: any;
	adultTotalFareInbound: any;
	adultTaxFareInbound: number = 0;
	childBaseFareInbound: any;
	childTotalFareInbound: any;
	childTaxFareInbound: number = 0;
	totaltaxInbound: any;
	totalPassengerCountInbound: number = 0;
	totalPaxArrInbound: any = [];
	totalInbound;
	arrivalDateInbound: string;
	departureDateInbound: string;
	departureTimeInbound: string;
	arrivalTimeInbound: string;
	totalBookingPrice: number = 0;
	airlineCodeInbound: any;
	step: number = 0;
	infBaseFareInbound: any;
	infTotalFareInbound: any;
	infTaxFareInbound: number = 0;
	sectorArr: any = [];
	sectorInboundArr: any = [];
	adminMarkup: number = 0;
	affMarkUp: number = 0;
	adminInboundMarkup: number = 0;
	affInboundMarkUp: number = 0;
	dummyAdultNameArr: any = [];
	selectedBaggage: any = [];
	showBaggage: boolean = false;
	gstArr: any = [];
	myGstControl = new FormControl();
	outboundSeatMap: any = [];
	inboundSeatMap: any = [];
	showseatloadError: boolean;
	flightRequest: any;
	InwardflightRequest: any;
	isLCC: boolean = false;
	inInBoundLCC: boolean = false;
	outBoundTicketIssueStatus: boolean = false;
	inboundTicketIssueStatus: boolean = false;
	request;
	OnWardsBookingResJson;
	supplierName: string;
	inWardSupplierName: string;
	ssrAvailability: any[];
	showloadError: boolean;
	seatMapArr = [];
	InBoundSSRAvailability: any[];

	outboundBaggageCharges = 0;
	inboundBaggageCharges = 0;

	netTotalayable = 0;

	outboundSeatCharges = 0;
	inboundSeatCharges = 0;

	outboundMealCharges = 0;
	inboundMealCharges = 0;

	outboundSeletedMealArr;
	inboundSelectedMealArr;

	outboundSeletedSeatArr;
	inboundSelectedSeatArr;

	outboundSelectedBaggageArr;
	inboundSelectedBaggageArr;

	inBoundNetTotalayable = 0;
	holdTicket;

	firbaseData = [];
	offerSubscription: Subscription;
	allgstArr: any = [];
	airlineArr: any;

	minDate: Date = new Date();

	maxDobDate = {
		"Adult" : new Date(moment().subtract(12, 'years')),
		"Child" : new Date(moment().subtract(2, 'years')),
		"Infant" : new Date()
	  }
	  // moment().subtract(12, 'years')
	minDobDate = {
		"Adult" : new Date(moment().subtract(100, 'years')),
		"Child" : new Date(moment().subtract(12, 'years')),
		"Infant" : new Date(moment().subtract(2, 'years'))
	}

	paxSubmitted: boolean = false;
	contactSubmitted: boolean = false;
	affiliateMarkup: number;
	isDiAccount: boolean;
	limitType: any;

	paymentIndex = 0
	currentPaymentAmount = 0
	warningMessage: string;
	isSME: boolean = false;
	fareData = [];
	selectedSeatArr: any;
	

	selectedPaymentMethod = [];

	@ViewChild('showPaymentInfo') showPaymentInfo: ElementRef;
	onlinePaymentAmount=[];
	onlinePaymentCharge=[];
	selectedFlightDirectionForPayment: string;
	errorMessage: string;
	isResponse: boolean;
	paymentStatusResponse: any;
	affiliateDetails: any;
	originalNetPayable=[];
	canHold=[];

	@ViewChild('showDuplicateBooking') showDuplicateBooking: ElementRef;
	duplicateBookingMsg: any;
	disableBtn: boolean;

	isIncreasedPrice = false;
	oldTtotalNetPayable = 0;
	messageList: { status: boolean; messageList: any[]; };

	@ViewChild('showHoldInfo') showHoldInfo: ElementRef;
	allowHold=[];
	selectedHoldIndex: any = 0;
	selectedInfantAssociate = new Map();

	constructor(private router: Router,
		private commonService: CommonService,
		private fb: FormBuilder,
		private affiliateService: AffiliateService,
		private bookingService: BookingService,
		private bookingServiceRequest: BookingRequest,
		private postService: PostService,
		private returnBookingService: BookingRequest,
		private bookingRequestService: BookingRequestService,
		private travellerService: TravellerService,
		private excelServc: ExcelService,
		private db: AngularFireDatabase,
		private calculatePriceService: CalculatePriceService,
		private activatedRoute: ActivatedRoute,
		private store: Store<InitialState>,
		private notificationService : NotificationService) {
		this.store.select('flightReducers').subscribe((items : any) => {
			this.isOffer = items.showOfferPrice ? items.showOfferPrice : false
			});
	
	}
	data: any = [];
	InboundData: any = [];
	passengers = [1, 2, 3]
	ngOnInit() {
		window.scroll(0, 0);
		this.messageList = this.notificationService.messageObj
		// this.selectedPaymentMethod = {
		// 	'outbound' : "agent-balance",
		// 	'inbound' : "agent-balance"
		// }
		// this.onlinePaymentAmount = {
		// 	'outbound' : 0,
		// 	'inbound' : 0
		// }
		// this.onlinePaymentCharge = {
		// 	'outbound' : 0,
		// 	'inbound' : 0
		// }
		// this.originalNetPayable = {
		// 	'outbound' : 0,
		// 	'inbound' : 0
		// }
		this.travellerForm = this.fb.group({
			"corporateGstNumber": ['', Validators.pattern("^([0][1-9]|[1-2][0-9]|[3][0-7])([A-Z]{5})([0-9]{4})([A-Z]{1}[1-9A-Z]{1})([Z]{1})([0-9A-Z]{1})+$")],
			"corporateName": [''],
			"corporateMobile": ['', [Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(10)]],
			"corporateEmail": [''],
			'additionalComments': [''],
			"passengerList": this.fb.array([]),
			"email": [Validators.required],
			"phone": ['', [Validators.maxLength(10), Validators.minLength(10), Validators.pattern('^[0-9]*$')]],
			"mobile": [Validators.required, [Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(10)]],
			"paxDetail": this.fb.array([])
		})

		this.bookingRequestService.getLimit.subscribe(res => {
			this.creditLimit = res['creditLimit'];
			this.invalidCredit = res['invalidCredit'];
			this.limitType = res['type'];
		})

		//
		if(localStorage.user_details != undefined) {
			if(JSON.parse(localStorage.user_details)['is_di_account'] != null) {
				this.isDiAccount = (JSON.parse(localStorage.user_details)['is_di_account'] == 'true')
			}
			else{
				this.isDiAccount = false;
			}
		}
		if(localStorage.user_details != undefined) {
			this.affiliateDetails = JSON.parse(localStorage.user_details)
		}else{
			this.affiliateDetails = null
		}
		// this.getCreditLimit();	// get credit limit of affiliate
		this.getNationality();	// bind countries into passport issuing country and nationality
		this.makeOutboundData(); // bind outbound data

		

		this.commonService.getAirline(`airline`).subscribe(res => {
			this.airlineArr = JSON.parse(res['_body']);
			// console.log('Airline MAster', this.airlineArr)
		});

		var url = window.location.href.slice(window.location.href.indexOf('?') + 1);
		this.checkPayment(url)
		
	}
	checkPayment(url){
		if (url.includes("paymentIndex=1")) {	
			this.active = true
			this.activatedRoute.queryParams.subscribe(params => {	
				let amount = params['amount'];
				let orderID = params['orderId'];
				let transId = params['referenceNumber'];
				let hasKey = params['key'];
	
				var formValue = JSON.parse(localStorage.getItem('formValue'));
				setTimeout(() => {
					this.setFormValue(formValue)
				}, 3000);
				if (!(location.href.includes('error=true')) && formValue != null) {
					let obj = {
						"orderId" : orderID,
						"trackingId" : transId,
						"amount" : amount,
						"key" : hasKey,
						"status" : "success"
					}	
					formValue['chequeNumber'] = orderID;
					formValue['transactionRef'] = transId;
					 
					let selectedFlightIndexForPayment = localStorage.getItem('selectedFlightIndexForPayment')
					if (!params.hasOwnProperty('ft-cash')) {
						this.commonService.hdfcPayment(obj, 'getPaymentStatus').subscribe(data => {
							if (data['_body'] != "") {
								let response = JSON.parse(data['_body'])
								if (response.length > 0) {
									this.isResponse = true;
									this.paymentStatusResponse = response[0].json_doc_response;
									window.history.replaceState({}, document.title, "/#/pages/" + "multi-city-lcc-traveller");
									if (selectedFlightIndexForPayment != null) {
										this.doBooking(formValue, false, selectedFlightIndexForPayment)
									}
								}
								else {
									this.isResponse = false;
									console.log('Failed Payment')
								}


							}
							else {
								this.router.navigate([`error?paymentIndex=0&error=true&referenceNumber=${transId}&orderId=${orderID}&amount=${amount}&key=${hasKey}`]);
								this.errorMessage = "Sorry ! Unable to process now, please try again.";
								console.log(this.errorMessage)
							}
						}, error => {
							console.log(error)
						})
					} else {
						console.log('Booking with ft-Cash')
						window.history.replaceState({}, document.title, "/#/pages/" + "multi-city-lcc-traveller");
						this.doBooking(formValue, false, selectedFlightIndexForPayment)
					}
					
				}
				else {
					this.router.navigate([`error?paymentIndex=0&error=true&referenceNumber=${transId}&orderId=${orderID}&amount=${amount}&key=${hasKey}`]);
					this.errorMessage = "Sorry ! Unable to process now, please try again.";
					console.log(this.errorMessage)
				}
				console.log(params); // Print the parameter to the console. 
			});																																																																																																																																																																																																																																																																				
		}
	}
	setFormValue(formValue){
		console.log(formValue)
		this.travellerForm.controls.corporateName.setValue(formValue.corporateName);
		this.travellerForm.controls.corporateGstNumber.setValue(formValue.corporateGstNumber);
		this.travellerForm.controls.corporateMobile.setValue(formValue.corporateMobile);
		this.travellerForm.controls.corporateEmail.setValue(formValue.corporateEmail);
		this.travellerForm.controls.email.setValue(formValue.email);
		this.travellerForm.controls.phone.setValue(formValue.phone);
		this.travellerForm.controls.mobile.setValue(formValue.mobile);
		for (let i = 0; i < formValue.passengerList.length; i++) {
			const values = formValue.passengerList[i]
			this.travellerForm.controls.passengerList['controls'][i].controls.firstName.setValue(values.firstName);
			this.travellerForm.controls.passengerList['controls'][i].controls.lastName.setValue(values.lastName);
			this.travellerForm.controls.passengerList['controls'][i].controls.title.setValue(values.title);
			this.travellerForm.controls.passengerList['controls'][i].controls.gender.setValue(values.gender);
			if (!this.isDomestic) {
				this.travellerForm.controls.passengerList['controls'][i].controls.passportNo.setValue(values.passportNo);
				this.travellerForm.controls.passengerList['controls'][i].controls.nationality.setValue(values.nationality);
				this.travellerForm.controls.passengerList['controls'][i].controls.issuingCountry.setValue(values.issuingCountry);
				this.travellerForm.controls.passengerList['controls'][i].controls.expiryDate.setValue(values.expiryDate);
				this.travellerForm.controls.passengerList['controls'][i].controls.dob.setValue(values.dob);
			}
			this.travellerForm.controls.passengerList['controls'][i].controls.seatPrefences.setValue(values.seatPrefences);
			this.travellerForm.controls.passengerList['controls'][i].controls.specialServices.setValue(values.specialServices);
			this.travellerForm.controls.passengerList['controls'][i].controls.mealServices.setValue(values.mealServices);
			this.travellerForm.controls.passengerList['controls'][i].controls.baggageServices.setValue(values.baggageServices);
			this.travellerForm.controls.passengerList['controls'][i].controls.travellerId.setValue(values.travellerId); 
			
			console.log(this.travellerForm)
		}
		//Set Pax Info, Contact Info, Gst Info,
	}
	setPersonalDetails(){
		let user_details = JSON.parse(localStorage.user_details)
		if (!this.travellerForm.controls.email.touched) {
			this.travellerForm.controls.email.setValue(user_details.primaryEmail)
		}
		if (this.travellerForm.controls.email.value == null) {
			this.travellerForm.controls.email.setValue(user_details.primaryEmail)
		}
		this.travellerService.viewAllGSTbyid().subscribe(res=>{
			this.allgstArr = res
			if (this.allgstArr.length > 0) {
				// console.log('All Saved GST',this.allgstArr)
			}
		},
		err=>{

		})
	}
	selectGST(item){
		this.selectedGST = {}
		this.selectedGST = item
	}
	getTravellers(val) {
		val = val.currentTarget.value;
		let valueLength  = 3
		if(this.supplierName == 'AMADEUS_V4' || this.supplierName == 'GALILEO' || this.data[0].priceType.toLowerCase().includes('ndc')){
		   valueLength = 2;
		}
			if (val.length >= valueLength) {
				this.travellerService.viewAllTravellersForBooking(val).subscribe(res => {
					let data: any
					data = res;
					let uniqueArr = [];
					data.forEach(el => {
						let filteredArr = uniqueArr.filter((v) => {
							if (v.firstname == el.firstname) {
								return v;
							}
						})
						if (filteredArr.length == 0) {
							uniqueArr.push(el)
						}
					});
					this.passengerDetails = uniqueArr;
				})
			}
	}
	public getOutBoundBaggageEvent(data,i) {
		this.outboundBaggageCharges = 0;
		data.forEach(v => {
			if (v.baggage != null && v.baggage != undefined) {
				v.baggage.forEach(el => {
					if (el.price != undefined) {
						this.outboundBaggageCharges += Number(el.price)
					}
				})
			}


		})
		this.outboundSelectedBaggageArr = data;
		this.calculateNetPayable(false,i);
	}

	public getInboundBaggageEvent(data,i) {
		this.inboundBaggageCharges = 0;
		data.forEach(v => {
			if (v.baggage != null && v.baggage != undefined) {
				v.baggage.forEach(el => {
					if (el.price != undefined) {
						this.inboundBaggageCharges += Number(el.price)
					}
				})
			}


		})
		this.inboundSelectedBaggageArr = data;
		this.calculateNetPayable(true,i);
	}

	public getOutboundMealEvent(data,i) {
		this.outboundMealCharges = 0;
		data.forEach(v => {
			if (v.meals != null && v.meals != undefined) {
				v.meals.forEach(el => {
					if (el.price != undefined) {
						this.outboundMealCharges += Number(el.price)
					}
				})
			}
		})

		this.outboundSeletedMealArr = data;
		this.calculateNetPayable(false,i);
	}

	public getinboundMealEvent(data,i) {
		this.inboundMealCharges = 0;
		data.forEach(v => {
			if (v.meals != null && v.meals != undefined) {
				v.meals.forEach(el => {
					if (el.price != undefined) {
						this.inboundMealCharges += Number(el.price)
					}
				})
			}
		})

		this.inboundSelectedMealArr = data;
		this.calculateNetPayable(false,i);
	}

	public getOutboundSeatEvent(data,i) {
		this.outboundSeatCharges = 0;
		data.forEach(v => {
			if (v.seats != null && v.seats != undefined) {
				v.seats.forEach(el => {
					if (el.price != undefined) {
						this.outboundSeatCharges += Number(el.price)
					}
				})
			}
		})
		this.outboundSeletedSeatArr = data;
		this.calculateNetPayable(false,i);
	}

	public getSeatEvent(data) {
		console.log('Seat  selection event called')
		// this,this.seatCharges = 0;
		// data.forEach(v=>{ 
		// 	if(v.seats  != null && v.seats != undefined) {
		// 		v.seats.forEach(el=>{ 
		// 			if(el.price != undefined) {
		// 				this.seatCharges += Number(el.price)
		// 			}
		// 		}) 		
		// 	}
		// })
		// this.selectedSeatArr = data;
		// this.calculateNetPayable(false,0);
	}

	calculateNetPayable(inbound,i) {
		let totalFare = this.data[i].fares[0].total
		this.netPayable = totalFare;
		this.affiliateMarkup = Math.round(this.data[i].fares[0].fareComponents.AFFILIATE_MARKUP);

		if(this.data[i].fares[0].fareComponents.hasOwnProperty('NET_PAYABLE')) {
			this.netPayable = Math.round(this.data[i].fares[0].fareComponents.NET_PAYABLE);
			this.netPayable = Math.round(this.netPayable+ this.mealCharges+this.seatCharges+this.baggageCharges);
			// this.selectedFare[0].fareComponents.NET_PAYABLE = this.netPayable;
		}
		else{
			this.netPayable = Math.round(this.netPayable+ this.mealCharges+this.seatCharges+this.baggageCharges);

		}
		this.originalNetPayable[i]= this.netPayable
			if (this.selectedPaymentMethod[i] != 'agent-balance') {
				this.onlinePaymentAmount[i] = Math.round(Number(this.netPayable) + (Number(this.netPayable) * Number(environment.payment_gateway_charges)) / 100);
				this.onlinePaymentCharge[i] = Math.round((Number(this.netPayable) * Number(environment.payment_gateway_charges)) / 100);
			}
		this.data[i]['bookingAmount'] = this.netPayable
		this.netTotalayable = Math.round(this.totalFare + this.outboundMealCharges + this.outboundSeatCharges + this.outboundBaggageCharges);
		totalFare = Math.round(this.data[i].fares[0].total + this.outboundMealCharges + this.outboundSeatCharges + this.outboundBaggageCharges);
		this.fareData[i]['netTotalayable'] = Math.round(totalFare);
		this.fareData[i]['netPayable'] = this.data[i]['bookingAmount']
		this.bookingRequestService.getCreditLimit(this.data[i]['isLCC'], this.netTotalayable, this.flightRequest.affiliate, 'outbound');		
		this.totalBookingPrice =  this.netTotalayable;

	}
	refreshCreditLimit(i){
		this.bookingRequestService.getCreditLimit(this.data[i]['isLCC'], this.netTotalayable, this.flightRequest.affiliate, 'outbound');		
	}
	ngOnDestroy() {
	}

	// get controls value of passengerlist formarray
	get DynamicFormControls() {
		return <FormArray>this.travellerForm.get('passengerList');
	}
	// function to create passengerlist formarray object for traveller form
	buildItem(passengerType,i) {
	
		return this.fb.group({
			"title": [''],
			"firstName": ['', Validators.required],
			'lastName': ['', Validators.required],
			'gender': ['', Validators.required],
			'dob': [''],
			'passportNo': [''],
			'nationality': [''],
			'issuingCountry': [''],
			'associateInfo': [''],
			'expiryDate': [''],
			'passengerType': [passengerType],
			'frequentflyers': [''],
			'frequentflyersNumber': [''],
			'specialServices': [''],
			'mealServices': [''],
			'seatPrefences': [''],
			'baggageServices': [''],
			'travellerId': [''],
		})
	}
	setValidator(i){
		if (!this.isDomestic) {
			this.travellerForm.controls.passengerList['controls'][i].controls.dob.setValidators([Validators.required]);
			this.travellerForm.controls.passengerList["controls"][i].controls.passportNo.setValidators([Validators.required]);
			this.travellerForm.controls.passengerList["controls"][i].controls.expiryDate.setValidators([Validators.required]);
			
		} else {
			this.travellerForm.controls.passengerList['controls'][i].controls.dob.setValidators(null);
			this.travellerForm.controls.passengerList["controls"][i].controls.passportNo.setValidators(null);
			this.travellerForm.controls.passengerList["controls"][i].controls.expiryDate.setValidators(null);
		}

		if (this.data[0] != undefined) {
			if (this.data[0].supplierCode=='airasia' || this.data[0].supplierCode == "airasia_v2") {
				this.travellerForm.controls.passengerList['controls'][i].controls.dob.setValidators([Validators.required]);
				this.travellerForm.controls.passengerList['controls'][i].controls.nationality.setValidators([Validators.required]);
			}
		}
		this.travellerForm.updateValueAndValidity();
	}
	// function to bind pax details 
	bindTraveller(val, param) {
		val = val.currentTarget.value;
		if (val.length >= 3) {
			this.travellerService.viewAllTravellersForBooking(val).subscribe(res => {
				let data: any
				data = res;
				let uniqueArr = [];
				data.forEach(el => {
					let filteredArr = uniqueArr.filter((v) => {
						if (v.firstname == el.firstname) {
							return v;
						}
					})
					if (filteredArr.length == 0) {
						uniqueArr.push(el)
					}
				});
				this.passengerDetails = uniqueArr;
			})
		}
	}
	// fucntion to set pax details for passengers after first name selection
	setPaxDetails(values, i) {
		this.paxSubmitted = false
		this.travellerForm.controls.passengerList['controls'][i].controls.firstName.setValue(values.firstname);
		this.travellerForm.controls.passengerList['controls'][i].controls.lastName.setValue(values.lastname);
		this.travellerForm.controls.passengerList['controls'][i].controls.title.setValue(values.title);
		this.travellerForm.controls.passengerList['controls'][i].controls.gender.setValue(values.gender);
		if (!this.isDomestic) {
			this.travellerForm.controls.passengerList['controls'][i].controls.passportNo.setValue(values.passport_no);
			this.travellerForm.controls.passengerList['controls'][i].controls.nationality.setValue(values.nationality);
			this.travellerForm.controls.passengerList['controls'][i].controls.nationality.setValidators(null);
			this.travellerForm.controls.passengerList['controls'][i].controls.issuingCountry.setValue(values.issuing_country);
			this.travellerForm.controls.passengerList['controls'][i].controls.expiryDate.setValue(values.expiry_date);
			this.travellerForm.controls.passengerList['controls'][i].controls.dob.setValue(values.dob);
		}
		this.travellerForm.controls.passengerList['controls'][i].controls.seatPrefences.setValue(values.seat_prefences);
		this.travellerForm.controls.passengerList['controls'][i].controls.specialServices.setValue(values.special_services);
		this.travellerForm.controls.passengerList['controls'][i].controls.mealServices.setValue(values.meal_prefences);
		this.travellerForm.controls.passengerList['controls'][i].controls.baggageServices.setValue(values.baggage_services);
		this.travellerForm.controls.passengerList['controls'][i].controls.travellerId.setValue(values.id);
		this.bindDummyAdultArr("")
	}
	// function to get countries list
	getNationality() {
		this.affiliateService.getCountries().subscribe(res => {
			this.nationalityArr =  JSON.parse(res["_body"]);;
			// console.log('counties',res)
		})
	}
	// function to bind traveller first name
	bindTravellerFirstName(e, i, type, passenger) {
		// if (type == 'Infant') {
		// 	this.bindDummyAdultArr(e.target.value)
		// }
		this.travellerForm.controls.passengerList['controls'][i].controls.firstName.setValue(e.target.value);
		if (e.currentTarget.value.length > 2 && e.key == 'Tab') {
			if (passenger.length > 0 && passenger != undefined) {
				this.setPaxDetails(passenger[0], i);
			}
		}
		if (type == 'Adult') {
			this.bindDummyAdultArr(e.target.value)
		}
	}
	setGender(value, i) {
		this.travellerForm.controls.passengerList['controls'][i].controls.gender.setValue('Male');
		if (value == 'Mr') {
			this.travellerForm.controls.passengerList['controls'][i].controls.gender.setValue('Male');
		}
		if (value == 'Mrs') {
			this.travellerForm.controls.passengerList['controls'][i].controls.gender.setValue('Female');
		}
		if (value == 'Ms') {
			this.travellerForm.controls.passengerList['controls'][i].controls.gender.setValue('Female');
		}
	}

	private openHoldInfoModal(): void {
		this.showHoldInfo.nativeElement.click();
	}

	showHoldInfoFn(values, isHold,i,forceBooking,checkDuplicate) {
		 
		this.selectedHoldIndex = i
		if (!this.data[i]['isLCC']) {
			this.openHoldInfoModal()
		}else{
			this.issueTicket(values, isHold,i,forceBooking,checkDuplicate)
		}
	}
	setAllowHold(event,i){
		if (event.target.checked == true) {
			this.allowHold[i] = true
		}
		else {
			this.allowHold[i] = false
		}
	}
	

	// function to issue ticket for booking request
	async issueTicket(values, isHold,i,forceBooking,checkDuplicate) {
		 
		this.active = true;
		const invalid = [];
		this.messageList['messageList'] = []
		this.notificationService.showMessage(this.messageList)
		localStorage.setItem('selectedFlightIndexForPayment',i)
		const controls = this.travellerForm.controls;
			for (const name in controls) {
				if (controls[name].invalid) {
					invalid.push(name);
					if(name == 'passengerList'){
						this.paxSubmitted = true
						break;
					  }else{
						this.contactSubmitted = true
						break;
					  }
				}
			}
			// console.log('invalid field on submit',invalid)
			if(invalid.length  > 0) {
				this.panelOpenState = true;
				this.openTravellerTab = true;
				this.setStep(1)
				this.active =false;
				return false;
			}
		if (this.invalidCredit && !isHold) {
			this.active = false;
			return false
		}
		if(this.isSME) {
			if(values.corporateName == "" || values.corporateEmail == "" || values.corporateGstNumber == "" || values.corporateMobile == "") {
				this.setStep(3)
				this.showError('warning-box','Please Add GST Details',undefined)
				this.active =false;
				return false;
			}
		}
		// FIX ME
		// if(this.data[0].priceType == "SPICEMAX") {
		// 	if(this.selectedSeatArr == undefined) {
		// 		this.setStep(4)
		// 		this.active =false;
		// 		this.warningMessage = "Seat is mandatory with spicemax fare";
		// 		return false;
		// 	}
		// 	else if(this.selectedSeatArr[0].seats.length  == 0){
		// 		this.setStep(4)
		// 		this.active =false;
		// 		this.warningMessage = "Seat is mandatory with spicemax fare";
		// 		return false;

		// 	}
		// 	else{
		// 		this.warningMessage = "";
		// 	}
		// }
		// else{
		// 	this.warningMessage = "";
		// }
		values.isHold = isHold;
		values.passengerName = values.passengerList[0].firstName + ' ' + values.passengerList[0].lastName;
		values.bookingDate = new Date();
		if (this.isDomestic == true) {
			values.isDomestic = 'true';
		}
		else {
			values.isDomestic = 'false';
		}
		values.sCode = '';
		values.seats = this.outboundSeletedSeatArr;
		values.baggage = this.outboundSelectedBaggageArr;
		values.meals = this.outboundSeletedMealArr;
		values.xlCode = JSON.parse(localStorage.getItem('user_details'))['xlAccountCode'];
		values.passengerList = this.createPassengerData(values.passengerList, 'TICKET-');	// create passenger/pax data and added dynamic tickets for per pax
		this.data[i]['netPayable'] = this.data[i]['bookingAmount'];  //FIX ME
		localStorage.setItem('formValue',JSON.stringify(values))
		 
		if (checkDuplicate == true) {
			let duplicateBookingRes = {};
			if (environment.enableBooking) {
				duplicateBookingRes = await this.doDuplicateBookingCheck(values, this.data,i); 
			}
			console.log('Duplicate booking check  response', duplicateBookingRes)
			if (duplicateBookingRes.hasOwnProperty('duplicate')) {
				if (duplicateBookingRes['duplicate'] == true) {
					this.duplicateBookingMsg = {
						text: duplicateBookingRes['errorMessage'],
						reference_no: duplicateBookingRes['duplicateReference'],
						direction : i,
						isHold : isHold,
						status : duplicateBookingRes['status']
					}
					this.disableBtn = false
					this.active = false;
					this.openDuplicateBookingModal()
					return false
				} else {
					if (this.selectedPaymentMethod[i] == 'agent-balance') {
						this.doBooking(values,isHold,i)
					}else{
						this.active =false;
						this.selectedFlightDirectionForPayment = i
						this.openPaymentInfoModal()
					}
				}
			}
		}else{
			values['forceBooking'] = forceBooking
			if (forceBooking) {
				values['duplicateReference'] = this.duplicateBookingMsg['reference_no']
			}
			if (this.selectedPaymentMethod[i] == 'agent-balance') {
				this.doBooking(values,isHold,i)
			}else{
				this.active =false;
				this.selectedFlightDirectionForPayment = i
				this.openPaymentInfoModal()
			}

		}
	}
	private openPaymentInfoModal(): void {
		this.showPaymentInfo.nativeElement.click();
	}
	saveTravellers(values) {
		values.userDetails = JSON.parse(localStorage.getItem('user_details'));
		values.travellers = this.travellerForm.controls.passengerList.value;
		for (let i = 0; i < values.travellers.length; i++) {
			const element = values.travellers[i];
			if (i == 0) {
				element['mobile_number'] = this.travellerForm.controls.mobile.value
			}
			else{
				element['mobile_number'] = ''
			}
			this.checkifExist(element)
		}
	}
	checkifExist(values) {
		let tArr;
		this.travellerService.checkifExist(values).subscribe(res => {
			tArr = res
			if (tArr.length == 0) {
				this.travellerService.saveAllTraveller(values).subscribe(res => {
					// console.log('saved', res)
				})
			}
		})
	}

	doDuplicateBookingCheck(value, flightData,i) {
		return new Promise((resolve, reject) => {
			let obj = this.bookingServiceRequest.multiCityBookingRequest(value, flightData, this.limitType,i);
			this.request = obj;
      console.log('Create booking request : ',obj)
			this.postService.postMethod(`${environment.url}/book/checkduplicate`, obj).subscribe(res => {
				
				if (res != "") {
					resolve(res);
				}
				else {
					resolve('');
				}
			}, err => {
				resolve('');
			})

		})
	}


	creatingBookingRequest(value, flightData,i) {
		return new Promise((resolve, reject) => {
			let obj = this.bookingServiceRequest.multiCityBookingRequest(value, flightData, this.limitType,i);
			this.request = obj;
      console.log('Create booking request : ',obj)
			this.postService.postMethod(`${environment.url}/book/test`, obj).subscribe(res => {
				
				if (res != "") {
					resolve(res);
				}
				else {
					resolve('');
				}
			}, err => {
				resolve('');
			})

		})
	}

	// function to view offer
	viewOfffer() {
		if (this.isOffer) {
			this.isOffer = false;
		}
		else {
			this.isOffer = true;
		}
	}

	// traveller form validation
	get email() {
		return this.travellerForm.controls.email;
	}
	get mobile() {
		return this.travellerForm.controls.mobile;
	}
	// create outbound data request
	getFlightData(flight){
		let x = []
			x.push(flight)
			return x
	}
	makeOutboundData() {
		
    this.data=JSON.parse(localStorage.getItem('selectedLccMultiCityFLight'));
    console.log(this.data)
		// this.data[0].segments = this.data[0].segments.splice(1,1)
		// console.log('Selected Flight', this.data);
		this.flightRequest = JSON.parse(sessionStorage.getItem('ActiveSearchRequest'));
		this.total = this.data[0].selFlightPrice;
		this.totalBookingPrice += this.total;
		const localThis = this;
		
		for (let i = 0; i < this.data.length; i++) {
			const element = this.data[i];
			this.makeFareObj(i)
		}
		this.airlineCode = this.data[0].airline.code;
		// passport expiry min  date
		let segmentLength = this.data[0].segments.length
		this.minDate = new Date(this.data[0].segments[segmentLength - 1].legs[0].departureDateTime)
		let expiry_month = this.minDate.getMonth()+6
		this.minDate = new Date(this.minDate.setMonth(expiry_month))
		this.totaltax = (this.totalFare - this.baseFare) + this.adultTaxFare + this.childTaxFare;
		this.isDomestic = this.flightRequest.domestic;
		this.departure = this.flightRequest.sectors[0].originDetails.code;
		this.arrival = this.flightRequest.sectors[this.flightRequest.sectors.length-1].destinationDetails.code;
		let d = new Date(this.flightRequest.sectors[0].journeyStartDate);
		this.date = d.toDateString();
		this.noOfAdult = this.flightRequest.noOfAdults;
		this.noOfChild = this.flightRequest.noOfChild;
		this.noOfInfant = this.flightRequest.noOfInfants;
		this.totalPassengerCount = Number(this.noOfAdult) + Number(this.noOfChild) + Number(this.noOfInfant);
		for (let i = 1; i <= this.flightRequest.noOfAdults; i++) {
			this.passengerList = this.travellerForm.get('passengerList') as FormArray;
			this.passengerList.push(this.buildItem('Adult',i));
			this.setValidator(this.passengerList.length-1);
		}
		for (let i = 1; i <= this.flightRequest.noOfChild; i++) {
			this.passengerList = this.travellerForm.get('passengerList') as FormArray;
			this.passengerList.push(this.buildItem('Child',i));
			this.setValidator(this.passengerList.length-1);
		}
		for (let i = 1; i <= this.flightRequest.noOfInfants; i++) {
			this.passengerList = this.travellerForm.get('passengerList') as FormArray;
			this.passengerList.push(this.buildItem('Infant',i));
			this.setValidator(this.passengerList.length-1);
		}

		//FIX THIS
		let paxDiscountCode = null;
		if (this.selectedFares[0].supplierInfo != null) {
			paxDiscountCode = this.selectedFares[0].supplierInfo.FareBasisCode;
		}
		if (this.flightRequest.noOfAdults > 0) {
			let obj = {
				"paxCount": this.flightRequest.noOfAdults,
				"paxDiscountCode": paxDiscountCode,
				"paxType": "ADT"
			}
			this.totalPaxArr.push(obj);
		}
		if (this.flightRequest.noOfChild > 0) {
			let obj = {
				"paxCount": this.flightRequest.noOfChild,
				"paxDiscountCode": paxDiscountCode,
				"paxType": "CHD"
			}
			this.totalPaxArr.push(obj);
		}
		if (this.flightRequest.noOfInfants > 0) {
			let obj = {
				"paxCount": this.flightRequest.noOfInfants,
				"paxDiscountCode": paxDiscountCode,
				"paxType": "INF"
			}
			this.totalPaxArr.push(obj);
		}
		
	
	
	}

	makeFareObj(i){
		this.selectedFares = this.data[i].fares
		if (this.selectedFares[0].priceType == "SME" && this.data[i].airline.code != '6E') {
			this.isSME = true
		}
		this.selectedPaymentMethod[i] = 'agent-balance'
		this.allowHold[i] = false
		this.onlinePaymentAmount[i] = 0
		this.onlinePaymentCharge[i]=0
		this.originalNetPayable[i]=0
		this.canHold[i] = true
		this.netCommission = this.selectedFares[0].fareComponents.COMMISSION;
		this.tds = this.selectedFares[0].fareComponents.TDS;
		this.baseFare = this.selectedFares[0].base;
		this.totalFare += this.selectedFares[0].total;
		this.data[i]['status'] = ""
		this.data[i]['bookingId'] = ""
		let isLCC = this.travellerService.checkLCC(this.data[i].airline.code);
		 
		if (isLCC) {
			let sKeys = []
			let outBoundskey = {
				"fareSellKey": this.selectedFares[0].supplierInfo ? this.selectedFares[0].supplierInfo.FareSellKey : "",
				"journeyKey": this.selectedFares[0].supplierInfo ? this.selectedFares[0].supplierInfo.JourneySellKey : ""
			}
			sKeys.push(outBoundskey)
			this.data[i].supplierInfo.sKeys = sKeys
			this.getBaggage(this.data[i], i);
		}
		this.data[i]['isLCC'] = isLCC
		this.fareData.push([])
		this.calculateNetPayable(false,i);
		this.fareData[i]['childBaseFare'] = 0
		this.fareData[i]['childTotalFare'] = 0
		this.fareData[i]['childTaxFare'] = 0
		this.fareData[i]['infBaseFare'] = 0
		this.fareData[i]['infTotalFare'] = 0
		this.fareData[i]['infTaxFare'] = 0
		if (this.selectedFares[0].travellerFares.ADT) {
			this.fareData[i]['adultBaseFare'] = this.selectedFares[0].travellerFares.ADT.base
			this.fareData[i]['adultTotalFare'] = this.selectedFares[0].travellerFares.ADT.total
			this.fareData[i]['adultTaxFare'] = this.calculatePriceService.calculateTax(this.selectedFares[0].travellerFares.ADT.fareComponents)
			this.adultBaseFare += this.selectedFares[0].travellerFares.ADT.base;
			this.adultTotalFare += this.selectedFares[0].travellerFares.ADT.total;
			this.adultTaxFare += this.calculatePriceService.calculateTax(this.selectedFares[0].travellerFares.ADT.fareComponents)
		}
		if (this.selectedFares[0].travellerFares.CHD) {
			this.fareData[i]['childBaseFare'] = this.selectedFares[0].travellerFares.CHD.base
			this.fareData[i]['childTotalFare'] = this.selectedFares[0].travellerFares.CHD.total
			this.fareData[i]['childTaxFare'] = this.calculatePriceService.calculateTax(this.selectedFares[0].travellerFares.CHD.fareComponents)
			this.childBaseFare += this.selectedFares[0].travellerFares.CHD.base;
			this.childTotalFare += this.selectedFares[0].travellerFares.CHD.total;
			this.childTaxFare += this.calculatePriceService.calculateTax(this.selectedFares[0].travellerFares.CHD.fareComponents)
		}
		if (this.selectedFares[0].travellerFares.INF) {
			this.fareData[i]['infBaseFare'] = this.selectedFares[0].travellerFares.INF.base
			this.fareData[i]['infTotalFare'] = this.selectedFares[0].travellerFares.INF.total
			this.fareData[i]['infTaxFare'] = this.calculatePriceService.calculateTax(this.selectedFares[0].travellerFares.INF.fareComponents)
			this.infBaseFare += this.selectedFares[0].travellerFares.INF.base;
			this.infTotalFare += this.selectedFares[0].travellerFares.INF.total;
			this.infTaxFare += this.calculatePriceService.calculateTax(this.selectedFares[0].travellerFares.INF.fareComponents)
		}
		// this.bookingRequestService.getCreditLimit(this.data[i]['isLCC'], this.netPayable, this.flightRequest.affiliate, 'outbound');
		if (this.data[i].supplierCode == "6E") {
			this.supplierName = "INDIGO";
		}
		else if (this.data[i].supplierCode == "SG") {
			this.supplierName = "SPICEJET";
		}
		else if (this.data[i].supplierCode == "G8") {
			this.supplierName = "GO_AIR";
		}
		else {
			this.supplierName = this.data[i].supplierCode;
		}
		if (this.data[i].supplierCode=='airasia') {
			this.warningMessage = "Free Baggage available."
		}

		let fareRuleObj = {
			"supplierName": this.data[i].fares[0].supplierInfo ? this.data[i].fares[0].supplierInfo.SUPPLIER : "",
			"classOfService": this.data[i].fares[0].supplierInfo ? this.data[i].fares[0].supplierInfo.ClassOfService : "",
			"fareBasisCode": this.data[i].fares[0].supplierInfo ? this.data[i].fares[0].supplierInfo.FareBasisCode : "",
			"ruleNumber": this.data[i].fares[0].supplierInfo ? this.data[i].fares[0].supplierInfo.RuleNumber : "",
			"signature": this.data[i].fares[0].supplierInfo ? this.data[i].supplierInfo.Signature : "",
			"supplierInfo": this.data[i].fares[0].supplierInfo
		}

		if(this.data[i].supplierCode == "SABRE") {
			fareRuleObj.supplierName = this.data[i].supplierCode;
			let supplierInfo = {
				"Origin" : this.data[i].segments[0].originAirport.code,
				"Destination" : this.data[i].segments[0].destinationAirport.code,
				"TravelDate" : this.data[i].segments[0].legs[0].supplierInfo.DepartureDateTime
			}
			fareRuleObj["supplierInfo"] = supplierInfo;
			fareRuleObj["fareBasisCode"] = this.data[i].fares[0].supplierInfo ? this.data[i].fares[0].supplierInfo.FareBasisCode : "";
		}
		else if(this.data[i].supplierCode == "AMADEUS_V4"){
			let flightReq = JSON.parse(sessionStorage.ActiveSearchRequest)
			fareRuleObj['supplierName']  = "AMADEUS_V4" 
			let supplierInfo = {
				  "travellerInfo": {
					"ADT": flightReq['noOfAdults'],
					"CHD": flightReq['noOfChild'],
					"INF": flightReq['noOfInfants']
				  },
				  "sectors": [
					{
					  "departureDateTime": this.data[i].segments[0].legs[0].departureDateTime,
					  "departureCityCode": this.data[i].segments[0].legs[0].originAirport['code'],
					  "arrivalCityCode": this.data[i].segments[0].legs[0].destinationAirport['code'],
					  "airlineCode": this.data[i].segments[0].legs[0].operatingAirlineCode,
					  "flightNum": this.data[i].segments[0].legs[0].flightNumber,
					  "bookingClass": this.data[i].segments[0].legs[0].cabinClass
					}
				  ]
				}
				fareRuleObj["supplierInfo"] = supplierInfo;
		}
		this.getOutBoundFareRule(fareRuleObj,i);
	}
	
	getBaggage(selectedFlight,i){
		console.log('Selected flight before appending baggage',selectedFlight)
		let flightRequest = JSON.parse(sessionStorage.ActiveSearchRequest)

		let supplierInfo = selectedFlight.fares[0].supplierInfo
		if (supplierInfo == null) {
			return false
		}
		let supplierName = supplierInfo.SUPPLIER
		if (supplierName == undefined) {
			supplierName = selectedFlight.airline.code
		}
		let reqObj = {
			"supplierName": supplierName,
			"classOfService": supplierInfo.ClassOfService,
			"fareBasisCode": supplierInfo.FareBasisCode,
			"ruleNumber": supplierInfo.RuleNumber,
			"signature": selectedFlight.supplierInfo.Signature,
			"supplierInfo": {
				"FareSellKey": supplierInfo.FareSellKey,
				"JourneySellKey": supplierInfo.JourneySellKey,
				"travellers":{
					"ADT": flightRequest.noOfAdults,
					"CHD": flightRequest.noOfChild,
					"INF": flightRequest.noOfInfants
				},
				"FareMap": supplierInfo,
				"FlightNumber": selectedFlight.segments[0].legs[0].flightNumber,
				"BoardPoint": selectedFlight.segments[0].legs[0].originAirport.code,
				"OffPoint": selectedFlight.segments[0].legs[0].destinationAirport.code,
				"DepartureDateTime": selectedFlight.segments[0].legs[0].departureDateTime,
				"ArrivalDateTime": selectedFlight.segments[0].legs[0].arrivalDateTime
			}
		 }

		  
		 console.log('Baggage req :',reqObj)
		 this.travellerService.getBaggage(reqObj).subscribe(res=>{
			let freeCheckInBaggage = JSON.parse(res['_body'])
			let segment = selectedFlight.segments
			for (let i = 0; i < segment.length; i++) {
				const legs = segment[i].legs;
				for (let j = 0; j < legs.length; j++) {
					const elm = legs[j];
					if (!elm.hasOwnProperty('freeCheckInBaggage')) {
						elm['freeCheckInBaggage'] = freeCheckInBaggage
					}
					else{
						if (elm.freeCheckInBaggage == null || elm.freeCheckInBaggage == undefined) {
							elm['freeCheckInBaggage'] = freeCheckInBaggage
						}
					}
					
				}
				
			}

			selectedFlight.segments = segment
			console.log('Selected flight after appending baggage',this.data[i])
		},
		err=>{
			console.log('Error',err)
		})
	}

	getOutBoundFareRule(fareRuleObj,i){
		let fareRule = ""
		this.postService.postMethod(`${environment.url}/getFareRule`, fareRuleObj).subscribe(data => {
			try{
				if(data != ""  && data != null) {
					fareRule = data['fareRule'];
				}
				else{
					fareRule = "No Fare Rule Found";
				}
				this.data[i]['fareRule'] = fareRule
				console.log('fareRule ',data)
			}
			catch(Exception){
				console.log(Exception)
			}
			
		
		}, error => {
			fareRule = "No Fare Rule Found";
			this.data[i]['fareRule'] = fareRule
		})
	}
	// expansion panel for stepper
	async setStep(index: number) {
		this.step = index;
		window.scroll(0, 0)
		if (index == 2) {
			this.paxSubmitted = true;
			const invalid = [];
			const controls = this.travellerForm.controls.passengerList['controls'];
			for (let i = 0; i < controls.length; i++) {
				for (const name in controls[i].controls) {
					if (controls[i].controls[name].invalid && name !="passportNo" && name !="nationality" && name !="issuingCountry" && name != "expiryDate") {
						invalid.push(name);
					}
					if (name == "passportNo" && controls[i].controls['passportNo'].hasError('pattern') && controls[i].controls['passportNo'].touched) {
						invalid.push('passportNo');
					}
					if (name == "expiryDate" && (controls[i].controls['expiryDate'].errors || controls[i].controls['expiryDate'].value ==null)  && controls[i].controls['passportNo'].value != "" && controls[i].controls['passportNo'].touched) {
						invalid.push('expiryDate');
					}
				}
			}
			if (invalid.length > 0) {
				this.setStep(1)
			}
			else {
				this.setPersonalDetails()
				// this.seatMapArr.push([])
				// for (let i = 0; i < this.data.length; i++) {
				// 	let requestData = await this.bookingServiceRequest.multiCityBookingRequest(this.travellerForm.value, this.data, this.limitType,i);
				// 	if (this.data[i]['isLCC']) {
				// 		this.getSSR(requestData,i);
				// 	}
				// }
			}

		}
		else if (index == 3) {
			this.contactSubmitted = true
			const invalid = [];
			const controls = this.travellerForm.controls;
			for (const name in controls) {
				if (controls[name].invalid && name != "passengerList") {
					invalid.push(name);
				}
			}
			if (invalid.length > 0 && !invalid.includes('corporateEmail')) {
				this.setStep(2)
			}
		}else if (index == 4 ) {
			const invalid = [];
			this.contactSubmitted = true
			const controls = this.travellerForm.controls;
			for (const name in controls) {
				if (controls[name].invalid && name != "passengerList") {
					invalid.push(name);
				}
			}
			console.log('invalid field in step 3',invalid)
			if(invalid.length  > 0 && invalid.includes('corporateEmail')) {
				this.setStep(3)
			}
		}
	}

	// create passenger data object for booking request
	createPassengerData(passengerList, ticketNo) {
		return passengerList.map((v, i) => {
			let count = i + 1;
			v.ticketNo = ticketNo + count;
			return v;
		})
	}
	// create after booking request

	// create after booking pax details

	// traveller form validations
	get corporateEmail() {
		return this.travellerForm.controls.corporateEmail;
	}
	get corporateMobile() {
		return this.travellerForm.controls.corporateMobile;
	}
	// create dummy object for adult
	bindDummyAdultArr(value) {
		this.dummyAdultNameArr = [];
		this.travellerForm.controls.passengerList.value.forEach(el => {
			if (el.passengerType == 'Adult') {
				this.dummyAdultNameArr.push(el.firstName);
			}
		});
	}
	// function to revert back into search form page
	onFinished() {
		let searchId = localStorage.getItem('flightSearchId');
		this.router.navigate(['pages/flight', { searchId }]);
	}

	getBaggageDetails(code) {
		this.affiliateService.getBaggageDetails(code).subscribe(res => {
			this.baggageArr = res;
			// console.log('baggage data', this.baggageArr)
		})
	}

	bindGSTInfo(val) {
		val = val.currentTarget.value;
		if (val.length >= 3) {
			this.postService.getMethod(`${environment.adminUrl}/common/suggest/gstDetails/${val}`).subscribe(res => {
				let data = JSON.parse(res['_body']);
				let uniqueArr = [];
				data.forEach(el => {
					let filteredArr = uniqueArr.filter((v) => {
						if (v.name == el.name) {
							return v;
						}
					})
					if (filteredArr.length == 0) {
						uniqueArr.push(el)
					}
				});
				this.gstArr = uniqueArr;
			})
		}
	}
	bindTravellerGstName(e) {
		this.travellerForm.controls.corporateName.setValue(e.target.value);
	}
	setGstDetails(values) {
		this.travellerForm.controls.corporateName.setValue(values.name);
		this.travellerForm.controls.corporateGstNumber.setValue(values.gst_number);
		this.travellerForm.controls.corporateMobile.setValue(values.mobile);
		this.travellerForm.controls.corporateEmail.setValue(values.email);
	}

	baggageOnChange(el) {
		let filteredData = this.baggageArr.filter((v) => {
			if (el.value == v.id) {
				return v;
			}
		})
		if (filteredData.length > 0) {
			this.selectedBaggage = filteredData;
			this.showBaggage = true;
			this.totalFare += this.selectedFares[0].total + this.selectedBaggage[0].PRICE;
			this.total = this.selectedFares[0].total + this.selectedBaggage[0].PRICE;
		}
		else {
			this.selectedBaggage = [];
			this.showBaggage = false;
			this.totalFare += this.selectedFares[0].total;
			this.total = this.selectedFares[0].total;
		}
		// console.log(filteredData)
	}

	getSSR(requestData,i) {
		let fullArr: any = [];
		const lThis = this
		if (this.data[i]['isLCC']) {
			this.commonService.getmeal('ssrAvailability', requestData).subscribe(res => {
				fullArr = res;
				let legs = [];
				this.airlineCode
				if (res != null) {
					// this.ssrAvailability = Object.values(fullArr.legs);
					for (let i = 0; i < fullArr['legs'].length; i++) {
						const meals = Object.values(fullArr['legs'][i].meals)
						for (let j = 0; j < meals.length; j++) {
							const element = meals[j];
							element['status'] = 'notselected'
							const el = element['fare']
							if (el.hasOwnProperty("base")) {
								element['totalFare'] = Number(el.base);
							}
							else {
								element['totalFare'] = 0
							}


						}
						const baggages = Object.values(fullArr['legs'][i].baggages)
						for (let j = 0; j < baggages.length; j++) {
							const element = baggages[j];
							element['status'] = 'notselected'
							const el = element['fare']

							if (el.hasOwnProperty("base")) {
								// element['totalFare'] = Number(el.base) + Number(el.tax);
								element['totalFare'] = Number(el.base);
							}
							else {
								element['totalFare'] = 0
							}
						}
						let obj = {};
						obj["meals"] = meals;
						obj["baggages"] = baggages;
						obj["arrival"] = fullArr['legs'][i].arrival;
						obj["totalSelected"] = 0;
						obj["travellerCount"] = requestData.travellers.length;
						obj["flightInfo"] = requestData
						let tempTravellerArr = [];
						for (let k = 0; k < requestData.travellers.length; k++) {
							let tempTraveller = {};
							tempTraveller = Object.assign({}, requestData.travellers[k]);
							tempTravellerArr.push(tempTraveller);
						}
						obj["travellers"] = tempTravellerArr;
						obj["departure"] = fullArr['legs'][i].departure;
						obj["flightCode"] = fullArr['legs'][i].airline;
						obj["flightNo"] = fullArr['legs'][i].flightNo;
						legs.push(obj);
					}
				}
				this.ssrAvailability = legs;
				if (this.ssrAvailability.length == 0) {
					lThis.showloadError = true
				}
				// console.log('Meal Array', this.ssrAvailability);
			}, error => {
				this.showloadError = true
				console.log('Fetch Error', error);
			})
			this.commonService.getSeat('seatMap', requestData).subscribe(res => {
				let seatMap = res;
				let legs = [];
				for (let i = 0; i < seatMap['leg'].length; i++) {
					if(seatMap['leg'][i].seats.rows != null) {
						const values = Object.values(seatMap['leg'][i].seats.rows)
						for (let j = 0; j < values.length; j++) {
							const element = values[j];
							let charges = Object.values(element)
							for (let k = 0; k < charges.length; k++) {
								const ch = charges[k]
								ch['totalFare'] = 0;
								ch['status'] = 'notselected'
								const el = ch.charges
								if (el.hasOwnProperty("base")) {
									ch['totalFare'] = Number(el.base);
								}
								else {
									ch['totalFare'] = 0;
								}		
							}
						}
						let obj = {};
						obj["keys"] = Object.keys(seatMap['leg'][i].seats.rows);
						obj["values"] = values;
						obj["arrival"] = seatMap['leg'][i].arrival;
						obj["totalSelected"] = 0;
						obj["travellerCount"] = requestData.travellers.length;
						let tempTravellerArr = [];
						let associateTravellerInfoMap = new Map();
						for (let k = 0; k < requestData.travellers.length; k++) {
							let tempTraveller = {};
							tempTraveller = Object.assign({}, requestData.travellers[k]);
							tempTravellerArr.push(tempTraveller);
							if(this.travellerForm.controls.passengerList['controls'][k].controls.passengerType.value == "Infant"){
								associateTravellerInfoMap.set(this.travellerForm.controls.passengerList['controls'][k].controls.associateInfo.value, "")
							}


						}
						obj['associateTravellerInfoMap'] = associateTravellerInfoMap;
						obj["travellers"] = tempTravellerArr;
						obj["flightInfo"] = requestData
						obj["departure"] = seatMap['leg'][i].departure;
						obj["flightCode"] = seatMap['leg'][i].flightCode;
						obj["flightNo"] = seatMap['leg'][i].flightNo;
						legs.push(obj);
					}
					
					
				}
				this.seatMapArr[i] = legs;
				// console.log('Seat Map', JSON.stringify(this.seatMapArr));
			}, error => {
				this.showseatloadError = true
				console.log('Fetch Error', error);

			})
		}

	
	}
	openSearch(){
		let x ="BOMDEL2019-07-0511561662290641_RETURN_BOMDEL2019-07-0511561662290648_RETURN_DELBOM2019-07-1011561662290645"
		let searchId = localStorage.searchId+"_RETURN_"+localStorage.returnSearchId+"_RETURN_"+localStorage.specialReturnSearchId
		// window.location("/#/pages/flight;searchId="+searchId);
		this.router.navigate(['/pages/flight', { searchId }]);
	}
	setGST(values){
		if (values != null && values != undefined) {
			this.setGstDetails(values);
			setTimeout(() => {
				this.closeactiveModal();
			}, 100)
		}
	}
	private closeactiveModal(): void {
		this.closeactiveBtn.nativeElement.click();
	}

	setPaymentMethod(paymentMethod,checkNetPayable,i){
		this.selectedPaymentMethod[i] = paymentMethod
		if (paymentMethod != 'agent-balance') {
			this.canHold[i] = false
		}else{
			this.canHold[i] = true
		}
		console.log('Payment method : ',this.selectedPaymentMethod)
		if (checkNetPayable == true) {
			this.calculateNetPayable(false,i)
		}
	}

	async doBooking(values,isHold,i){
		let bookingRes = await this.creatingBookingRequest(values, this.data,i);// calling to create an live booking
		 
		if (localStorage.getItem('previuosBookingData') != null && localStorage.getItem('previuosBookingData') != undefined) {
			this.data = JSON.parse(localStorage.getItem('previuosBookingData'))
		}
		this.data[i]['bookingId'] = bookingRes['bookingId']
		// this.saveTravellers(values)
		this.bookingRequestService.checkifExistGST(values)

		if (bookingRes != "" && bookingRes['bookingResponse'] != null) {
			values.bookingRefNo = bookingRes['bookingResponse']['bookingId'];
		
			if (isHold) {
				values['bookingStatus'] = "HOLD";
				values.bookingInvoice = '';
				this.data[i]['status']  = "HOLD";
			}
			else {
				values['bookingStatus'] = "TICKETED";
				this.data[i]['status']  = "TICKETED";
				values.bookingInvoice = bookingRes['bookingResponse']['invoice_no'];
			
			}
			values.pnrNo = bookingRes['bookingId'];
			values['message'] = "Success";
			values['gdsPnr'] = bookingRes['pnr'];
			if (bookingRes['bookingResponse'].hold == true) {
				values['ticketedIssue'] = bookingRes['message']
				values['bookingStatus'] = "HOLD";
				this.data[i]['status']  = "HOLD";
			}
			values['bookingResp'] = bookingRes['bookingResponse'];
			this.OnWardsBookingResJson = bookingRes['bookingResponse'];
			this.data[i]['bookingId'] = bookingRes['bookingResponse']['bookingId']
			this.data[i]['invoice_no'] = bookingRes['bookingResponse']['invoice_no']
			if(bookingRes.hasOwnProperty("pnr")){
				this.data[i]["pnr"] = bookingRes["pnr"]
			}
			localStorage.setItem('previuosBookingData',JSON.stringify(this.data))
			localStorage.setItem("MULTI_CITY_LCC_BOOKING_"+this.data[i]['bookingId'], JSON.stringify(this.data));
			// localStorage.setItem("MULTI_CITY_LCC_BOOKING_" + values.bookingRefNo, JSON.stringify(this.OnWardsBookingResJson));
			// this.bookingService.sendSms(this.OnWardsBookingResJson)
			this.active = false;
			 
			this.paymentIndex = Number(localStorage.getItem('selectedFlightIndexForPayment'))+1
			let searchId = this.data[i]['bookingId'];
			this.step = 5
			this.setStep(5)
			if (this.data.length-1 == i) {
				localStorage.removeItem('previuosBookingData')
				localStorage.removeItem('selectedFlightIndexForPayment')
				this.router.navigate(['booking-status', { searchId }]);
			}
			this.active = false;
		}
		else {

			values['bookingStatus'] = "FAILED";
			values['message'] = bookingRes['message'];
			values.pnrNo = '';
			values['gdsPnr'] = '';
			values.bookingInvoice = '';

			values.bookingRefNo = bookingRes['bookingId'];
			// this.OnWardsBookingResJson = this.bookingRequestService.getBookingRequest(values, this.request, this.supplierName, this.data[i]['isLCC']);
			// localStorage.setItem("ONWARDS_BOOKING_"+res['bookingResponse'].bookingId, JSON.stringify(res['bookingResponse']));
			this.data[i]['status'] = "FAILED"
			localStorage.setItem("MULTI_CITY_LCC_BOOKING_"+this.data[i]['bookingId'], JSON.stringify(this.data));
			// localStorage.setItem("MULTI_CITY_LCC_BOOKING_" + values.bookingRefNo, JSON.stringify(this.OnWardsBookingResJson));
			console.log("Booking Failed");
			let searchId = this.data[i]['bookingId']
			localStorage.removeItem('previuosBookingData')
			localStorage.removeItem('selectedFlightIndexForPayment')
			this.router.navigate(['booking-status', { searchId }]);
			this.active = false;
		}
	}


	doOnlinePayment(){
		this.active = true
		let paymentMethod = ""
		localStorage.setItem('selectedFlightDirectionForPayment',this.selectedFlightDirectionForPayment)
		if (this.selectedPaymentMethod[this.selectedFlightDirectionForPayment] == 'credit-card') {
			paymentMethod = "OPTCRDC"
		}else if (this.selectedPaymentMethod[this.selectedFlightDirectionForPayment] =='netbanking') {
			paymentMethod = "netbanking"
		}else if (this.selectedPaymentMethod[this.selectedFlightDirectionForPayment] =='debit') {
			paymentMethod = "debit"
		}
		let redirectUrl = `http://localhost:4200/#/pages/multi-city-lcc-traveller`
		if (environment.name != 'default') {
			redirectUrl = `${environment.baseUrl}/#/pages/multi-city-lcc-traveller`
		}
		// let amount_charged = Number(this.netPayable) + (Number(this.netPayable) * Number(environment.payment_gateway_charges)) / 100;
		let limitType = 'cash'
		if(!this.isDiAccount && !this.isLCC){
			limitType = 'credit'
		}
		let amount = 1
		if (environment.name =='prod') {
			amount = this.onlinePaymentAmount[this.selectedFlightDirectionForPayment]
		}
		if (this.affiliateDetails != null && this.affiliateDetails != undefined) {
			let obj = {
				"affiliateCode" : this.affiliateDetails.xlAccountCode,
				"affiliateId" : this.affiliateDetails.affiliateId,
				"redirectUrl" : redirectUrl,
				"amount" : amount,
				"order_id" : "PT500069",
				"mobile" : this.affiliateDetails.mobile,
				"email" : this.affiliateDetails.primaryEmail,
				"payment_mode" : paymentMethod,
				"limit_type" : limitType,
				"original_amount" : this.originalNetPayable[this.selectedFlightDirectionForPayment]
			}

			if (this.selectedPaymentMethod[this.selectedFlightDirectionForPayment] == 'credit-card') {
				this.travellerService.doHDFCPayment(obj)
			}else{
				this.travellerService.doFTCASHPayment(obj)
			}
		}
	}


	private openDuplicateBookingModal(): void {
		this.showDuplicateBooking.nativeElement.click();
	}

	viewBooking(reference){
		window.open(`${environment.baseUrl}/#/booking-summary-detail;searchId=${reference}`, "_blank");
	}

	showError(type,message,detail){
		this.messageList['status'] = true
		let obj ={
			warningType : type,
			message : message,
			detail : detail
		}
		this.messageList['messageList'].push(obj)
		this.notificationService.showMessage(this.messageList)
	}

	travellerFareSlider = {
		loop: false,
		mouseDrag: true,
		touchDrag: true,
		pullDrag: true,
		dots: false,
		nav: true,
		autoplayHoverPause:true,
		autoplay: true,
		navSpeed: 700,
		navText: ['', ''],
		responsive: {
		  0: {
			items: 1
		  },
		  480: {
			items: 1
		  },
		  1400: {
			items: 1
		  }
		},
	  }	
	  selectInfantAssociate(parent,currentIndex){
		let arr = []
		this.selectedInfantAssociate.forEach(value=> {
		  arr.push(value)
		})
		if(arr.indexOf(currentIndex) > -1){
		  this.selectedInfantAssociate.delete(arr.indexOf(currentIndex))
		}
		this.selectedInfantAssociate.set(parent,currentIndex)
	  }
}