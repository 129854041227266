<div class="fare-right ">
  <div class="departure">
    <div class="fare-datails-container">

      <div class="timeout-range">
        <div class="card" style="display: flex; align-items: center; padding: 5px;">
          <p>Kindly complete your payment within next to <strong><span style="border-bottom: 1px dashed; padding-bottom: 2px;">5 minutes</span></strong> maintain the activity of this session</p>

           <!-- <div
            (click)="toggleAdultDetails()"
            class="card-header"
            style="display: flex; align-items:center; justify-content: space-between;">
            <p class="text-bold p-0 m-0">Fare details for Adults</p>
            <i
              class="fa"
              [ngClass]="{
                'fa-angle-up': toggleAdult,
                'fa-angle-down': !toggleAdult
              }"
              aria-hidden="true"
            ></i>
          </div>
          <div *ngIf="toggleAdult && toggleFare" class="card-body pl-2 pr-3">
            <div class="custom-table">
              <table class="table">
                <tbody>
                  <tr>
                    <td class="cursor-pointer">Adult (Base Fare) 
                    </td>
                    <td class="">&#36; {{ adultBaseFare }} </td>
                  </tr>
                  <tr>
                    <td class="cursor-pointer">Tax </td>
                    <td >&#36; {{ adultTaxFare }} </td>
                  </tr>
                  <tr>
                    <td class="cursor-pointer">Other Charges </td>
                    <td class="">&#36; 0</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td class="text-bold" style="color: #002144;font-size: 20px;font-weight: 600;">TOTAL</td>
                    <td class="text-bold" style="color: #002144;font-size: 20px;font-weight: 600;">
                      &#36; {{ adultBaseFare + adultTaxFare }}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>-->
        </div> 
      </div>
        

          <!-- Child Fare Detials  -->
          <div class="card" *ngIf="noOfChild > 0">
            <div
              (click)="toggleChildDetails()"
              class="card-header d-flex align-items-center justify-content-between"
            >
              <p class="text-bold p-0 m-0">Fare details for Child</p>
              <i
                class="fa"
                [ngClass]="{
                  'fa-angle-up': toggleChild,
                  'fa-angle-down': !toggleChild
                }"
                aria-hidden="true"
              ></i>
            </div>
            <div *ngIf="toggleChild && toggleFare" class="card-body pl-2 pr-3">
              <div class="custom-table">
                <table class="table">
                  <tbody>
                    <tr>
                      <td class="cursor-pointer">Child (Base Fare) </td>
                      <td>&#36; {{ childBaseFare }}</td>
                    </tr>
                    <tr>
                      <td class="cursor-pointer">Tax</td>
                      <td>&#36; {{ childTaxFare }}</td>
                    </tr>
                    <tr>
                      <td class="cursor-pointer">Other Charges</td>
                      <td>&#36; 0</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td class="text-bold text-pinkish-red">TOTAL</td>
                      <td class="text-bold text-pinkish-red">
                        &#36; {{ childBaseFare + childTaxFare }}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
          <!-- Infant Fare Detsils  -->
          <div class="card" *ngIf="noOfInfant > 0">
            <div
              (click)="toggleInfantDetails()"
              class="card-header d-flex align-items-center justify-content-between"
            >
              <p class="text-bold p-0 m-0">Fare details for Infant</p>
              <i
                class="fa"
                [ngClass]="{
                  'fa-angle-up': toggleInfant,
                  'fa-angle-down': !toggleInfant
                }"
                aria-hidden="true"
              ></i>
            </div>
            <div *ngIf="toggleInfant && toggleFare" class="card-body pl-2 pr-3">
              <div class="custom-table">
                <table class="table">
                  <tbody>
                    <tr>
                      <td class="cursor-pointer">Infant (Base Fare)</td>
                      <td class="">&#36; {{ infBaseFare }}</td>
                    </tr>
                    <tr>
                      <td class="cursor-pointer">Tax</td>
                      <td class="">&#36; {{ infTaxFare }}</td>
                    </tr>
                    <tr>
                      <td class="cursor-pointer">Other Charges</td>
                      <td class="">&#36; 0</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td class="text-bold text-pinkish-red">TOTAL</td>
                      <td class="text-bold text-pinkish-red">
                        &#36; {{ infBaseFare + infTaxFare }}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
          <!-- total fare -->
          <div class="card">
            <div
              (click)="toggleTotalFareDetails()"
              class="card-header d-flex align-items-center justify-content-between"
            >
              <p class="text-bold p-0 m-0">Price Details (USD)</p>
            
              <!-- <i
              (click)="toggleFare = !toggleFare"
                class="fa"
                [ngClass]="
                toggleTotalFare ? 'fa-eye' : 'fa-eye-slash'"
                aria-hidden="true"
              ></i> -->
            
            </div>
            <div *ngIf="toggleTotalFare && toggleFare" class="card-body pl-2 pr-3">
              <div class="custom-table">
                <table class="table">
                  <tbody>
                    <tr>
                      <td class="cursor-pointer">Adult x {{ noOfAdult }} </td>
                      <td class="">
                         {{ ((adultBaseFare + adultTaxFare) * noOfAdult)  | currency: "USD":"symbol-narrow" }}
                      </td>
                    </tr>
      
                    <tr *ngIf="noOfChild > 0">
                      <td class="cursor-pointer">Child x {{ noOfChild }} </td>
                      <td class="">
                         {{ ((childBaseFare + childTaxFare) * noOfChild) | currency: "USD":"symbol-narrow" }}
                      </td>
                    </tr>
      
                    <tr *ngIf="noOfInfant > 0">
                      <td class="cursor-pointer">Infant x {{ noOfInfant }} </td>
                      <td class="">
                        {{ ((infBaseFare + infTaxFare) * noOfInfant) | currency: "USD":"symbol-narrow" }}
                      </td>
                    </tr>
      
                    <tr>
                      <td class="cursor-pointer">Baggage Charges </td>
                      <td class=""> {{ baggageCharges | currency: "USD":"symbol-narrow" }}</td>
                    </tr>
                    <tr>
                      <td class="cursor-pointer">Addnl. Baggage Charges </td>
                      <td class=""> {{ additionalBaggageCharges | currency: "USD":"symbol-narrow" }}</td>
                    </tr>
                    <tr>
                      <td class="cursor-pointer">Anicillary Charges </td>
                      <td class=""> {{ anicillaryCharges | currency: "USD":"symbol-narrow" }}</td>
                    </tr>
                    <tr>
                      <td class="cursor-pointer">Meal Charges </td>
                      <td class=""> {{ mealCharges | currency: "USD":"symbol-narrow" }}</td>
                    </tr>
                    <tr>
                      <td class="cursor-pointer">Seat Charges </td>
                      <td class=""> {{ seatCharges | currency: "USD":"symbol-narrow" }}</td>
                    </tr>
                    <tr *ngIf="insuranceAmountPerPerson && insuranceAmountPerPerson > 0">
                      <td class="cursor-pointer">Insurance Premium<small>({{noOfAdult + noOfChild + noOfInfant}} X &#36;{{insuranceAmountPerPerson}})</small> </td>
                      <td class=""> {{totalInsuranceAmount | currency: "USD":"symbol-narrow"}} </td>
                    </tr>
                    <tr>
                      <td class="">Service Charge</td>
                      <td class=""> {{totalServiceFee | currency: "USD":"symbol-narrow"}}</td>
                    </tr>
                    <!-- <tr>
                      <td class="">Total GST</td>
                      <td class="">&#36; 0</td>
                    </tr> -->
                  </tbody>
                  <!-- <tfoot class="double-top-btm-line">
                    <tr>
                      <td class="text-bold  text-uppercase" style="color: #002144;font-size: 20px;font-weight: 600;">
                        Total Pub. Fare
                      </td>
                      <td class="text-bold" style="color: #002144;font-size: 20px;font-weight: 600;">
                        &#36; {{ netTotalayable+ (totalInsuranceAmount ? totalInsuranceAmount : 0)}}
                      </td>
                    </tr>
                  </tfoot> -->
                </table>
              </div>
            </div>
          </div>

          <!--if Offer -->
            <!-- <div class="card"> -->
              <!-- <div class="card-header d-flex align-items-center justify-content-between">
                <p class="text-bold p-0 m-0" style="display: contents;">
                  
                  Total Price (USD)
                  <i class="fas fa-info-circle" title="Information"></i>   
                  <span style="margin-left: 154px;">USD {{ netTotalayable+ (totalInsuranceAmount ? totalInsuranceAmount : 0)}}</span></p>
              </div>
            </div> -->

            <!-- <div class="card active p-0 mt-2 traveller-grand-total"> -->
              <div class="row m-0 grand-total-card">

                <div class=" text-center pl-0">
                  <h4 class="text-bold text-capitalize">Total Price (USD)  </h4>
                </div>

                <div class=" price-col d-block">
                  <h3 class="offered-price d-flex align-items-center">
                    <i class="fas fa-tags mr-3"></i>
                    <span>
                      <span *ngIf="promoCodeApplied"
                      style="display: block; color: red; text-decoration: line-through; font-weight: 600; font-size: 14px; text-align: center;">
                       {{ (netTotalayable + (totalInsuranceAmount ? totalInsuranceAmount : 0)) | currency: "USD":"symbol-narrow" }}
                    </span>
                      <span style="display: block; color: green; font-weight: 700;">
                           {{ ((netTotalayable + (totalInsuranceAmount ? totalInsuranceAmount : 0)) - discountAmount) | currency: "USD":"symbol-narrow" }}
                      </span>
                    </span>
                  </h3>
                </div>

              </div>
            <!-- </div> -->

          <div class="card" *ngIf="isOffer">
            <div
              class="card-header d-flex align-items-center justify-content-between"
            >
              <p class="text-bold p-0 m-0">Total Price Details</p>
              <i class="fa fa-angle-up" aria-hidden="true"></i>
            </div>
            <div *ngIf="toggleFare" class="card-body">
              <div class="custom-table">
                <table class="table">
                  <tbody>
                    <tr>
                      <td class="cursor-pointer">
                        <span class="float-left">Comm. Earned</span
                        ><span class="float-right font-weight-bold">(-)</span>
                      </td>
                      <td>
                        {{ netCommission | currency: "USD":"symbol-narrow" }}
                      </td>
                    </tr>
                    <tr>
                      <td class="cursor-pointer">
                        <span class="float-left">Service Fee</span
                        ><span class="float-right font-weight-bold">(-)</span>
                      </td>
                      <td>
                        {{
                          totalServiceCharge
                            ? totalServiceCharge
                            : (0 | currency: "USD":"symbol-narrow")
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td class="cursor-pointer">
                        <span class="float-left">TDS</span
                        ><span class="float-right font-weight-bold">(+)</span>
                      </td>
                      <td>{{ tds | currency: "USD":"symbol-narrow" }}</td>
                    </tr>
                    <tr>
                      <td class="cursor-pointer">
                        <span class="float-left">GST</span
                        ><span class="float-right font-weight-bold">(+)</span>
                      </td>
                      <td>
                        {{
                          totalGst ? totalGst : (0 | currency: "USD":"symbol-narrow")
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td class="cursor-pointer">
                        <span class="float-left">My Markup</span
                        ><span class="float-right font-weight-bold">(-)</span>
                      </td>
                      <td>
                        {{ affiliateMarkup | currency: "USD":"symbol-narrow" }}
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td class="text-bold text-pinkish-red text-uppercase">
                        Net Payable
                      </td>
                      <td class="text-bold text-pinkish-red">
                         {{ netPayable | currency: "USD":"symbol-narrow" }}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
          <!--if Reissue -->
          <div class="card" *ngIf="isReissue">
            <div
              (click)="toggleReIssueDetails()"
              class="card-header d-flex align-items-center justify-content-between"
            >
              <p class="text-bold p-0 m-0">Re - Issuance</p>
              <i
                class="fa"
                [ngClass]="{
                  'fa-angle-up': toggleReIssue,
                  'fa-angle-down': !toggleReIssue
                }"
                aria-hidden="true"
              ></i>
            </div>
            <div *ngIf="toggleReIssue" class="card-body pl-2 pr-3">
              <div class="custom-table">
                <table class="table mb-0">
                  <tbody>
                    <tr>
                      <td class="">Old Booking Amount</td>
                      <td class="">
                        <span  *ngIf="reIssueData.oldBookingAmount"> {{ reIssueData.oldBookingAmount | number : '0.0-0' }}</span>
                        <div class="shimmer" *ngIf="!reIssueData.oldBookingAmount">
                          <div class="wrapper">
                            <div class="stroke animate title m-0"></div>
                            </div>
                          </div>
                      </td>
                    </tr>
      
                    <tr>
                      <td class="">Current Booking Amount</td>
                      <td class="">
                        <span  *ngIf="reIssueData.currentNetPayable"> {{ reIssueData.currentNetPayable | number : '0.0-0'}}</span>
                       

                      </td>
                    </tr>
                    <tr>
                      <td class="tg-0pky">Fare Difference</td>
                      <td class="tg-0pky">
                        {{ reIssueData?.fareDifference }}
                      </td>
                    </tr>
      
                    <tr>
                      <td class="">Reissuance Charges</td>
                      <td class="">
                        <span> {{ reIssueData.cancellationCharges?reIssueData.cancellationCharges:0 }}</span>
                        <div class="shimmer" *ngIf="!reIssueData.hasOwnProperty('cancellationCharges')">
                          <div class="wrapper">
                            <div class="stroke animate title m-0"></div>
                            </div>
                          </div>
                      </td>
                    </tr>
                    <tr *ngIf="reIssueData && reIssueData.hasOwnProperty('reIssuanceChargeAdmin')">
                      <td class="">Service Charges</td>
                      <td class="">
                        <span> {{ reIssueData.reIssuanceChargeAdmin?reIssueData.reIssuanceChargeAdmin:0 }}</span>
                        <div class="shimmer" *ngIf="!reIssueData.hasOwnProperty('reIssuanceChargeAdmin')">
                          <div class="wrapper">
                            <div class="stroke animate title m-0"></div>
                            </div>
                          </div>
                      </td>
                    </tr>
                    <tr *ngIf="reIssueData && reIssueData.hasOwnProperty('reIssuanceChargeAdminGST')">
                      <td class="">GST on Service Charges</td>
                      <td class="">
                        <span> {{ reIssueData.reIssuanceChargeAdminGST?reIssueData.reIssuanceChargeAdminGST:0 }}</span>
                        <div class="shimmer" *ngIf="!reIssueData.hasOwnProperty('reIssuanceChargeAdminGST')">
                          <div class="wrapper">
                            <div class="stroke animate title m-0"></div>
                            </div>
                          </div>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td class="text-bold text-pinkish-red text-uppercase">
                        Net Payable
                      </td>
                      <td class="text-bold text-pinkish-red">
                      
                        <span *ngIf="reIssueData.hasOwnProperty('currentNetPayable') && reIssueData.hasOwnProperty('cancellationCharges') &&  reIssueData.hasOwnProperty('oldBookingAmount')">&#36; {{
                          reIssueData.currentNetPayable +
                          (reIssueData.reIssuanceChargeAdmin?reIssueData.reIssuanceChargeAdmin:0) +
                          (reIssueData.reIssuanceChargeAdminGST?reIssueData.reIssuanceChargeAdminGST:0) +
                            reIssueData.cancellationCharges -
                            reIssueData.oldBookingAmount
                            | number : '0.0-0'
                        }}</span>
                        <div class="shimmer" *ngIf="!(reIssueData.hasOwnProperty('currentNetPayable') && reIssueData.hasOwnProperty('cancellationCharges') &&  reIssueData.hasOwnProperty('oldBookingAmount'))">
                          <div class="wrapper">
                            <div class="stroke animate title m-0"></div>
                            </div>
                          </div>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
          <div class="info-details">
              <div class="card">
                <p>All prices are listed in <strong style="text-transform: capitalize;display: inline-block;">united states Dollars</strong>, Additional
                  <a href="javascript:void(0)" style="text-decoration: underline;">baggages fees</a> may apply depending on the airline. Your credit/debit
                  card might be processed in several transaction that add up to the
                  total final cost. The promo code applies a disount to our service fees.
                </p>
            </div>
          </div>
         

        <!-- <div class="promo-wrapper card p-2 d-flex "> -->
          <div class="promo-wrapper card p-2 d-flex align-items-center" style="flex-direction: row!important; margin-top: 10px;">
            <mat-form-field class="example-full-width w-100 flex-grow-1">
              <input matInput placeholder="Promo Code" [(ngModel)]="promoCodeInput"
                (ngModelChange)="onPromoCodeInputChange($event)" autocomplete="off" class="capitalize-input">
            </mat-form-field>
          
            <button mat-button class="promobtn" (click)="applyPromoCode()" [disabled]="promoCodeApplied">
              {{ promoCodeApplied ? 'Applied' : 'Apply' }}
            </button>
  
            <button *ngIf="promoCodeApplied" mat-icon-button (click)="unapplyPromoCode()">
              <mat-icon>clear</mat-icon> <!-- Cross icon to unapply the promo code -->
            </button>

          </div>

          <div style="margin-top: -12px; margin-left: 12px; color: crimson;">{{errorMessage}}</div>
        <!-- </div> -->
        
        <!-- <div *ngFor="let promo of promoCodes" class="promo-item card"
        [ngClass]="{'dimmed': appliedPromoCode && appliedPromoCode !== promo.promo_code}" 
          style=" margin-top: 10px;">
          
          <div class="promo-details">
            <h3 style="float: none!important;padding: 10px 10px 5px 15px;margin-bottom: 0;">{{ promo.promo_code }}</h3>
              <div style="display: flex;padding: 0px 10px 0px 15px;">
                <div style="position: relative;flex: 0 0 64%;">
                  <select disabled style="margin-bottom: 5px!important;">
                    <option>
                      Discount: {{ promo.discount_type === 'percentage' ? promo.discount_value + '%' : '$' + promo.discount_value }}
                    </option>
                  </select>
                  <button class="closing-btn"  mat-button *ngIf="appliedPromoCode === promo.promo_code" (click)="applyPromoCode(promo.promo_code)">
                    ✖
                  </button>
                </div>
                <div>
                  <button mat-button class="promobtn" (click)="applyPromoCode(promo.promo_code)"
                  [disabled]="appliedPromoCode && appliedPromoCode !== promo.promo_code">
                  {{ appliedPromoCode === promo.promo_code ? 'Applied' : 'Apply' }}
                </button>
                </div>
                
                
              </div>
            <p style="display: flex;padding: 0px 15px 0px 15px;">Valid Until: {{ promo.valid_until | date }}</p>
          </div>
     
        </div> -->
      
    <!-- </div>     -->
  </div>
</div>
