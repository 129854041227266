import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
// import 'rxjs/add/observable/throw';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { CookiesService } from './cookie.service';

@Injectable({
	providedIn: 'root'
})
export class HttpInterceptorService {

	constructor( 
		private router: Router, private cookiesService: CookiesService) {
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		var access_token = "";
		if (localStorage.getItem('access_token') == null) {
			if(window.location.hash.includes('template/print-ticket;searchId') || window.location.hash.includes('signup')) {

			}
			// else{
			// 	this.router.navigate(['login']);
			// }
		}
		else {
			access_token = localStorage.getItem('access_token');
		}
		let deviceId = this.cookiesService.getCookie('deviceId');
		
		var authReq = req.clone({ headers: req.headers.set('Content-Type', 'application/json').append('Authorization', access_token) });
		let searchId =  localStorage.getItem('flightSearchId') ?  localStorage.getItem('flightSearchId') : "";
		let bookingRef = sessionStorage.getItem('X-CURRENT-BOOKING-REFERENCE') ?  sessionStorage.getItem('X-CURRENT-BOOKING-REFERENCE') : "";
		let pnr = sessionStorage.getItem('X-CURRENT-PNR') ?  sessionStorage.getItem('X-CURRENT-PNR') : "";
		let invoiceNumber = sessionStorage.getItem('X-CURRENT-INVOICE') ?  sessionStorage.getItem('X-CURRENT-INVOICE') : "";
			let user = localStorage.authUserDetails && JSON.parse(localStorage.authUserDetails);
			authReq = req.clone({ headers: req.headers.set('Content-Type', 'application/json')
			.append('X-AFFILIATE-ID', !user ? 'Guest' : user.email ? user.email : user.id)
			.append('X-AFFILIATE-NAME', !user ? 'Guest' : user.firstName ? `${user.firstName} ${user.lastName ? user.lastName : ''}` : 'Guest')
			.append('X-XL-CODE', "Guest")
			.append('X-CLIENT-KEY', !user ? '' : user.accountKey ? user.accountKey : "")
			.append('X-SEARCH-ID', searchId)
			.append('X-DEVICE-ID', deviceId)
			.append('X-BOOKING-REFERENCE', bookingRef)
			.append('X-PNR', pnr)
			.append('X-INVOICE-NO', invoiceNumber)
			.append('Authorization', access_token) }); 
			if (user && user.emulated_by) {
			authReq = req.clone({ headers: req.headers.set('Content-Type', 'application/json')
			.append('X-AFFILIATE-ID', user.email ? user.email : user.id)
			.append('X-AFFILIATE-NAME', user.firstName ? `${user.firstName} ${user.lastName ? user.lastName : ''}` : 'Guest')
			.append('X-XL-CODE', "CO000N" )
			.append('X-CLIENT-KEY', user.accountKey ? user.accountKey : "")
			.append('X-SEARCH-ID', searchId)
			.append('X-BOOKING-REFERENCE', bookingRef)
			.append('X-PNR', pnr)
			.append('X-INVOICE-NO', invoiceNumber)
			.append('X-EMULATED-CODE', user.emulated_by)
			.append('Authorization', access_token) }); 
			}
		// }
		// else{
		// 	authReq = req.clone({ headers: req.headers.set('Content-Type', 'application/json')					
		// 	.append('Authorization', access_token).append('X-DEVICE-ID', deviceId) });
		// }
		
		
		return next.handle(authReq)
	}

	randomString(length, chars) {
		var result = '';
		for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
		return result;
	}
}
