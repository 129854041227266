<!-- <div class="container-fluid header-height">
	<div class="row section-header">
		<app-menu></app-menu>
	</div>
</div> -->
<div class="container-fluid mt-2">
	<div class="inner-container row m-0">
		<div class="left-container float-left col-lg-8 pl-0">
			<app-detail
			[totalNetPayable]="totalNetPayable"
			[totalServiceCharges]="totalServiceCharges"
			[totalAffiliateMarkup]="totalAffiliateMarkup"
			[invalidCredit]="invalidCredit"
			[bookingArr]="bookingArr"
			[bookingHistoryArr]="bookingHistoryArr"
			[bookedWithAffiliateCreds]="bookedWithAffiliateCreds"
			[affiliateDetails]="affiliateDetails"
			[isLCC]="isLCC"
			(calSSREvent)="calculateAddOnPrice($event)"
			(resetData)="getData()"
			[bookingActivity]="bookingHistoryArr"
			[totalAffilateAdonMarkup] = "totalAffilateAdonMarkup"
			></app-detail>
		</div>
		<div id="sidebar" class="right-container float-right col-lg-4">
			<app-pricing
			[bookingArr]="bookingArr"
			[travellerFareArray]="travellerFareArray"
			[totalNetPayable]="totalNetPayable"
			[totalServiceCharges]="totalServiceCharges"
			[totalGstOnServiceCharges]="totalGstOnServiceCharges"
			[totalAffiliateMarkup]="totalAffiliateMarkup"
			[totalAffilateAdonMarkup] = "totalAffilateAdonMarkup"
			[totalCommission]="totalCommission"
			[totalTds]="totalTds"
			[totalPublishedFare]="totalPublishedFare"
			[resIssuanceCharges]="resIssuanceCharges"
			(updatedBookingArr) = "updateBookingArr($event)"
			[totalNetPayableRoundOff] = "totalNetPayableRoundOff"
			[resIssuanceFareDiffernceCharges] = "resIssuanceFareDiffernceCharges"

			[totalAirlineCancellationCharges]="totalAirlineCancellationCharges"
			[totalMyCancellationCharges]="totalMyCancellationCharges"
			[totalGstOncancellationCharges]="totalGstOncancellationCharges"
			[totalRefundAmount]="totalRefundAmount"
			[totalTravelledFare]="totalTravelledFare"
			[totalRefundedCommission]="totalRefundedCommission"
			[totalRefundedTds]="totalRefundedTds"
			[mealCharges]="mealCharges"
			[seatCharges]="seatCharges"
			[baggageCharges]="baggageCharges"
			[additionalBaggageCharges]="additionalBaggageCharges"
			[ancillaryCharges]="ancillaryCharges"
			[total_insured_person_amount_sum] = "total_insured_person_amount_sum"
			></app-pricing>

			<app-addon-pricing  *ngIf="addOnsSelected"
			[seatAmount] = "seatAmount"
			[mealAmount] = "mealAmount"
			[baggageAmount] = "baggageAmount"
			[additionalBaggageAmount] = "additionalBaggageAmount"
			[ancillaryAmount] = "ancillaryAmount"
			[totalUpdatedAmount] = "totalUpdatedAmount"
			[totalAddOnPrice] = "totalAddOnPrice"
			></app-addon-pricing>

		</div>
	</div>
</div>