<header id="header" class="home row mx-0 mb-0" [ngStyle]="{'margin-top': heightAsPerMessage}">
<!-- messages -->
<!-- *ngIf="warningMessage['status'] == true" -->
<ng-container *ngIf="warningMessage && warningMessage['status'] == true" >
    <div class="message-wrapper bg-warning">
        <div
        *ngFor="let messageList of warningMessage['messageList'] let i = index;" 
            class="update-info animated  fadeInTop {{messageList['warningType']}}">
            <div class="container">
               <div class="row">
                <div class="col-11">
                    <b class="d-block">
                        {{messageList?.message}} 
                       <ng-container *ngIf="messageList.detail != undefined">
                        <small class="btn btn-sm btn-dark ml-2" *ngIf="selectedErrorIndex != i" (click)="viewDetails(i)">View Details</small>
                        <small class="btn btn-sm btn-dark ml-2" *ngIf="selectedErrorIndex == i" (click)="viewDetails(undefined)">Hide Details</small>
                       </ng-container>
                    </b>
                 
                    <small *ngIf="selectedErrorIndex == i && messageList.detail != undefined" class="warning-detail-area">
                      
                        {{messageList?.detail}}
                    </small>
                </div>
                <div class="col-1">
                    <i class="fa fa-times cursor-pointer" (click)="hideError(i)" aria-label="Close"></i>
               
                </div>
               </div>
            </div>
        </div>
    </div>
</ng-container>

<!-- header -->
    <div class="container p-0">

       <div class="d-flex align-items-center justify-content-between">

            <div class="logo">
                <a routerLink="">
                    <img src="assets/v4images/icons/cheap-logo.svg" width="250px" alt="" class="mt-2">
                </a>
            </div>

            <ul class="contact-wrapper">
                <li>
                    <div class="support-wrapper">
                        <div class="img-wrapper">
                            <img src="assets/v4images/icons/COA-agent-pic.png" width="30px" height="30px" alt="" />
                        </div>
                        <div>
                            <a href="tel:000-900-050-5540">000-900-050-5540</a>
                            <p>Speak to a travel expert</p>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="" style="display: flex;align-items: center;">
                        <span style="display: flex;align-items: center; ">
                            <img src="assets/v4images/icons/world-ico.png" width="" height="" alt=""/>
                        </span>
                        

                        <select class="currency-select">
                            <option value="0">USD$ / EN</option>
                        </select>
                    </div>
                </li>
                <li>
                    <div class="help-wrapper">
                        <a href="javascript:void(0)">Help</a>
                    </div>
                </li>
                <li>
                    <ul class="action-btns align-content-center d-flex list-unstyled m-0 p-0">
                        <li *ngIf="!authLogin" class="menue-item">
                                <a (click)="showModal()" class="btn btn-green">
                                    Login/Signup
                                </a>                        
                        </li>

                        <li *ngIf="authLogin" class="menue-item  auth-item">
                            <div class="d-flex align-items-center justify-content-between cursor "  (click)="toggleDropdown()">
                                <span class="user-name">{{ authuserInfo?.firstName ? authuserInfo.firstName : '' }} {{ authuserInfo?.lastName ? authuserInfo.lastName : '' }}</span>
                                <i _ngcontent-c12="" class="fas fa-chevron-down ml-2" style="color:#fff"></i>
                            </div>
                            <ul *ngIf="dropdownVisible" class="dropdown-menu position-absolute show">
                                <li>
                                    <a [routerLink]="['/my-account']">
                                        <div class="select-menu-item">
                                            <div class="menu-item-icon">
                                                <img src="assets/v4images/icons/profile.svg" width="38px" height="38px" alt=""/>
                                              
                                            </div>
                                            <div>
                                                <h5>My Profile</h5>
                                                <p>Manage your profile and password</p>
                                            </div>
                                        </div>
                                    </a>
                                </li> 
                                <li>
                                    <a href="/#/bookingsummary">
                                        <div class="select-menu-item">
                                            <div class="menu-item-icon">
                                                <img src="assets/v4images/icons/trips.svg" width="34px" height="34px" alt="" />
                                               
                                            </div>
                                            <div>
                                                <h5>My Trips</h5>
                                                <p>Manage your trips here</p>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a (click)="logout()">
                                        <div class="select-menu-item">
                                            <div class="menu-item-icon">
                                                <img src="assets/v4images/icons/logout.svg" width="38px" height="38px" alt="" />
                                          
                                            </div>
                                            <div>
                                                <h5>Logout</h5>
                                            </div>
                                            
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <!-- <li *ngIf="!login" class="menue-item user-account position-relative">
                           <div (click)="isAccDropDownShow=!isAccDropDownShow"   class="d-flex align-items-center justify-content-between cursor ">
                                <img  src="assets/v3images/icons/user-icon.svg" alt="" width="25px" height="25px" class="user-pic mr-2" />
                                <span class="user-name text-bold" style="font-size: 12px;">hy</span>
                                <img src="assets/v3images/icons/black-angle-down.svg" alt="" width="10px" class="ml-2"  />
                                <a class="btn btn-green">
                                    Login/Signup
                                </a>
                           </div>  
                            <div  [ngClass]="isAccDropDownShow ? 'active' : ''" class="custom-modal-box top-right transition user-account-dropdown">
                                <div class="position-relative">
                                    <img (click)="isAccDropDownShow=!isAccDropDownShow" src="assets/v3images/icons/red-close.svg" alt="" srcset="" class="custom-modal-close-btn position-absolute grayscale-filter no-grayscale-filter-hover cursor">
                                    <div class="user-info">
                                        <div class="text-center  ">
                                            <img  src="assets/v3images/icons/user-icon.svg" alt="" width="40px" height="40px" class="user-pic mb-2" />
                                            <div>
                                                <h4 class="user-name text-bold text-dark-green text-capitalize"><small class="text-bold text-black"><i>Hi,</i></small> {{loggedInUserName}}</h4>
                                                <small  class="comp-name  text-uppercase">{{userInfo.companyName}} <br> <span class="text-bold">({{userInfo.xlAccountCode}})</span></small>
                                                    <small *ngIf="showEmulatedBy" class="text-capitalize text-grey d-block c-name" >Impersonated by :
                                                        {{userInfo.emulated_by}}</small>
                                            </div>
                                       </div>  
                                    </div>
                                    <div class="bal-cards d-flex align-items-center justify-content-between ">
                                        <div class="card">
                                                <small class="text-bold text-grey" *ngIf="creditLimit != undefined">Balance</small>
                                            <h4 class="text-bold" [ngClass]="creditLimit > '0' ? 'text-success' : 'text-danger'" *ngIf="creditLimit != undefined">{{creditLimit?.creditLimit  | currency:"INR":"symbol-narrow":"1.0-0"}}</h4>
                                        </div>
                                    </div>
                                    <ul class="dropdown-menues list-unstyled">
                                        <li class="menue-list"  *ngIf="!showOfferLi" (click)="viewOffer()">
                                            <a ref="javascript:void(0)" class="d-block">
                                                <div class="d-flex align-items-center justify-content-between ">  
                                                    <div class="d-flex align-items-center ">
                                                        <div class="menue-icon mr-2">
                                                            <img  src="assets/v3images/icons/green-offer.svg" alt="" width="20px" height="20px"  />
                                                        </div>
                                                        <span class="text-bold">View Offers</span>
                                                    </div>
                                                    <div class="angle-right-icon">
                                                        <img src="assets/v3images/icons/green-angle-right.svg" alt="" width="15px" class="ml-2"  />
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <li class="menue-list"  *ngIf="showOfferLi" (click)="hideOffer()">
                                            <a ref="javascript:void(0)" class="d-block">
                                            <div class="d-flex align-items-center justify-content-between ">  
                                                <div class="d-flex align-items-center ">
                                                    <div class="menue-icon mr-2">
                                                        <img  src="assets/v3images/icons/green-offer.svg" alt="" width="20px" height="20px"  />
                                                    </div>
                                                    <span class="text-bold text-danger">Hide Offers</span>
                                                </div>
                                                    <div class="angle-right-icon">
                                                        <img src="assets/v3images/icons/green-angle-right.svg" alt="" width="15px" class="ml-2"  />
                                                    </div>
                                            </div>
                                            </a>
                                        </li>
                                        <li class="menue-list" (click)="isAccDropDownShow=!isAccDropDownShow">
                                            <a class="d-block">
                                            <div class="d-flex align-items-center justify-content-between ">  
                                                <div class="d-flex align-items-center ">
                                                    <div class="menue-icon mr-2">
                                                        <img  src="assets/v3images/icons/green-payment.svg" alt="" width="20px" height="20px"  />
                                                    </div>
                                                    <span class="text-bold">Payment</span>
                                                </div>
                                                    <div class="angle-right-icon">
                                                        <img src="assets/v3images/icons/green-angle-right.svg" alt="" width="15px" class="ml-2"  />
                                                    </div>
                                            </div>
                                            </a>
                                        </li>
                                        <li class="menue-list" (click)="isAccDropDownShow=!isAccDropDownShow">
                                            <a  class="d-block">
                                            <div class="d-flex align-items-center justify-content-between ">  
                                                <div class="d-flex align-items-center ">
                                                    <div class="menue-icon mr-2">
                                                        <img  src="assets/v3images/icons/green-ledger.svg" alt="" width="20px" height="20px"  />
                                                    </div>
                                                    <span class="text-bold">Financials</span>
                                                </div>
                                                    <div class="angle-right-icon">
                                                        <img src="assets/v3images/icons/green-angle-right.svg" alt="" width="15px" class="ml-2"  />
                                                    </div>
                                            </div>
                                            </a>
                                        </li>
                                       
                                        <li class="menue-list" (click)="isAccDropDownShow=!isAccDropDownShow">
                                            <a  class="d-block">
                                            <div class="d-flex align-items-center justify-content-between ">  
                                                <div class="d-flex align-items-center ">
                                                    <div class="menue-icon mr-2">
                                                        <img  src="assets/v3images/icons/green-booking.svg" alt="" width="20px" height="20px"  />
                                                    </div>
                                                    <span class="text-bold">Booking Summary</span>
                                                </div>
                                                    <div class="angle-right-icon">
                                                        <img src="assets/v3images/icons/green-angle-right.svg" alt="" width="15px" class="ml-2"  />
                                                    </div>
                                            </div>
                                            </a>
                                        </li>
                              
                                        <li class="menue-list" (click)="isAccDropDownShow=!isAccDropDownShow">
                                            <a class="d-block">
                                            <div class="d-flex align-items-center justify-content-between ">  
                                                <div class="d-flex align-items-center ">
                                                    <div class="menue-icon mr-2">
                                                        <img  src="assets/v3images/icons/green-account.svg" alt="" width="20px" height="20px"  />
                                                    </div>
                                                    <span class="text-bold">My Account</span>
                                                </div>
                                                    <div class="angle-right-icon">
                                                        <img src="assets/v3images/icons/green-angle-right.svg" alt="" width="15px" class="ml-2"  />
                                                    </div>
                                            </div>
                                            </a>
                                        </li>
                                        <li class="menue-list" (click)="isAccDropDownShow=!isAccDropDownShow">
                                            <a class="d-block">
                                            <div class="d-flex align-items-center justify-content-between ">  
                                                <div class="d-flex align-items-center ">
                                                    <div class="menue-icon mr-2">
                                                        <img  src="assets/v3images/icons/green-group-fare.svg" alt="" width="20px" height="20px"  />
                                                    </div>
                                                    <span class="text-bold">Group Fare</span>
                                                </div>
                                                    <div class="angle-right-icon">
                                                        <img src="assets/v3images/icons/green-angle-right.svg" alt="" width="15px" class="ml-2"  />
                                                    </div>
                                            </div>
                                            </a>
                                        </li>
                                        <li class="menue-list" (click)="isAccDropDownShow=!isAccDropDownShow">
                                            <a  class="d-block">
                                            <div class="d-flex align-items-center justify-content-between ">  
                                                <div class="d-flex align-items-center ">
                                                    <div class="menue-icon mr-2">
                                                        <img  src="assets/v3images/icons/green-setting.svg" alt="" width="20px" height="20px"  />
                                                    </div>
                                                    <span class="text-bold">Portal Configration</span>
                                                </div>
                                                    <div class="angle-right-icon">
                                                        <img src="assets/v3images/icons/green-angle-right.svg" alt="" width="15px" class="ml-2"  />
                                                    </div>
                                            </div>
                                            </a>
                                        </li>
            
                                        <li (click)="isAccDropDownShow=!isAccDropDownShow">
                                            <a href="#" class="d-block">
                                            <div class="d-flex align-items-center justify-content-between ">  
                                                <div (click)="logout()" class="d-flex align-items-center ">
                                                    <div class="menue-icon mr-2">
                                                        <img  src="assets/v3images/icons/red-logout.svg" alt="" width="20px" height="20px"  />
                                                    </div>
                                                    <span class="text-bold text-danger">Logout</span>
                                                </div>
                                            </div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>                
                        </li> -->
                    </ul>
                </li>

               
            </ul>

       </div>

    </div>

</header>


<button type="button" data-backdrop="static" class="btn btn-primary" style="display: none;" data-toggle="modal" data-target="#loginModal" #openLoginModal [hidden]="true" > 
    Open modal
</button>

  <!-- start login form -->
  <div class="modal fade loginModalForm" id="loginModal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content new-release position-relative">
        <div class="modal-body">
            <button type="button" class="close"  #closeloginModel data-dismiss="modal" aria-label="Close">×</button>
            <div class="grid">
                <div class="item-one">
                    <div class="login-left-wrapper">
                        <div class="download-app">
                            <div class="mobile-app">
                                <img src="assets/v4images/mobile-app-scan.svg" width="350px" height="" alt="" />
                            </div>
                            <h4 class="single-header">Join Us On the Go!</h4>
                            <p class="single-text">Double your points on all flights, hotel stays, and car rentals. 
                                <a href="javascript:void(0)" target="_blank" title="Get the App" class="a-link">Join Us On the Go! ⇲</a>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="item-two">
                    <div class="login-form-wrapper">
                        <div style="width: 350px;margin: 0 auto;">
                            <div class="form-box">
                                <div class="form-row" style="display:flex;justify-content:center;" >
                                    <div id="facebookButton" style="width: 100%;">
                                        <a href="javascript:" class="login-social-button btn btn-block btn-social" style="margin-bottom: 15px;" (click)="loginWithFacebook('signin')">
                                            <img src="assets/v4images/icons/facebook.svg" width="20" height="20" alt="facebook" style="margin-right: 10px;"/>Sign in with Facebook
                                        </a>
                                    </div>
                                    <div id="googleButtonContainer" class="googleButtonContainer">
                                        <div id="googleButton"></div>
                                    </div>
                                   
                                </div>
                            </div>

                            <div class="or-text">
                                <span>Or</span>
                            </div>
                            <form [formGroup]="loginForm" (ngSubmit)="Login()">

                                <div class="form-input" [ngClass]="{'error-border': loginForm.get('email').invalid && loginForm.get('email').touched}" (mouseover)="emailHovered = true"
                                (mouseleave)="emailHovered = false">
                                    <input type="email" formControlName="email" placeholder="Email Address" (focus)="clearErrorMessage()" [title]="loginForm.get('email').invalid && (loginForm.get('email').touched || emailHovered) ? 'Email is required.' : ''"/>
                                </div>

                                <div class="form-input" [ngClass]="{'error-border': loginForm.get('password').invalid && loginForm.get('password').touched}"  (mouseover)="passwordHovered = true" (mouseleave)="passwordHovered = false">
                                    <input type="password" formControlName="password" placeholder="Password" (focus)="clearErrorMessage()" [title]="loginForm.get('password').invalid && (loginForm.get('password').touched || passwordHovered) ? 'Password is required.' : ''"/>
                                </div>
                                <div style="height: 23px;">
                                    <div *ngIf="errorMessage" class="error-message">{{ errorMessage }}</div>
                                </div>
                               
                            

                                <div class="form-input" style="text-align: left;margin-bottom: 15px;">
                                    <a href="">Forgot Password?</a>
                                </div>

                                <div class="btn-container" style="display: flex;justify-content: center;">
                                    <!-- <button type="submit" class="btn btn-primary" style="width: 100%;margin: 0;">Signin</button> -->
                                    <div class="btn-container" style="display: flex;justify-content: center;">
                                        <button type="submit" class="btn btn-primary" style="width: 100%; margin: 0;" [disabled]="loading">
                                          <ng-container *ngIf="!loading">Signin</ng-container>
                                          <ng-container *ngIf="loading">
                                            Signing in
                                            <div class="spinner-border spinner-border-sm" role="status">
                                              <span class="sr-only">Loading...</span>
                                            </div>
                                          </ng-container>
                                        </button>
                                      </div>
                                </div>
                            </form>

                            <div style="margin-top: 30px;">
                                <p style="text-align: left;margin-bottom: 0;">By signing up, you accept CheapFlightDepot's <a href="">Terms & Conditions</a>  and <a href="">Privacy Policy</a> . You agree to receive promotional emails and allow marketing use of your data.</p>
                            </div>

                            <div style="font-weight: 500;margin-top: 20px;font-size: 12px;">Don't have account?  
                                <a href="javascript:void(0)" type="button" data-toggle="modal" data-target="#signupModal" data-dismiss="modal" #opensignupModal>Sign up</a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end login form -->

  <!-- start signup form -->
  <div class="modal fade loginModalForm" id="signupModal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content new-release position-relative">
        <div class="modal-body">
            <button type="button" class="close" #closeAddonMarkupModel data-dismiss="modal" aria-label="Close">×</button>
            <div class="grid">
                <div class="item-one">
                    <div class="login-left-wrapper">
                        <div class="download-app">
                            <div class="mobile-app">
                                <img src="assets/v4images/mobile-app-scan.svg" width="350px" height="" alt="" />
                            </div>
                            <h4 class="single-header">Join Us On the Go!</h4>
                            <p class="single-text">Double your points on all flights, hotel stays, and car rentals. 
                                <a href="javascript:void(0)" target="_blank" title="Get the App" class="a-link">Join Us On the Go! ⇲</a>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="item-two">
                    <div class="login-form-wrapper">
                        <div style="width: 350px;margin: 0 auto;">
                            <div class="form-box">
                                <div class="form-row">
                                    <div id="facebookButton" style="width: 100%;">
                                        <a href="javascript:" class="" (click)="loginWithFacebook(null)" class="login-social-button btn btn-block btn-social" style="margin-bottom: 15px;">
                                            <img src="assets/v4images/icons/facebook.svg" width="20" height="20" alt="facebook" style="margin-right: 15px;"/> Sign in with Facebook
                                        </a>
                                    </div>
                                    <div class="">
                                        <div id="googleSignupButton"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="or-text">
                                <span>Or</span>
                            </div>
                            <form [formGroup]="signupForm" (ngSubmit)="onSubmit()">
                            
                            
                                <div class="form-input" [ngClass]="{
                                                                'error-border': signupForm.get('email').invalid && (signupForm.get('email').touched || submitted || emailHovered)
                                                            }" (mouseover)="emailHovered = true" (mouseleave)="emailHovered = false">
                                    <input type="email" formControlName="email" placeholder="Email Address" (focus)="clearErrorMessage()"
                                        [title]="signupForm.get('email').invalid && (signupForm.get('email').touched || submitted || emailHovered) ? 'Please enter a valid email address.' : ''" />
                                </div>
                            
                                <div class="form-input" [ngClass]="{
                                                                'error-border': signupForm.get('firstName').invalid && (signupForm.get('firstName').touched || submitted || firstNameHovered)
                                                            }" (mouseover)="firstNameHovered = true" (mouseleave)="firstNameHovered = false">
                                    <input type="text" formControlName="firstName" placeholder="First Name"
                                        [title]="signupForm.get('firstName').invalid && (signupForm.get('firstName').touched || submitted || firstNameHovered) ? 'First name is required.' : ''" />
                                </div>
                            
                                <div class="form-input" [ngClass]="{
                                                                'error-border': signupForm.get('lastName').invalid && (signupForm.get('lastName').touched || submitted || lastNameHovered)
                                                            }" (mouseover)="lastNameHovered = true" (mouseleave)="lastNameHovered = false">
                                    <input type="text" formControlName="lastName" placeholder="Last Name"
                                        [title]="signupForm.get('lastName').invalid && (signupForm.get('lastName').touched || submitted || lastNameHovered) ? 'Last name is required.' : ''" />
                                </div>
                            
                                <div class="form-input" [ngClass]="{
                                                                'error-border': signupForm.get('password').invalid && (signupForm.get('password').touched || submitted || passwordHovered)
                                                            }" (mouseover)="passwordHovered = true" (mouseleave)="passwordHovered = false">
                                    <input type="password" formControlName="password" placeholder="Enter Password"
                                        [title]="signupForm.get('password').invalid && (signupForm.get('password').touched || submitted || passwordHovered) ? 'Password is required (minimum 6 characters).' : ''" />
                                </div>
                            
                                <div class="form-input" [ngClass]="{
                                                                'border-green': signupForm.get('confirmPassword').value && signupForm.get('confirmPassword').valid, 
                                                                'border-red': signupForm.get('confirmPassword').value && signupForm.get('confirmPassword').invalid
                                                              }">
                                    <input type="password" formControlName="confirmPassword" placeholder="Confirm Password" class="confirm-pass" />
                                </div>
                            
                                <div class="btn-container" style="display: flex; justify-content: center;">
                                    <button type="submit" class="btn btn-primary" style="width: 100%;margin: 0;">Signup</button>
                                </div>
                            
                                <div style="margin-top: 30px;">
                                    <p style="text-align: left;margin-bottom: 0;">By signing up, you accept CheapFlightDepot's <a href="">Terms &
                                            Conditions</a> and <a href="">Privacy Policy</a> . You agree to receive promotional emails and allow
                                        marketing use of your data.</p>
                                </div>
                            
                                <div style="font-weight: 500;margin-top: 20px;font-size: 12px;">Don't have account?
                                    <a href="javascript:void(0)" type="button" data-toggle="modal" data-target="#loginModal"
                                        data-dismiss="modal">Sign In</a>
                                </div>
                            
                                <div *ngIf="errorMessageSignup" class="error-message">{{ errorMessageSignup }}</div>
                            
                            
                            </form>
                        </div>
                    </div>
                  
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end signup form -->