import { Component, OnInit, ViewChild, HostListener, ElementRef } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { CommonService } from 'src/app/services/common.service';
import { BookingService } from 'src/app/services/booking/booking.service';
import { Router, NavigationEnd } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker/public_api';
import * as moment from 'moment-timezone';
import { environment } from 'src/environments/environment';
import { TravellerService } from 'src/app/services/traveller/traveller.service';
@Component({
	selector: 'app-booking-summary',
	templateUrl: './booking-summary.component.html',
	styleUrls: ['./booking-summary.component.scss']
})
export class BookingSummaryComponent implements OnInit {
	isMinimized : boolean = false;
	dataArr: any = [];
	dataSource: any = [];
	displayedColumns: any[] = ['bookingRefNo', 'pnr', 'lname', 'bDate', 'tDate', 'route', 'price', 'status', 'action']
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;
	@ViewChild('openModal') openModal: ElementRef;
	active: boolean;
	lastName;
	isRefundable: any;
	applyFilter: boolean;
	filterData: any = [];
	fromDate = "";
	toDate = "";
	lName = "";
	refNumber = "";
	searchForm: FormGroup;
	pageIndex: number = 0;
	affiliateMarkUpPrice: number = 0;
	affiliateAddOnMarkUpPrice: number = 0;
	adminMarkup: number = 0;
	netCommission: number = 0;
	totalFare: number = 0;
	netPayable: number = 0;
	navigationSubscription;
	bookingDate;
	ascSortRef: boolean = true;
	ascBookDateSort: boolean = true;
	ascJourneyDateSort: boolean = true;
	bsConfig: Partial<BsDatepickerConfig>;
	p;
	colorTheme = 'theme-purple'
	innerHeight: string;
	//Filter ids
	@ViewChild('fDate') fDate: ElementRef;
	@ViewChild('tDate') tDate: ElementRef;
	@ViewChild('ref_no') ref_no: ElementRef;
	@ViewChild('l_name') l_name: ElementRef;
	@ViewChild('pnr_no') pnr_no: ElementRef;
	showTimeError: boolean;
	cancellationWarningMessage: string;
	copyFor: any;
	constructor(private fb: FormBuilder,
		private bookingService: BookingService,
		private commonService: CommonService,
		private router: Router,
		private travellerService : TravellerService
		) {
			this.navigationSubscription = this.router.events.subscribe((e: any) => {
				// If it is a NavigationEnd event re-initalise the component
				if (e instanceof NavigationEnd) {
					this.initialiseInvites();
				}
			});
		}
	ngOnInit() {
		this.searchForm = this.fb.group({
			'search_type': [''],
			'toDate': [''],
			'fromDate': [''],
			'travelDate': [''],
			'bookingDate': [''],
			'bookingRefNo': [''],
			'lastName': [''],
			'destination': [''],
			'number': [''],
			'status' : [''],
			'pnr' : [''],
		})
		this.showTimeError = false
		this.dataSource.paginator = this.paginator;
		this.bsConfig = Object.assign({ dateInputFormat: 'YYYY-MM-DD' }, { containerClass: this.colorTheme },{ showWeekNumbers:false })

		this.getBookings()
	}
	initialiseInvites() {
		this.getBookings();
	}
	ngOnDestroy() {
		// avoid memory leaks here by cleaning up after ourselves. If we  
		// don't then we will continue to run our initialiseInvites()   
		// method on every navigationEnd event.
		if (this.navigationSubscription) {
			this.navigationSubscription.unsubscribe();
		}
	}
	@HostListener('window:resize', ['$event'])
	onResize(event) {
		if (this.dataArr.length <= 3) {
			this.innerHeight = 'auto';
		}
		else {
			this.innerHeight = event.target.innerHeight - 150 + 'px';
		}
	}
	// dateFilter(fDate) {
	// 	this.fromDate = fDate;
	// 	this.checkOtherFilter(this.filterData);
	// 	console.log('FromDate', this.fromDate);
	// }

	private openModalDialog() : void{
		this.openModal.nativeElement.click()
	}

	nameFilter(lname) {
		this.lName = lname;
		this.checkOtherFilter(this.filterData);
		console.log('Name', this.lName);
	}
	bookingRefFilter(lname) {
		this.refNumber = lname;
		this.checkOtherFilter(this.filterData);
		console.log('Reference Number', this.refNumber);
	}
	refSort() {
		this.ascSortRef = !this.ascSortRef;
		var testData = this.dataArr;
		this.dataArr = testData.sort((a, b) => {
			if (this.ascSortRef) {
				if (b.json_doc.booking_ref < a.json_doc.booking_ref) {
					return -1;
				}
				if (b.json_doc.booking_ref > a.json_doc.booking_ref) {
					return 1;
				}
				return 0;
			} else {
				if (a.json_doc.booking_ref < b.json_doc.booking_ref) {
					return -1;
				}
				if (a.json_doc.booking_ref > b.json_doc.booking_ref) {
					return 1;
				}
				return 0;
			}
		});
	}

	journeyDateSort() {
		this.ascJourneyDateSort = !this.ascJourneyDateSort;
		var testData = this.dataArr;
		this.dataArr = testData.sort((a, b) => {
			if (this.ascJourneyDateSort) {
				if (b.json_doc.booked_timestamp < a.json_doc.booked_timestamp) { return -1; }
				if (b.json_doc.booked_timestamp > a.json_doc.booked_timestamp) { return 1; }
				return 0;
			}
			else {
				if (a.json_doc.booked_timestamp < b.json_doc.booked_timestamp) { return -1; }
				if (a.json_doc.booked_timestamp > b.json_doc.booked_timestamp) { return 1; }
				return 0;
			}
		});

	}
	bookDateSort() {
		this.ascBookDateSort = !this.ascBookDateSort;
		var testData = this.dataArr;
		this.dataArr = testData.sort((a, b) => {
			if (this.ascBookDateSort) {
				if (b.json_doc.booked_timestamp < a.json_doc.booked_timestamp) { return -1; }
				if (b.json_doc.booked_timestamp > a.json_doc.booked_timestamp) { return 1; }
				return 0;
			}
			else {
				if (a.json_doc.booked_timestamp < b.json_doc.booked_timestamp) { return -1; }
				if (a.json_doc.booked_timestamp > b.json_doc.booked_timestamp) { return 1; }
				return 0;
			}
		});

	}

	getBookings() {
		let affId = JSON.parse(localStorage.getItem('authUserDetails'))['id'];
		this.bookingService.getBookings(`${affId}&include_hotel=true`).subscribe(res => {
			let dataArr = JSON.parse(res['_body']);
			this.dataArr = dataArr.data;
			if (this.dataArr.length <= 3) {
				this.innerHeight = 'auto';
			}
			else {
				this.innerHeight = window.innerHeight - 350 + 'px';
			}
			if (localStorage.getItem('bookingSummaryPage') != undefined) {
				this.pageIndex = Number(localStorage.getItem('bookingSummaryPage'));
			}
		})
	}
	getName(data) {
		if (data.length>0) {
			let fname = data[0].first_name
			let lname = data[0].last_name
			let full_name = `${fname} ${lname}`
			let count = data.length -1
			
			return `${full_name}${count && count > 0 ? ` + ${count}` : ''}`
		}
		else {
			return '';
		}
	}
	getOrigin(data) {
		try {
			let originAirport = data.segments[0].legs[0].origin_airport_code;
			return originAirport
		} catch (error) {
			console.log('Not Segment : ',data.booking_ref)
			return ""
		}
	}
	getDestination(data) {
		try {
			let segLength = data.segments.length
		let lastSegment = data.segments[segLength - 1]
		let legLength = lastSegment.legs.length
		return lastSegment.legs[legLength - 1].destination_airport_code
		} catch (error) {
			return ""
		}
	}
	getTotal(data,pnr_status) {
		this.totalFare = 0
		for (let i = 0; i < data.length; i++) {
			const element = data[i];
			const lThis = this;
			let keys = Object.keys(element.fare.taxes[0]);
			let count = 0;
			Object.values(element.fare.taxes[0]).forEach(function(v) { 
				if (keys[count] != "MARKUP" && keys[count] != "COMMISSION" && keys[count] != "TOTAL" && keys[count] != "TDS" && keys[count] != "NET_PAYABLE" &&  keys[count] != "OTHER_TAX" && keys[count] != "AFFILIATE_MARKUP"  && keys[count] != "ADMIN_SERVICE_CHARGE") {
					lThis.totalFare += Number(v); 
				}
				if(keys[count] == "OTHER_TAX" || keys[count] == "AFFILIATE_MARKUP"  || keys[count] == "ADMIN_SERVICE_CHARGE") {
					lThis.totalFare += Number(v); 
				}
				count++;
			})		
			this.totalFare += Number(element.fare.base) + this.getOT(element.ssr);
		}
		this.netPayable += this.totalFare;
		if(pnr_status == 'REFUNDED'){
			let amount =  this.calculateRefundedAmount(data)
			if(amount){
				return amount.toFixed(2)
			}else{
				return this.totalFare.toFixed(2)
			}
		}
		return this.totalFare.toFixed(2)
	}
	calculateRefundedAmount(data) {
		let refundedAmount = 0
		let lessAmount = 0;
		let addAmount = 0;
		for (const traveller of data) {
			if(traveller.refunded_fare){
				let refundedFareObj = traveller.refunded_fare
				let base = Number(refundedFareObj.base ? refundedFareObj.base : 0);
				let keys = Object.keys(refundedFareObj.taxes[0]);
				let count = 0;
				const lThis = this;
				refundedAmount += refundedFareObj.refunded_total
				Object.values(refundedFareObj.taxes[0]).forEach(function (v) {
					if(keys[count] == "COMMISSION") {
						lessAmount += Number(v);
					}
					if(keys[count] == "TDS") {
						addAmount +=  Number(v);
					}
					count++;
				})
				if(refundedFareObj.hasOwnProperty('cancellation_charges')) {
					let client_xxl_charges = refundedFareObj.cancellation_charges[0].client_xxl_charges ? refundedFareObj.cancellation_charges[0].client_xxl_charges : 0;
					let client_raf_charges = refundedFareObj.cancellation_charges[0].client_raf_charges ? refundedFareObj.cancellation_charges[0].client_raf_charges : 0;
					
					lessAmount += client_raf_charges;
					lessAmount += client_xxl_charges;

				}
				lessAmount += refundedFareObj.gst ?  refundedFareObj.gst : 0;
			}
		}
		 
		return  refundedAmount - lessAmount + addAmount;
	}
	getOT( ssr) {
		let meal = 0;
		let baggage = 0;
		let seat = 0;
		let additionalBaggage = 0;
		let ancillaryCharges = 0;
		if (ssr != null) {
			// element.seats.code.totalFare ? element.seats.code.totalFare : 0
			for (let i = 0; i < ssr.length; i++) {
				const element = ssr[i];
				if(element.meals != null) {
					meal += Number(element.meals.code ? element.meals.code.totalFare : 0);
				}
				if(element.baggages != null) {
					baggage += Number(element.baggages.code ? element.baggages.code.totalFare : 0);
				}
				if(element.seats != null){
					seat += Number(element.seats.code ? element.seats.code.totalFare : 0);
				}
				if (element.additionalBaggages != null) {
					additionalBaggage += Number(element.additionalBaggages.code ? element.additionalBaggages.code.totalFare : 0);
				}
				if(element.ancillaries != null) {
					if(Array.isArray(element.ancillaries.code)){
						for (const ancillary of element.ancillaries.code) {
							ancillaryCharges += Number(ancillary.code ? ancillary.totalFare : 0);
						}
					}else{
						ancillaryCharges += Number(element.ancillaries.code ? element.ancillaries.code.totalFare : 0);
					}
				}
			}
		}
		return  Number(meal) + Number(baggage) + Number(seat) + Number(additionalBaggage) + Number(ancillaryCharges)
	}

	checkOtherFilter(data) {
		this.active = false;

		if (this.fromDate != "") {
			data = data.filter(a => {
				return a.fromDate > this.fromDate;
			})
		}
		if (this.toDate != "") {
			data = data.filter(a => {
				return a.toDate > this.toDate;
			})
		}
		if (this.lName != "") {
			data = data.filter(a => {
				return a.pax_name > this.lName;
			})
		}
		if (this.refNumber != "") {
			data = data.filter(a => {
				console.log(a);
				return a.local_booking_id.toLowerCase().indexOf(this.refNumber.toLowerCase()) > -1;
			})
		}

		this.dataArr = data;
		console.log('filterDate', this.dataArr);

	}
	returnDate(date) {
		let d = new Date(date.split("+")[0]);
		return d.toDateString()
	}
	submit(values) {
		console.log(values)
		let toDate = ""
		let fromDate = ""
		
		if (values.toDate == "") {
			toDate = ""
		}
		else{
			toDate = this.bookingService.gmtDateParserTo(values.toDate)
		}
		if (values.fromDate == "") {
			fromDate = ""
		}else{
			fromDate = this.bookingService.gmtDateParserFrom(values.fromDate)
		}
		let booked_by = JSON.parse(localStorage.user_details).xlAccountCode
		let url = "?include_hotel=true&type="+values.search_type+"&booking_ref="+values.bookingRefNo+"&toDate="+toDate+"&fromDate="+fromDate;
		let url2 = url+"&lastName="+values.lastName+"&status="+values.status+"&pnr="+values.pnr+"&booked_by="+booked_by;
		console.log('Search Value',values)
		// console.log('Search URL is  http://stage.aadeshtrips.com/booking/booking/bookingfilter'+url2)
		this.bookingService.getBookingFilter(url2).subscribe(res => {
			let dataArr = JSON.parse(res['_body']);
			this.dataArr = dataArr.data;
			this.filterData = dataArr.data;
			console.log('Data ARR', this.dataArr);
			this.dataSource = new MatTableDataSource(this.dataArr);
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
		}, err => {

		})
	}

	resetFilter() {
		this.searchForm.reset();
		this.getBookings();
	}

	modify(data) {
		let searchId = data.json_doc.booking_ref;
		this.commonService.changeDetailsSource(data)
		if(data.typhe == 'hotel') {
			let url = environment.hotelUrl + `/#/home/booking-detail;bookingRef=${searchId}`;
			window.open(url, "_blank")
		}else {
			let v3 = window.location.pathname.includes("v3")?"/v3":""
			window.open(v3+"/#/booking-summary-detail;searchId=" + searchId , "_blank")
		}
		
	}
	async cancelReq(data) {
		let isLcc = await this.travellerService.checkLCC(data.json_doc['segments'][0]['legs'][0]['marketing_airline_code']);
		
		if (environment.allowCancellation == true && isLcc) {
			let curr_date_time = await this.commonService.getCurrentDateTime()
			let curr_date = await this.commonService.getCurrentDate()
			let searchId = data.json_doc.booking_ref;
			let inbetween = moment(curr_date_time).isBetween(curr_date + 'T08:00:00', curr_date + 'T20:00:00')
			let brefore4hr = true
			let departureDate = data.json_doc.segments[0].legs[0].origin_timestamp
			let time_diff = this.commonService.timeDuration2(new Date(curr_date_time), new Date(departureDate))
			if (time_diff < 240) {
				brefore4hr = false
			}

			const current_day = moment(curr_date_time).format('dddd')
			console.log(time_diff)
			 
			if (!inbetween || !brefore4hr || current_day == 'Sunday') {
				window.scrollTo(0, 0)
				this.cancellationWarningMessage = ` The cancellation request service is only available on Mon-Sat between  <strong>08:00 AM</strong> to <strong>08:00 PM</strong> and before  <strong>4 hrs</strong> from journey date.`
				this.openModalDialog()
				return false
			}
			else {
				this.commonService.changeDetailsSource(data)
				this.router.navigate(['/cancel-request', { searchId }]);
			}
		}else{
			this.cancellationWarningMessage = `The cancellation is not working , Please contact to back office team.`
			this.openModalDialog()
			return false
		}
		
	}

	pageChanges(event) {
		localStorage.setItem('bookingSummaryPage', event.pageIndex)
	}
	checkIfNull(value) {
		if (value == undefined) {
			return '';
		}
		else {
			return value;
		}
	}
	private focusFromDate(): void {
		this.fDate.nativeElement.focus();
	}
	private focusToDate(): void {
		this.tDate.nativeElement.focus();
	}
	private focusRefNo(): void {
		this.ref_no.nativeElement.focus();
	}
	private focuslastName(): void {
		this.l_name.nativeElement.focus();
	}
	private focusPNR(): void {
		 
		this.pnr_no.nativeElement.focus();
	}
	focusOn(control){
		if (control == 'fromDate') {
			this.focusFromDate()
		}
		if (control == 'toDate') {
			this.focusToDate()
		}
		if (control == 'bookingRefNo') {
			this.focusRefNo()
		}
		if (control == 'lastName') {
			this.focuslastName()
		}
		if (control == 'pnr') {
			this.focusPNR()
		}
	}

	copyMessage(val,copyFor) {
		const selBox = document.createElement('textarea');
		selBox.style.position = 'fixed';
		selBox.style.left = '0';
		selBox.style.top = '0';
		selBox.style.opacity = '0';
		selBox.value = val;
		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();
		document.execCommand('copy');
		document.body.removeChild(selBox);
		this.copyFor = copyFor
		this.showSnackBar()
	}

	showSnackBar() {
		var x = document.getElementById("snackbar");
		x.className = "show";
		setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
	}

}
