import { NgModule } from '@angular/core';
import { CommonModule as CM } from '@angular/common';
import { CommonModule } from '../Common/common.module';
import { BookingSummaryComponent } from './booking-summary/booking-summary.component';
import { routing } from './pages-routing.module';
import { PagesComponent } from './pages.component';
import { SharedModule } from '../shared/shared.module';
import { LedgerComponent } from './ledger/ledger.component';
import { PaymentComponent } from './payment/payment.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { TwowayTravellerComponent } from './twoway-traveller/twoway-traveller.component';
//import { routing } from './dashboard.routing';
import {NgxPaginationModule} from 'ngx-pagination';
import { ErrorPageComponent } from './error-page/error-page.component';
import { BookingSummaryDetailsComponent } from './booking-summary-details/booking-summary-details.component';
import { CountdownModule } from 'ngx-countdown';
import { ReturnBaggageComponent } from './twoway-traveller/return-baggage/return-baggage.component';
import { ReturnSeatMapComponent } from './twoway-traveller/return-seat-map/return-seat-map.component';
import { ReturnMealComponent } from './twoway-traveller/return-meal/return-meal.component';
import { BookingStatusComponent } from './booking-status/booking-status.component';
import { CancelRequestComponent } from './cancel-request/cancel-request.component';
import { SpecialRoundTripTravellerComponent } from './special-round-trip-traveller/special-round-trip-traveller.component';
import { SpecialReturnBaggageComponent } from './special-round-trip-traveller/special-return-baggage/special-return-baggage.component';
import { SpecialReturnMealComponent } from './special-round-trip-traveller/special-return-meal/special-return-meal.component';
import { SpecialReturnSeatMapComponent } from './special-round-trip-traveller/special-return-seat-map/special-return-seat-map.component';
import { BookingSummaryDetailModule } from './booking-summary-detail/booking-summary-detail.module';
import { ledgerPipe } from './ledger/ledger.pipe';
import { TotalTravellerFareComponent } from './total-traveller-fare/total-traveller-fare.component';
import { SupportComponent } from './support/support.component';
import { LccSpecialRoundTripTravellerComponent } from './lcc-special-round-trip-traveller/lcc-special-round-trip-traveller.component';
import { LccSpecialReturnBaggageComponent } from './lcc-special-round-trip-traveller/lcc-special-return-baggage/lcc-special-return-baggage.component';
import { LccSpecialReturnMealComponent } from './lcc-special-round-trip-traveller/lcc-special-return-meal/lcc-special-return-meal.component';
import { LccSpecialReturnSeatMapComponent } from './lcc-special-round-trip-traveller/lcc-special-return-seat-map/lcc-special-return-seat-map.component';
import { StatementComponent } from './statement/statement.component';
import { MultiCityGdsTravellerComponent } from './multi-city-gds-traveller/multi-city-gds-traveller.component';
import { MulticityBaggageComponent } from './multi-city-gds-traveller/multicity-baggage/multicity-baggage.component';
import { MulticityMealComponent } from './multi-city-gds-traveller/multicity-meal/multicity-meal.component';
import { MulticitySeatMapComponent } from './multi-city-gds-traveller/multicity-seat-map/multicity-seat-map.component';
import { MultiCityLccTravellerComponent } from './multi-city-lcc-traveller/multi-city-lcc-traveller.component';
import { MulticityLccBaggageComponent } from './multi-city-lcc-traveller/multicity-lcc-baggage/multicity-lcc-baggage.component';
import { MulticityLccMealComponent } from './multi-city-lcc-traveller/multicity-lcc-meal/multicity-lcc-meal.component';
import { MulticityLccSeatMapComponent } from './multi-city-lcc-traveller/multicity-lcc-seat-map/multicity-lcc-seat-map.component';
import { ReAttemptTicketComponent } from './re-attempt-ticket/re-attempt-ticket.component';
import { ReAttempSeatComponent } from './re-attempt-ticket/re-attemp-seat/re-attemp-seat.component';
import { ReAttempMealComponent } from './re-attempt-ticket/re-attemp-meal/re-attemp-meal.component';
import { ReAttempBaggageComponent } from './re-attempt-ticket/re-attemp-baggage/re-attemp-baggage.component';
import { GroupFareTravellerComponent } from './group-fare-traveller/group-fare-traveller.component';
import { GroupFareComponent } from './group-fare/group-fare.component';
import { TravellerComponent } from './traveller/traveller.component';
import { FinancialsComponent } from './financials/financials.component';

@NgModule({
  imports: [
    CM,
    CommonModule,
    routing,
    SharedModule,NgxPaginationModule,CountdownModule,BookingSummaryDetailModule,
  ],
  declarations: [PagesComponent, BookingSummaryComponent, LedgerComponent, PaymentComponent,
    TwowayTravellerComponent, ErrorPageComponent, BookingSummaryDetailsComponent, ReturnBaggageComponent, ReturnSeatMapComponent, ReturnMealComponent, BookingStatusComponent, CancelRequestComponent, SpecialRoundTripTravellerComponent, SpecialReturnBaggageComponent, SpecialReturnMealComponent, SpecialReturnSeatMapComponent,ledgerPipe, SupportComponent, StatementComponent, MultiCityGdsTravellerComponent, MulticityBaggageComponent, MulticityMealComponent, MulticitySeatMapComponent, MultiCityLccTravellerComponent, MulticityLccBaggageComponent, MulticityLccMealComponent, MulticityLccSeatMapComponent, ReAttemptTicketComponent, ReAttempSeatComponent, ReAttempMealComponent, ReAttempBaggageComponent, GroupFareTravellerComponent, GroupFareComponent,
    TravellerComponent,
    FinancialsComponent,
  ]
})
export class PagesModule { }