<!-- The Modal -->
<div class="modal fade" id="OTPModal" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered">
     


        <div class="modal-content" style="    max-width: 400px;    margin: 0 auto;">
            <div class="modal-header">
                <h4 class="modal-title">OTP Verification</h4>
                <button type="button" class="close" #closeOtpModal data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <form class=" otp-form" [formGroup]="otpForm">
                <div class="modal-body">
                    <div class="alert alert-success text-bold">
                        <p class="m-0 "><span class=" text-black">We have a verification code to your Mobile No:
                            </span>XXXXXX{{userInfo?.mobile | slice: (userInfo?.mobile.length -4)}}</p>
                    </div>
             
                    <div class="form-group  mt-4">
                        <input type="text" formControlName="otp" placeholder="Enter Verification Code" />
                    </div>
                    <span
                        [ngClass]="{'text-danger': respType=='error','text-success': respType=='success'}">{{respMessage}}</span>
                </div>

                <div class="modal-footer">
                    <button type="button" (click)="submit(loginForm.value,true)"
                        class="btn btn-lg btn-white text-dark-pink ml-3" *ngIf="!isOTPVerfing"><i
                            class="fa fa-refresh mr-1"></i> Resend OTP</button>
                    <button type="button" (click)="validateOtp()" class="btn btn-lg btn-green ml-3"
                        *ngIf="!isOTPVerfing">Submit & Verify OTP
                    </button>
                    <button type="button"
                        class="btn btn-lg btn-green w-100 justify-content-center d-flex align-items-center"
                        *ngIf="isOTPVerfing">Verifying OTP <div id="wave">
                            <span class="dot"></span>
                            <span class="dot"></span>
                            <span class="dot"></span>
                        </div>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="position-relative">
    <button type="hidden" #otpModal data-toggle="modal" data-target="#OTPModal"></button>
    <div class="login-card-container position-">
        <section id="filterselection" class="login card position-relative">
            <div>
                <div class="login-container">
                    <div>
                        <form [formGroup]="loginForm" (ngSubmit)="submit(loginForm.value,false)" class="row m-0 p-0">
                            <div class="col-sm-4 ">
                                <span class="textbox email w-100">
                                    <div class="form-group d-flex align-items-center">
                                        <div class="position-relative w-100">
                                            <img src="../../assets/v3images/icons/{{!email.valid && email.touched || emailNotFound ?'red-warning-alert.svg': 'black-email.svg'}}"
                                                class="input-icon" width="20px" alt="" srcset="">

                                            <label for="" class="required">
                                                {{(email.valid || !email.touched) && !emailNotFound ? 'Your Email Id':
                                                ''}}
                                                <span class="text-danger">{{ !email.valid && email.touched ? 'Email
                                                    Required ' : emailNotFound ? 'Email Not Found' : ''}}</span>
                                            </label>
                                            <input
                                                [ngClass]="!email.valid && email.touched || emailNotFound ? 'red-border-bottom' : ''"
                                                class="form-control" placeholder="Email OR Agent Code"
                                                formControlName="email" required (keyup)="removeError()">
                                        </div>
                                    </div>
                                 
                                </span>
                          
                                <span *ngIf="EmulationError" class="errorLoginColor text-danger"> {{EmulationErrorMsg}}
                                </span>
                            </div>
                            <div class="col-sm-4">
                                <span class="textbox password showPasswrdIcon w-100">
                                    <div class="form-group d-flex align-items-center position-relative">
                                        <div class="position-relative w-100">
                                            <img src="../../assets/v3images/icons/{{!password.valid && password.touched  ?'red-warning-alert.svg': 'black-lock.svg'}}"
                                                class="input-icon" width="20px" alt="" srcset="">


                                            <label for="" class="required">
                                                {{(password.valid || !password.touched) && !pwdNotFound ? 'Your
                                                Password': ''}}
                                                <span class="text-danger">{{ !password.valid && password.touched ?
                                                    'Password Required ' : pwdNotFound ? 'Password Not Found' :
                                                    ''}}</span>
                                            </label>
                                            <div class="d-flex align-items-center">

                                                <input
                                                    [ngClass]="!password.valid && password.touched  ? 'red-border-bottom' : ''"
                                                    class=" form-control" placeholder="Password"
                                                    [type]="hide ? 'text' : 'password'" formControlName="password"
                                                    required (keyup)="removeError()">

                                                <img src="../../assets/v3images/icons/{{!hide ? 'black-opened-eye.svg' : 'closed-eye.png'}}"
                                                    class="cursor-pointer" style="border-bottom: 1px solid #7c68d4;
                                                padding-bottom: 6px;" width="20px" alt="" srcset=""
                                                    (click)="hide = !hide"
                                                    [ngClass]="!password.valid && password.touched  ? 'red-border-bottom' : ''">
                                            </div>
                                        </div>
                                    </div>
                                
                                </span>
                              
                            </div>
                            <div class="col-sm-4 action-btns">
                                <button type="submit" [disabled]="!loginForm.valid"
                                    class="submit d-flex align-items-center justify-content-center  w-100 btn btn-green"><span
                                        class="mr-2">Login</span>
                                </button>
                                <a (click)="forgetPassword()"
                                    class="mt-2 text-grey float-right text-dark-green-hover text-right">Forgot
                                    Password?</a>
                            </div>
                        </form>
                    </div>
                 
                </div>
            </div>
        </section>
    </div>
    <section class="whybook">
		<div class="container">
			<div class="section-heading">
					<h2>Why book with Cheap Flight Depot?</h2>
			</div>
			<div class="row">
				<div class="col-md-4">
					<div class="whybook-article">
						<div class="img-wrap">
							<span>
								<img src="assets/v4images/icons/air-world-img.svg" width="" height="" alt=""/>
							</span>
						</div>
						<div class="article-wrap">
							<h3>One place for all your needs</h3>
							<p>From flights, stays, to sights, just count on our complete products and Travel Guides.</p>
						</div>
					</div>
				</div>
				<div class="col-md-4">
					<div class="whybook-article">
						<div class="img-wrap">
							<span>
								<img src="assets/v4images/icons/air-ticket-img.svg" width="" height="" alt=""/>
							</span>
						</div>
						<div class="article-wrap">
							<h3>Flexible booking options</h3>
							<p>Sudden change of plan? No worries! Reschedule or Refund without hassle.</p>
					
						</div>
					</div>
				</div>
				<div class="col-md-4">
					<div class="whybook-article">
						<div class="img-wrap">
							<span>
								<img src="assets/v4images/icons/air-payment-img.svg" width="" height="" alt=""/>
							</span>
						</div>
						<div class="article-wrap">
							<h3>Secure & convenient payment</h3>
							<p>Enjoy many secure ways to pay, in the currency that's most convenient for you.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<section class="tools-section">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-md-4">
					<div class="section-heading">
						<h2>Need help booking? Our agents are Ready!</h2>
						<h3>Call us 24/7 at 000-900-050-5540</h3>
					</div>
				</div>
				<div class="col-md-8">
					<div class="tools-wrapper">
						<div class="scroll-container">
							<div class="scroll-wrapper">
							<span>
								<img src="assets/v4images/icons/american-air-logo.png" alt="American Airlines" width="" height="">
							</span>
							<span>
								<img src="assets/v4images/icons/American-Airlines-logo.png" alt="American Airlines" width="" height="">
							</span>
							<span>
								<img src="assets/v4images/icons/Lufthansa-Logo.png" alt="American Airlines" width="" height="">
							</span>
							<span>
								<img src="assets/v4images/icons/american-air-logo.png" alt="American Airlines" width="" height="">
							</span>
							<span>
								<img src="assets/v4images/icons/American-Airlines-logo.png" alt="American Airlines" width="" height="">
							</span>
							<span>
								<img src="assets/v4images/icons/Lufthansa-Logo.png" alt="American Airlines" width="" height="">
							</span>
							<span>
								<img src="assets/v4images/icons/american-air-logo.png" alt="American Airlines" width="" height="">
							</span>
							<span>
								<img src="assets/v4images/icons/American-Airlines-logo.png" alt="American Airlines" width="" height="">
							</span>
							<span>
								<img src="assets/v4images/icons/Lufthansa-Logo.png" alt="American Airlines" width="" height="">
							</span>
							<span>
								<img src="assets/v4images/icons/american-air-logo.png" alt="American Airlines" width="" height="">
							</span>
							<span>
								<img src="assets/v4images/icons/American-Airlines-logo.png" alt="American Airlines" width="" height="">
							</span>
							<span>
								<img src="assets/v4images/icons/Lufthansa-Logo.png" alt="American Airlines" width="" height="">
							</span>
							<span>
								<img src="assets/v4images/icons/american-air-logo.png" alt="American Airlines" width="" height="">
							</span>
							<span>
								<img src="assets/v4images/icons/American-Airlines-logo.png" alt="American Airlines" width="" height="">
							</span>
							<span>
								<img src="assets/v4images/icons/Lufthansa-Logo.png" alt="American Airlines" width="" height="">
							</span>
						</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<section class="whycheap">
		<div class="container">
			<div class="section-heading">
					<h2>Why Cheap Flight Depot Is Best</h2>
			</div>
			<div class="row">
				<div class="col-md-4">
					<div class="whycheap-article">
						<div class="img-wrap">
							<span>
								<img src="assets/v4images/icons/air-world-img.svg" width="" height="" alt=""/>
							</span>
						</div>
						<div class="article-wrap">
							<h3>One place for all your needs</h3>
							<p>From flights, stays, to sights, just count on our complete products and Travel Guides.</p>
						</div>
					</div>
				</div>
				<div class="col-md-4">
					<div class="whycheap-article">
						<div class="img-wrap">
							<span>
								<img src="assets/v4images/icons/air-ticket-img.svg" width="" height="" alt=""/>
							</span>
						</div>
						<div class="article-wrap">
							<h3>Flexible booking options</h3>
							<p>Sudden change of plan? No worries! Reschedule or Refund without hassle.</p>
					
						</div>
					</div>
				</div>
				<div class="col-md-4">
					<div class="whycheap-article">
						<div class="img-wrap">
							<span>
								<img src="assets/v4images/icons/air-payment-img.svg" width="" height="" alt=""/>
							</span>
						</div>
						<div class="article-wrap">
							<h3>Secure & convenient payment</h3>
							<p>Enjoy many secure ways to pay, in the currency that's most convenient for you.</p>
						</div>
					</div>
				</div>
				<div class="col-md-4">
					<div class="whycheap-article">
						<div class="img-wrap">
							<span>
								<img src="assets/v4images/icons/air-world-img.svg" width="" height="" alt=""/>
							</span>
						</div>
						<div class="article-wrap">
							<h3>One place for all your needs</h3>
							<p>From flights, stays, to sights, just count on our complete products and Travel Guides.</p>
						</div>
					</div>
				</div>
				<div class="col-md-4">
					<div class="whycheap-article">
						<div class="img-wrap">
							<span>
								<img src="assets/v4images/icons/air-ticket-img.svg" width="" height="" alt=""/>
							</span>
						</div>
						<div class="article-wrap">
							<h3>Flexible booking options</h3>
							<p>Sudden change of plan? No worries! Reschedule or Refund without hassle.</p>
					
						</div>
					</div>
				</div>
				<div class="col-md-4">
					<div class="whycheap-article">
						<div class="img-wrap">
							<span>
								<img src="assets/v4images/icons/air-payment-img.svg" width="" height="" alt=""/>
							</span>
						</div>
						<div class="article-wrap">
							<h3>Secure & convenient payment</h3>
							<p>Enjoy many secure ways to pay, in the currency that's most convenient for you.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<section class="promo-section">
		<div class="container">
			<div class="row">
				<div class="col-md-12">
					<div class="promo-wrapper">
						<div class="offer-article">
							<p>Sign up now and enjoy savings 
								of up to <b>$25 off!</b> on our fees for selected 
								regions using a special promo code!</p>
						</div>
						<div>
							<form class="promo-form">
								<span class="input-wrapper">
									<input type="text" placeholder="Enter Email address"/>
								</span>
								<button type="button" class="promo-btn">Get Promo Code!</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<section class="app-section  app-intro position-relative">
		<div class="container position-relative z-index2">
			<div class="row">
				<div class="col-lg-5">
					<div class=" app-screens text-center">
						<img src="assets/v4images/mobile-app.svg" alt="" />
					</div>
				</div>
				<div class="col-lg-7">
					<h2>App Features</h2>
					<div class="row">
						<div class="col-md-6">
							<ul class="list-unstyled app-features ml-0 p-0">
								<li>
									<img src="../../assets/v3images/icons/app-feature1.svg" alt="" srcset="" class="app-icon">
									<span>Lightening Fast Booking</span>
								</li>
								<li>
									<img src="../../assets/v3images/icons/app-feature2.svg" alt="" srcset="" class="app-icon">
									<span>Agent Friendly Interface</span>
								</li>
								<li>
									<img src="../../assets/v3images/icons/app-feature3.svg" alt="" srcset="" class="app-icon">
									<span>Easy To Monitor Your Bookings</span>
								</li>
							</ul>
						</div>
						<div class="col-md-6">
							<ul class="list-unstyled app-features ml-0 p-0">
								<li>
									<img src="../../assets/v3images/icons/app-feature4.svg" alt="" srcset="" class="app-icon">
									<span>Easily Accessible</span>
								</li>
								<li>
									<img src="../../assets/v3images/icons/app-feature5.svg" alt="" srcset="" class="app-icon">
									<span>Easy & secured Payment</span>
								</li>
								<li>
									<img src="../../assets/v3images/icons/app-feature6.svg" alt="" srcset="" class="app-icon">
									<span>Easy To Work</span>
								</li>
							</ul>
						</div>
					</div>        
					<div class=" qr-code-section mt-5  d-flex align-items-center">
						<div  class="qr-box">
							<img src="assets/v3images/qr-code.svg" alt="">
						</div>
						<div>
							<h4 class="text-color text-bold m-0">Enjoy Seamless Booking with our App</h4>
							<p class="m-0">Scan the QR code and install it</p>
							  <small class="text-grey">Booking Travel Services Just Got Easier with the App. <br>Download the Latest Version TODAY
							</small>
						</div>
					</div>        
				</div>
				
			</div> 
		</div>
	</section>
	<section class="section testimonial-section">
		<div class="container">
			<div class="testimonials">
			<div class="row">
				<div class="col-lg-6">
					<div>
					<h3 class="mt-0">What our 
						clients think <span class="d-block">about us? </span>
					</h3>
					</div>
				</div>
				<div class="col-lg-6 pr-0 ">              
					<div id="testimonial-slider" >
						<owl-carousel-o [options]="testimonialSlider">  
							<ng-container *ngFor="let slide1 of testimonialSlides">
							<ng-template carouselSlide >
								<div [id]="slide1.id" class="pt-3 pb-3">
									<div  class="client-card active position-relative mr-4">
										<div class="client-pic">
											<img [src]="slide1.pic" alt="">
										</div>
										<div class="client-says p-4">
											<p class="text-grey">{{slide1.comment}}.</p>
											<h5 class="text-capitalize mt-2">{{slide1.name}}</h5>
											<small class="text-light-grey text-uppercase">{{slide1.designation}}</small>
										</div>
									</div>
								</div>
							</ng-template>
							</ng-container>      
						</owl-carousel-o>
					
					</div>
				</div>
			</div>
			</div>
	</div>
	</section>

</div>
