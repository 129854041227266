import { Injectable } from '@angular/core';
import {jwtDecode} from 'jwt-decode';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from 'src/app/services/authService/auth-service.service';
import { HeaderServiceService } from '../../header-service.service';

declare var google: any;

@Injectable({
  providedIn: 'root',
})
export class GoogleLoginService {
  userProfile: any = {};
  private loginState = new BehaviorSubject<boolean>(false);

  constructor(private authService : ApiService, private headerService: HeaderServiceService) {}

  initializeGoogleLogin() {    
    google.accounts.id.initialize({
      client_id: '637584291579-70p2vgu65tg0mnk9gnki4d38v010vjqm.apps.googleusercontent.com',
      callback: (response: any) => {        
        const decodedToken: any = jwtDecode(response.credential);
        this.bindObjectdata(decodedToken); 

        this.authService.createUser(this.userProfile).subscribe(
          (res) => {
            console.log('Profile sent to backend successfully', res);
            localStorage.setItem('authUserDetails', JSON.stringify(res));
            this.headerService.setAuthUserDetails(res);
            this.loginState.next(true);
          },
          (err) => {
            console.error('Error sending profile to backend', err);
          }
        );
      },
    });

    google.accounts.id.renderButton(document.getElementById('googleButton'), {
      theme: 'outline',  
      size: 'large',  
      width: '350',  
      shape: 'rectangular',  
      logo_alignment: 'center'  
    });
    
    // For the second button
    google.accounts.id.renderButton(document.getElementById('googleSignupButton'), {
      theme: 'outline',  
      size: 'large',     
      width: '350',  
      shape: 'rectangular',  
      logo_alignment: 'center'  
    });
  }


   // Bind Facebook login data to the service
   bindObjectdata(profileResponse) {

    this.userProfile = {
      firstName: profileResponse.given_name,
      email: profileResponse.email,
      photoUrl: profileResponse.picture,
      updatedBy: null,
      updatedOn: null,
      createdBy: null,
      createdOn: null,
      stateId: null,
      t_user_city_id: null,
      t_user_country_id: null,
      tenant_user_type_id: 2,
      tenantId: null,
      groupId: null,
      password: null,
      status: 'ACTIVE',
      code: null,
      phoneNo: null,
      mobileNo: null,
      stateName: null,
      cityName: null,
      countryName: null,
      address1: null,
      address2: null,
      title: null,
      zipCode: null,
      dob: null,
      gender: null,
      lastName: profileResponse.family_name,
      source: 'GOOGLE',
    };
  }

  getLoginState() {
    return this.loginState.asObservable();
  }
}
