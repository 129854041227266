<ng-container *ngIf="bookingArr.length > 0">
   
    <div class="alert alert-warning" role="alert"
        *ngIf="showHoldTTL ">
        Your Booking is on <strong>Hold</strong> and it will be cancelled automatically after 
            <strong>{{bookingArr[0].json_doc.supplierInfo.hold_ttl | formatDate:'shortDate'}}  {{ bookingArr[0].json_doc.supplierInfo.hold_ttl | formatDate:'shortTime' }}</strong>.
    </div>
    <div class="alert alert-danger" role="alert"
        *ngIf="holdTimelineMessage && showHoldTTL" [innerHTML]="holdTimelineMessage">
    </div>
    <div class="alert alert-danger" role="alert"
        *ngIf="showError == true">
        {{errorMessage}}
    </div>
    <a class="ml-2 mb-1 text-grey transition hover-text-grey mt-2" href="javascript:void(0)" routerLink="/bookingsummary"><i class="fas fa-arrow-left mr-2"></i>Back</a>
    <div class="booking-imp-info">
  
        <!-- <div class="address float-left">
            <div class="c-name text-pinkish-red">{{bookingArr[0].json_doc?.contactdetails?.affiliateAddress?.companyName}}</div>
            <div  *ngIf="bookingArr[0].json_doc?.contactdetails?.affiliateAddress?.mobile != undefind" class="phone"><small class="text-bold text-grey mt-3 d-block">Phone No.:</small> <span class="font-weight-bold">{{bookingArr[0].json_doc?.contactdetails?.affiliateAddress?.mobile}}</span></div>
            <small class="text-bold text-grey d-block mt-3">Address:</small>
            <div class="c-address text-uppercase">{{bookingArr[0].json_doc?.contactdetails?.affiliateAddress?.address1}}</div>
            <div class="c-address">{{bookingArr[0].json_doc?.contactdetails?.affiliateAddress?.city}}, {{bookingArr[0].json_doc?.contactdetails?.affiliateAddress?.stateCode}}</div>
        </div> -->

       <div class="card">
        <div class="row m-0  " >
            <div class="col-4 pl-0">
            <div class=" p-2">
             <div class="book-refno"><span>Booking Ref. No. :&nbsp;</span><span class="font-weight-bold float-right">{{bookingArr[0]?.json_doc.booking_ref}} <i title="Copy Booking Ref. No." class="fa fa-copy text-grey  ml-2" (click)="copyMessage(bookingArr[0]?.json_doc.booking_ref,'Booking Ref')"></i></span></div>
 
             <div class="book-refno" *ngIf="bookingArr[0].json_doc.api_info != undefined && bookingArr[0]?.json_doc?.api_info != null"><span>My Ref. No. :&nbsp;</span><span class="font-weight-bold float-right">{{bookingArr[0]?.json_doc?.api_info?.affiliate_booking_info?.booking_ref_no}} <i title="Copy Booking Ref. No." class="fa fa-copy text-grey  ml-2" (click)="copyMessage(bookingArr[0]?.json_doc?.api_info?.affiliate_booking_info?.booking_ref_no,'Booking Ref')"></i></span></div>
 
             <div class="pnr-num" *ngIf="bookingArr[0].json_doc.pnr_status != 'FAILED' && bookingArr[0].json_doc.pnr_status != 'HOLD'"><span>PNR : </span><span class="font-weight-bold float-right">{{bookingArr[0].json_doc.segments[0].pnr}} <i title="Copy PNR" class="fa fa-copy text-grey  ml-2" (click)="copyMessage(bookingArr[0].json_doc.segments[0].pnr,'PNR')"></i></span></div>
 
             <div class="pnr-num" *ngIf="bookingArr[0].json_doc.pnr_status == 'REFUNDED' && bookingArr[0].json_doc.invoice_no"><span>CRN No. : </span><span class="font-weight-bold float-right">IZ{{getYear()}}-{{bookingArr[0].json_doc?.invoice_no}} <i title="Copy CRN No." class="fa fa-copy text-grey  ml-2" (click)="copyMessage('IZ'+getYear()+'-'+bookingArr[0].json_doc?.invoice_no,'CRN No.')"></i></span></div>
 
             <div class="pnr-num" *ngIf="bookingArr[0].json_doc.pnr_status != 'HOLD' && bookingArr[0].json_doc.pnr_status != 'REFUNDED' && bookingArr[0].json_doc.pnr_status != 'FAILED' "><span>Invoice No. : </span><span class="font-weight-bold float-right">IW{{getYear()}}-{{bookingArr[0].json_doc?.invoice_no}} <i title="Copy Invoice No." class="fa fa-copy text-grey  ml-2" (click)="copyMessage('IW'+getYear()+'-'+bookingArr[0].json_doc?.invoice_no,'Invoice No.')"></i></span></div>
            </div>
            </div>
 
          <div class="col-4">
             <div class=" p-2 pl-3 pr-3">
                 <!-- <div class="book-refno"><span>Fare Type :&nbsp;</span><span class="font-weight-bold float-right">{{bookingArr && bookingArr[0].json_doc.displayPriceType?bookingArr[0].json_doc.displayPriceType:bookingArr[0]?.json_doc.fare_type}}</span></div> -->
                 <div class="book-refno"><span>Fare Type :&nbsp;</span><span class="font-weight-bold float-right"><img    src="assets/v3images/icons/purple-student-cap.svg" alt="STUD" *ngIf="bookingArr[0].json_doc.supplierInfo.subPriceType =='STUD'" />
                    <img class="w-25 m-auto" *ngIf="bookingArr[0].json_doc.supplierInfo.subPriceType =='SRCT'"  src="assets/v3images/icons/purple-senior-citizen.svg" alt="SRCT" />{{bookingArr && bookingArr[0].json_doc.displayPriceType?bookingArr[0].json_doc.displayPriceType:bookingArr[0]?.json_doc.fare_type}} </span></div>    
             <div class="pnr-num" *ngIf="bookingArr[0].json_doc.pnr_status != 'HOLD' && bookingArr[0].json_doc.pnr_status != 'REFUNDED' && bookingArr[0].json_doc.pnr_status != 'FAILED' && (bookingArr[0].json_doc.api_info != undefined && bookingArr[0]?.json_doc?.api_info != null)"><span>My Invoice No. : </span><span class="font-weight-bold float-right">{{bookingArr[0].json_doc?.api_info?.affiliate_booking_info?.invoice_no}}</span></div>
             <div class="pnr-status">
                 <span>Status : </span>
                 <span class="font-weight-bold float-right">
                     <ng-container *ngIf="bookingArr[0].json_doc?.pnr_status != 'FAILED'">
                         <span class="text-success">{{(bookingArr[0].json_doc?.pnr_status)}}</span>
                     </ng-container>
                     <ng-container *ngIf="bookingArr[0].json_doc?.pnr_status == 'FAILED'">
                         <ng-container *ngIf="bookingArr[0].json_doc.supplierInfo && bookingArr[0].json_doc.supplierInfo.groupFareInfo == undefined">
                             <span class="text-danger">{{(bookingArr[0].json_doc?.pnr_status)}}</span>
                         </ng-container>
                         <ng-container *ngIf="bookingArr[0].json_doc.supplierInfo && bookingArr[0].json_doc.supplierInfo.groupFareInfo">
                             <span class="text-info">IN-PROGRESS</span>
                         </ng-container>
                     </ng-container>
                 </span>
             </div>
             </div>
          </div>
         <div class="col-4 pr-0">
                <div class=" p-2">
                     <div class="book-date" *ngIf="bookingArr[0].json_doc.booking_hold_date != undefined"><span>Hold Date : &nbsp;</span><span class="font-weight-bold float-right"> {{bookingArr[0].json_doc.booking_hold_date | formatDate:'shortDate'}}</span></div>
                     <div class="book-date" *ngIf="bookingArr[0].json_doc.pnr_status != 'HOLD'"><span>Booked Date : &nbsp;</span><span class="font-weight-bold float-right"> {{bookingArr[0].json_doc.booked_timestamp | formatDate:'shortDate'}}</span></div>
                     <div class="book-date" *ngIf="bookingArr[0].json_doc.pnr_status == 'REFUNDED'"><span>Cancelled Date : &nbsp;</span><span class="font-weight-bold float-right"> {{bookingArr[0].json_doc.cancelled_timestamp | formatDate:'shortDate'}}</span></div>
                     <!-- <div class="travel-date"><span>Ticket Date : </span><span class="font-weight-bold float-right">{{bookingArr[0].json_doc.segments[0].legs[0].origin_timestamp }}</span></div> -->
                     <div class="travel-date"><span>Travel Date : </span><span class="font-weight-bold float-right">{{bookingArr[0].json_doc.segments[0].legs[0].origin_timestamp | formatDate:'shortDate'}}</span></div>
                     </div>
                </div>
         </div>
       </div>
    </div>
    <div class="alert alert-danger" *ngIf="bookingArr[0].json_doc.pnr_status == 'FAILED'">
        <span>Failed to ticket this PNR, No worries experts for our office will help you in getting it done. Please feel free to get connected! </span>
    </div>
    <!-- <div class="booking-details mb-2">
        <div class="head-style">
            <span class="b-head float-left">Booking Details</span>
            <span class="b-link float-right"><i class="fa fa-reply" aria-hidden="true"></i><a class="theme-link" href="javascript:void(0)" routerLink="/bookingsummary">Back to
							Ticket Queue</a></span>
            <div class="clearfix"></div>
        </div>
        <div class="clearfix"></div>
    </div> -->

    <div class="flight-details card mb-2">
        <div class="card-header d-flex justify-content-between align-items-center flex-dir-row">
            <p class="text-bold  m-0  ">Flight Information</p>
            <span class="text-success  " *ngIf="bookingArr[0].json_doc.pnr_status == 'TICKETED'" (click)="printInvoice()">
                <span  class="btn btn-green btn-sm mb-0 invoice-btn" ><a class="text-white" href="javascript:void(0)" >Invoice</a></span>
						<!-- <span><i class="fa fa-eye" aria-hidden="true"></i><a class="text-success" href="javascript:void(0)" > View Invoice</a></span> -->
            </span>

        </div>
            <div class="custom-table">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="text-left">Flight No</th>
                            <th class="text-left">Origin</th>
                            <th class="text-left">Destination</th>
                            <th class="text-left">Departure</th>
                            <th class="text-left">Arrival</th>
                            <th class="text-left">Class</th>
                            <th class="text-right">Status</th>
                        </tr>
                    </thead>
                    <tbody  style="background-color: #ede9ff;">
                        <ng-container *ngFor="let segment of bookingArr[0].json_doc.segments; let s=index">
                            <tr *ngFor="let leg of segment.legs; let l=index" style="border-bottom: 1px solid #fff; border-right:5px solid #fff">
                                <td class="text-left">{{leg.operating_airline_code}} - {{leg.flight_number}}</td>
                                <td class="text-left">{{leg.origin_airport_code}}</td>
                                <td class="text-left">{{leg.destination_airport_code}}</td>
                                <td class="text-left">{{leg.origin_timestamp | formatDate:'shortDate' }} {{ leg.origin_timestamp | formatDate:'shortTime' }}</td>
                                <td class="text-left">{{leg.destination_timestamp | formatDate:'shortDate' }} {{ leg.destination_timestamp | formatDate:'shortTime' }}</td>
                                <td class="text-left">{{leg.cabin_class}}</td>
                                <td class="text-right text-bold">
                                    {{getSectorStatus(s)}}
                                    <!-- <span *ngIf="bookingArr[0].json_doc.pnr_status == 'TICKETED'">CONFIRMED</span>
                                    <span *ngIf="bookingArr[0].json_doc.pnr_status == 'REFUNDED'">CANCELLED</span> -->
                                </td>
                                <!-- FIX ME -->
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
    </div>


        <div class="passenger-section card mb-3 ">
            <div class="card-header d-flex justify-content-between align-items-center flex-dir-row  ">
                <p class="text-bold  m-0">Passenger Details</p>
                <span *ngIf="bookingArr[0].json_doc.pnr_status == 'TICKETED'" (click)="printTicket()" class="btn btn-sm btn-green all-tkt-btn"><a class="text-white" href="javascript:void(0)" >All Tickets</a></span>
            </div>
            <div class="passenger-box card-body pt-3 p-0 clearfix row ml-0 mr-0">
                <div class="passenger  col-lg-6  mb-3" *ngFor="let traveller of bookingArr[0].json_doc.travellers; let i=index;">
                    <div class="card">
                    <div class="pt-2 pl-3 pr-3 pb-2">
                        <span class="sno float-left font-weight-bold">Passenger {{i+1}} • </span>
                        <span class="p-type float-left font-weight-bold">&nbsp;
									<ng-container *ngIf="traveller.type == 'ADT'">(Adult)</ng-container>
									<ng-container *ngIf="traveller.type == 'CHD'">(Child)</ng-container>
									<ng-container *ngIf="traveller.type == 'INF'">(Infant)</ng-container>
								</span>
                        <span class="p-link float-right">
									<i class="fa fa-ticket" aria-hidden="true"  *ngIf="bookingArr[0].json_doc.pnr_status == 'TICKETED' && traveller.status == 'TICKETED'"></i>
									<a class="text-success text-bold" href="javascript:void(0)" (click)="printTicket()"  *ngIf="bookingArr[0].json_doc.pnr_status == 'TICKETED' && traveller.status == 'TICKETED'"><small>View Ticket</small></a>
									<a class="text-danger text-bold" href="javascript:void(0)" *ngIf="bookingArr[0].json_doc.pnr_status == 'TICKETED' && traveller.status == 'CANCELLED'"><small>CANCELLED</small></a>
									<a class="text-danger text-bold" href="javascript:void(0)"   *ngIf="bookingArr[0].json_doc.pnr_status == 'REFUNDED' && traveller.status == 'CANCELLED'"><small>CANCELLED</small></a>
								</span>
                        <div class="clearfix"></div>
                    </div>
                    <div class="card-body p-2 pb-3 pl-3">
                        <div>
                            <span class="text-grey text-bold mr-2">
                                Name:
                            </span>
                            {{traveller.title}} {{traveller.first_name}} {{traveller.last_name}}
                        </div>
                        <div>
                            <span class="text-grey text-bold mr-2">
                                Ticket <i class="fa fa-hashtag ticketnum" aria-hidden="true"></i>
                            </span>
                            {{traveller?.ticket?.number}}
                            <span *ngIf="bookedWithAffiliateCreds && traveller.ticket == null && (bookingArr[0].json_doc.pnr_status == 'FAILED' || bookingArr[0].json_doc.pnr_status == 'HOLD')">
                                <input id="my-input" class="form-control ticketInput" oninput="this.value = this.value.toUpperCase()"  type="text" name="">
                            </span>
                        </div>
                        <ng-container *ngIf="traveller?.ssr != null && traveller?.ssr !=  ''">
                            <div class="ssrInfoTravellerWise" id="traveller_{{i}}">
                                <ng-container *ngFor="let ssr of traveller.ssr">
                                    <hr>
                                      <ng-container *ngIf="ssr.seats != null && ssr.seats != '' && ssr.seats.name && ssr.seats.name != 'NA'">
                                          <div class="d-flex align-items-center">
                                              <span class="text-grey text-bold mr-2">
                                                  Seat:
                                              </span>
                                              ({{ssr.origin}}-{{ssr.destination}}) :  
                                              <span title="{{ssr?.seats?.code?.code}} {{ssr?.seats?.code?.name}} ">
                                                  {{start_and_end(ssr?.seats?.code?.code ? ssr?.seats?.code?.code : ssr?.seats?.code)}}
                                              </span>
                                              <ng-container *ngIf="ssr.seats.status && ssr.seats.status == 'REQUESTED' ;else ssrStatusIcon">
                                                <img class="ml-1" style="width: 11px;" src="assets/v3images/icons/request-sent.png" alt="" title="REQUESTED">
                                              </ng-container>
                                              <ng-template #ssrStatusIcon>
                                                  <i class="fa ml-1 {{(!ssr.seats.status || ssr.seats.status == 'SUCCESS') ? 'fa-check-circle text-success' : 'fa-times-circle text-danger'}}" title="{{!ssr.seats.status || ssr.seats.status == 'SUCCESS' ? 'SUCCESS' : 'FAILED' }}"></i>
                                              </ng-template>
                                          </div>
                                      </ng-container>
                                      <ng-container *ngIf="ssr.meals != null && ssr.meals != '' && ssr.meals.name && ssr.meals.name != 'NA' ">
                                          <div class="d-flex align-items-center">
                                              <span class="text-grey text-bold mr-2">
                                                  Meal: 
                                              </span>
                                              ({{ssr.origin}}-{{ssr.destination}}) :
                                              <span title="{{ssr?.meals?.code?.code}} {{ssr?.meals?.code?.name}}">
                                                  {{start_and_end(ssr?.meals?.code)}}
                                              </span>
                                              <ng-container *ngIf="ssr.meals.status && ssr.meals.status == 'REQUESTED' ;else ssrStatusIcon">
                                                <img class="ml-1" style="width: 11px;" src="assets/v3images/icons/request-sent.png" alt="" title="REQUESTED">
                                              </ng-container>
                                              <ng-template #ssrStatusIcon>
                                                  <i class="fa ml-1 {{(!ssr.meals.status || ssr.meals.status == 'SUCCESS') ? 'fa-check-circle text-success' : 'fa-times-circle text-danger'}}" title="{{!ssr.meals.status || ssr.meals.status == 'SUCCESS' ? 'SUCCESS' : 'FAILED' }}"></i>
                                              </ng-template>
                                          </div>
                                      </ng-container>
                                      <ng-container *ngIf="ssr.baggages != null && ssr.baggages != '' && ssr.baggages.name && ssr.baggages.name != 'NA'">
                                          <div class="d-flex align-items-center">
                                              <span class="text-grey text-bold mr-2">
                                                  Baggage: 
                                              </span>
                                              ({{ssr.origin}}-{{ssr.destination}}) : 
                                              
                                              <span title="{{ssr?.baggages?.code?.code}} {{ssr?.baggages?.code?.name}} ">
                                                  {{start_and_end(ssr?.baggages?.code)}}
                                              </span>
                                              <ng-container *ngIf="ssr.baggages.status && ssr.baggages.status == 'REQUESTED' ;else ssrStatusIcon">
                                                <img class="ml-1" style="width: 11px;" src="assets/v3images/icons/request-sent.png" alt="" title="REQUESTED">
                                              </ng-container>
                                              <ng-template #ssrStatusIcon>
                                                  <i class="fa ml-1 {{(!ssr.baggages.status || ssr.baggages.status == 'SUCCESS') ? 'fa-check-circle text-success' : 'fa-times-circle text-danger'}}" title="{{!ssr.baggages.status || ssr.baggages.status == 'SUCCESS' ? 'SUCCESS' : 'FAILED' }}"></i>
                                              </ng-template>
                                          </div>
                                      </ng-container>
                                      <ng-container *ngIf="ssr.additionalBaggages != null && ssr.additionalBaggages != '' && ssr.additionalBaggages.name && ssr.additionalBaggages.name != 'NA'">
                                          <div class="d-flex align-items-center">
                                              <span class="text-grey text-bold mr-2">
                                              Addnl. Baggage: 
                                              </span>
                                              ({{ssr.origin}}-{{ssr.destination}}) : 
                                              <span title="{{ssr?.additionalBaggages?.code?.code}} {{ssr?.additionalBaggages?.code?.name}}">
                                                  {{start_and_end(ssr?.additionalBaggages?.code)}}
                                              </span>
                                              <ng-container *ngIf="ssr.additionalBaggages.status && ssr.additionalBaggages.status == 'REQUESTED' ;else ssrStatusIcon">
                                                <img class="ml-1" style="width: 11px;" src="assets/v3images/icons/request-sent.png" alt="" title="REQUESTED">
                                              </ng-container>
                                              <ng-template #ssrStatusIcon>
                                                  <i class="fa ml-1 {{(!ssr.additionalBaggages.status || ssr.additionalBaggages.status == 'SUCCESS') ? 'fa-check-circle text-success' : 'fa-times-circle text-danger'}}" title="{{!ssr.additionalBaggages.status || ssr.additionalBaggages.status == 'SUCCESS' ? 'SUCCESS' : 'FAILED' }}"></i>
                                              </ng-template>
                                          </div>
                                      </ng-container>
                                      <ng-container *ngIf="ssr.ancillaries != null && ssr.ancillaries != '' && ssr.ancillaries.name && ssr.ancillaries.name != 'NA'">
                                         <ng-container *ngIf="ssr?.ancillaries.code && isArray(ssr?.ancillaries.code)">
                                          <div class="d-flex align-items-center" *ngFor="let item of ssr?.ancillaries.code">
                                              <span class="text-grey text-bold mr-2">
                                              Ancillary: 
                                              </span>
                                              ({{ssr.origin}}-{{ssr.destination}}) :
                                              <span title="{{item?.code}} {{item?.name}} ">
                                                  {{start_and_end(item)}}
                                              </span>
                                              <ng-container *ngIf="item && item.status && item.status == 'REQUESTED' ;else ssrStatusIcon">
                                                <img class="ml-1" style="width: 11px;" src="assets/v3images/icons/request-sent.png" alt="" title="REQUESTED">
                                              </ng-container>
                                              <ng-template #ssrStatusIcon>
                                                  <i class="fa ml-1 {{(!item?.status || item?.status == 'SUCCESS' || item?.status == 'notselected') ? 'fa-check-circle text-success' : 'fa-times-circle text-danger'}}" title="{{!item?.status || item?.status == 'SUCCESS' || item?.status == 'notselected' ? 'SUCCESS' : 'FAILED' }}"></i>
                                              </ng-template>
                                          </div>
                                         </ng-container>
                                      </ng-container>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>

                    <!-- <div class="p-add pl-3"><span>Address:</span>203, FF, IP Colony, Faridabad</div> -->
                    <!-- FIX ME -->
                </div>
                </div>

            </div>
        </div>
        <div class="passenger-section card mb-3 " *ngIf="bookingArr[0].json_doc?.contactdetails?.email || bookingArr[0].json_doc?.contactdetails?.phone ">
            <div class="card-header d-flex justify-content-between align-items-center flex-dir-row  ">
                <p class="text-bold m-0">Passenger Personal Details</p>
            </div>
            <div class="card-body clearfix ml-0 mr-0 p-0 passenger-box px-4 py-2">
                <div *ngIf="bookingArr[0].json_doc?.contactdetails?.email"> <strong>Email :</strong> {{bookingArr[0].json_doc?.contactdetails?.email}} <i title="Copy Email" class="fa fa-copy text-grey  ml-2" (click)="copyMessage(bookingArr[0].json_doc?.contactdetails?.email,'Email')"></i></div>
                <div *ngIf="bookingArr[0].json_doc?.contactdetails?.phone"> <strong>Mobile No. :</strong> {{bookingArr[0].json_doc?.contactdetails?.phone}} <i title="Copy Mobile No." class="fa fa-copy text-grey  ml-2" (click)="copyMessage(bookingArr[0].json_doc?.contactdetails?.phone,'Mobile No.')"></i></div>
            </div>
        </div>
        
        <!-- passenger section end -->

        <div class="gst-detail card mb-3 mb-2" *ngIf="bookingArr[0].json_doc?.gstDetail?.gstNumber != ''">
            <!-- <div class="gst-detail mt-2 mb-2"> -->
                <div class="card-header d-flex justify-content-between align-items-center flex-dir-row">
                    <p class="text-bold  m-0    ">GST Details</p>
                    </div>
                    <div class="card-body row m-0">
                <span class="col-6 float-left mb-2"><span class="gst-head">GST Number: <span>{{bookingArr[0].json_doc?.gstDetail?.gstNumber}}</span></span>
                </span>
                <span class="col-6 float-left mb-2"><span class="gst-head">GST Company Name: <span>{{bookingArr[0].json_doc?.gstDetail?.companyName}}</span></span>
                </span>
                <span class="col-6 float-left mb-2"><span class="gst-head">GST Company Contact No.: <span>{{bookingArr[0].json_doc?.gstDetail?.phone}}</span></span>
                </span>
                <span class="col-6 float-left mb-2"><span class="gst-head">GST Company Email: <span>{{bookingArr[0].json_doc?.gstDetail?.email}}</span></span>
                </span>
                <div class="clearfix"></div>
            </div>
        </div>

        <div id="traveller-filling-details " class="ssr-info card mb-3 mb-2" *ngIf="bookingArr[0].json_doc.pnr_status == 'TICKETED'">    
            <div class="card-header d-flex justify-content-between align-items-center flex-dir-row">
                <p class="text-bold  m-0   ">SSR Information</p>
                <span (click)="getSSR()"  class="btn-sm btn btn-green mb-0 ssr-btn" >
                    <ng-container *ngIf="!showAddons">
                        <i class="fa fa-plus mr-1" aria-hidden="true"></i>
                        SSR
                    </ng-container>
                    <ng-container *ngIf="showAddons">
                        <i class="fa fa-refresh mr-1" aria-hidden="true"></i>
                        SSR
                    </ng-container>
                </span>



                <!-- <span class="text-success"><i class="fa fa-link" aria-hidden="true"></i><a class="text-success"
                    (click)="getSSR()" href="javascript:void(0)">
                    <ng-container *ngIf="!showAddons">
                        Add SSR
                    </ng-container>
                    <ng-container *ngIf="showAddons">
                        Refresh SSR
                    </ng-container>
                </a></span> -->
                </div>   
                <app-add-on-fare ></app-add-on-fare>
            <div   style="position: relative;" class="traveller-section " *ngIf="showSSRField">
                <div class="add-ons-container">
                    <div class="add-on-list">
                        <ul class=" list-unstyled m-0 p-0" style="overflow-y: scroll;height: calc(100% - 40px);">
                            <li (click)="selectAddonTab('seat-map')" class="add-on-item" [ngClass]="activeAddonTab == 'seat-map' ? 'active' : ''">
                                <i class="fas fa-chair" aria-hidden="true"></i>
                                <span>Seats</span>
                            </li>
                            <li (click)="selectAddonTab('meal')" class=" add-on-item" [ngClass]="activeAddonTab =='meal' ? 'active' : ''">
                                <i class="fa fa-cutlery" aria-hidden="true"></i>
                                <span>Meals</span>
                            </li>
                            <li (click)="selectAddonTab('baggage')" class="add-on-item" [ngClass]="activeAddonTab =='baggage' ? 'active' : ''">
                                <i class="fa fa-suitcase" aria-hidden="true"></i>
                                <span>Baggages</span>
                            </li>
                           
                            <li (click)="selectAddonTab('ancillary')" class="add-on-item" [ngClass]="activeAddonTab == 'ancillary' ? 'active' : ''">
                                <img class="post-booking-add-on-item-icon mr-1" [ngClass]="activeAddonTab == 'ancillary' ? 'active' : ''" src="assets/v3images/icons/ancillary.png" width="23px" /> 
                                <span>Ancillaries</span>
                            </li>
                            <li (click)="selectAddonTab('wheelchair')" class="add-on-item" [ngClass]="activeAddonTab == 'wheelchair' ? 'active' : ''">
                                <img class="post-booking-add-on-item-icon mr-1" [ngClass]="activeAddonTab == 'wheelchair' ? 'active' : ''" src="assets/v3images/icons/wheelchair.png" width="23px" /> 
                                <span>Wheel Chair</span>
                            </li>
                        </ul>
                        <ul class="m-0 p-0 list-unstyled">
                            <li class="text-dark-green cursor-pointer mt-4  border-radius-5 mt-4 pb-2 pt-2 text-center ">See All  <i class="fas fa-angle-right ml-3" aria-hidden="true"></i></li>
                        </ul>
                    </div>
                    <div class="add-on-detail-area">
                        <div *ngIf="showSelectedSeatError" class="alert-danger justify-content-center row">
                            Selection of Seat for all Legs and Passenger is Mandatory.
                        </div>
                        <div *ngIf="bookingArr[0].json_doc.supplierInfo && bookingArr[0].json_doc.supplierInfo.airlineCode!= '2T'">
                            <ng-container>
                               <div [ngClass]="activeAddonTab =='meal' ? 'active-add-on' : 'inactive-add-on'">
                                    <ng-container>
                                        <div *ngIf=" !ssrAvailability || (   ssrAvailability && ssrAvailability.length == 0)">
                                            <div *ngIf="!showSSRError"   class="loading-img-container">
                                                <div class="loading-img">
                                                    <img src="../../../assets/v3images/airoplane-fliping-animation.gif"
                                                alt=""  width="100%" height="100%">
                                                </div>
                                                <div class="animated-loader-text d-flex align-items-center justify-content-center">
                                                    <small >Fetching Meal </small>
                                                <div id="wave">
                                                    <span class="dot"></span>
                                                    <span class="dot"></span>
                                                    <span class="dot"></span>
                                                </div>
                                                </div>
                                            </div>
                                             <div  *ngIf="showSSRError"  class="loading-img-container">
                                                <img src="../../../assets/v3images/error-illustration.svg" width="400px"  alt="" class="ml-auto mr-auto d-block">
                                                <p class="text-danger text-center text-bold">Unable To Fetch Meal</p>
                                             </div>
                                        </div>
                                    </ng-container> 
                                    <app-addon-meal [ssrAvailability]="ssrAvailability" [showRequestLoader]="showRequestLoader" [responseData]="responseData" (callMealEvent)="getMealEvent($event)"></app-addon-meal>
                               </div>
                                <div [ngClass]="activeAddonTab =='baggage' ? 'active-add-on' : 'inactive-add-on'" >
                                    <ng-container>
                                        <div *ngIf=" !ssrAvailability || (   ssrAvailability && ssrAvailability.length == 0)">
                                            <div *ngIf="!showSSRError && ssrAvailability.length == 0 else showBaggageError"   class="loading-img-container">
                                                <div class="loading-img">
                                                    <img src="../../../assets/v3images/airoplane-fliping-animation.gif"
                                                alt=""  width="100%" height="100%">
                                                </div>
                                                <div class="animated-loader-text d-flex align-items-center justify-content-center">
                                                    <small >Fetching Baggage </small>
                                                <div id="wave">
                                                    <span class="dot"></span>
                                                    <span class="dot"></span>
                                                    <span class="dot"></span>
                                                </div>
                                                </div>
                                            </div>
                                            <ng-template #showBaggageError>
                                                <div  class="loading-img-container">
                                                    <img src="../../../assets/v3images/error-illustration.svg" width="400px"  alt="" class="ml-auto mr-auto d-block">
                                                    <p class="text-danger text-center text-bold">Unable To Fetch Baggage</p>
                                                 </div>
                                            </ng-template>
                                           
                                        </div>
                                    </ng-container>
                                    <app-addon-baggage 
                                        [responseData]="responseData" 
                                        [showRequestLoader]="showRequestLoader" 
                                        [ssrAvailability]="ssrAvailability" 
                                        (callBaggageEvent)="getBaggageEvent($event)"
                                        (callAdditionalBaggageEvent)="getAdditionalBaggageEvent($event)"></app-addon-baggage>
                                </div> 
                                <div [ngClass]="activeAddonTab =='ancillary' ? 'active-add-on' : 'inactive-add-on'" >
                                <ng-container>
                                    <div *ngIf=" !ssrAvailability || (   ssrAvailability && ssrAvailability.length == 0)">
                                        <div *ngIf="!showSSRError && ssrAvailability.length == 0 else showBaggageError"   class="loading-img-container">
                                            <div class="loading-img">
                                                <img src="../../../assets/v3images/airoplane-fliping-animation.gif"
                                            alt=""  width="100%" height="100%">
                                            </div>
                                            <div class="animated-loader-text d-flex align-items-center justify-content-center">
                                                <small >Fetching Ancillary </small>
                                            <div id="wave">
                                                <span class="dot"></span>
                                                <span class="dot"></span>
                                                <span class="dot"></span>
                                            </div>
                                            </div>
                                        </div>
                                        <ng-template #showBaggageError>
                                            <div  class="loading-img-container">
                                                <img src="../../../assets/v3images/error-illustration.svg" width="400px"  alt="" class="ml-auto mr-auto d-block">
                                                <p class="text-danger text-center text-bold">Unable To Fetch Ancillary</p>
                                             </div>
                                        </ng-template>
                                       
                                    </div>
                                </ng-container>
                                <app-addon-ancillary [responseData]="responseData" [showRequestLoader]="showRequestLoader" [ssrAvailability]="ssrAvailability" (callAncillaryEvent)="getAncillaryEvent($event)"></app-addon-ancillary>
                                </div> 
                                <div [ngClass]="activeAddonTab =='seat-map' ? 'active-add-on' : 'inactive-add-on'">
                                    <ng-container>
                                        <div   *ngIf="seatMapArr && seatMapArr.length == 0">
                                            <div *ngIf="!showSeatError"   class="loading-img-container">
                                                <div class="loading-img">
                                                    <img src="../../../assets/v3images/airoplane-fliping-animation.gif"
                                                alt=""  width="100%" height="100%">
                                                </div>
                                                <div class="animated-loader-text d-flex align-items-center justify-content-center">
                                                    <small >Fetching Seats Data</small>
                                                <div id="wave">
                                                    <span class="dot"></span>
                                                    <span class="dot"></span>
                                                    <span class="dot"></span>
                                                </div>
                                                </div>
                                            </div>
                                             <div  *ngIf="showSeatError"  class="loading-img-container">
                                                <img src="../../../assets/v3images/error-illustration.svg" width="400px"  alt="" class="ml-auto mr-auto d-block">
                                                <p class="text-danger text-center text-bold">Unable To Fetch Seats Data</p>
                                             </div>
                                        </div>
                                    </ng-container>
                                    <!-- <app-seat-map [seatMapArr]="seatMapArr" [passengerArr]="passengerArr"
                                        (callSeatEvent)="getSeatEvent($event)">
                                    </app-seat-map> -->
                                    <app-addon-seat [responseData]="responseData" [showRequestLoader]="showRequestLoader" [seatMapArr]="seatMapArr" [passengerArr]="passengerArr" (callSeatEvent)="getSeatEvent($event)">
                                    </app-addon-seat>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                   
                </div>

                <!-- <ngx-loading [show]="showRequestLoader" [config]="{
                    backdropBackgroundColour: 'rgba(0,0,0,0.9)',
                    primaryColour: 'white', 
                    secondaryColour: 'orange', 
                    tertiaryColour: 'orange',
                    fullScreenBackdrop: false
                }">
            </ngx-loading> -->
                <!-- <div class="card-body p-2">
                    <div class="loader">
                        <div class="msg-group" *ngIf="showSSRMessage">
                            <span class="statustype">
                                <span class="status-col">
                                    <span class="b-status"><span class="status-type">Booking Status <a class="detail-btn" href="#">View all details</a></span></span>
                                    <span class="close-icon" (click)="closeSSRMessage()" ><i class="fas fa-times"></i></span>
                                </span>
        
                                <span class="info-col">
                                    <ul>
                                        <li *ngIf="mealRequested"><span class="bsuccess" [ngClass]="{'successcolor': mealBooked,'failcolor': !mealBooked}"><i class="fas  mr-2"
                                            [ngClass]="{'fa-check-circle': mealBooked,'fa-times-circle': !mealBooked}"></i></span>Meal <ng-container *ngIf="mealBooked else bookingFailed">Booked Successfully</ng-container></li>
                                      
                                        <li *ngIf="seatRequested"><span class="bsuccess " [ngClass]="{'successcolor': seatBooked,'failcolor': !seatBooked}"><i class="fas  mr-2"
                                            [ngClass]="{'fa-check-circle': seatBooked,'fa-times-circle': !seatBooked}"></i></span>Seat <ng-container *ngIf="seatBooked else bookingFailed">Booked Successfully</ng-container></li>
                                        
                                        <li *ngIf="baggageRequested"><span class="bsuccess " [ngClass]="{'successcolor': baggageBooked,'failcolor': !baggageBooked}"><i class="fas  mr-2"
                                            [ngClass]="{'fa-check-circle': baggageBooked,'fa-times-circle': !baggageBooked}"></i></span>Baggage <ng-container *ngIf="baggageBooked else bookingFailed">Booked Successfully</ng-container></li>
                                    </ul>
                                    <ng-template #bookingFailed>Booking Failed</ng-template>
                                </span>
        
                            </span>
        
                        </div>
        
                        <mat-tab-group>
                            <mat-tab label="Add Meals">
                                <ng-container>
                                    <div class="mt-5 seat-loader" *ngIf="!showAddons">
                                        <div class="lds-roller" *ngIf="!showSSRError"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                        <span *ngIf="showSSRError">
                                            <span>
                                                We're experiencing some trouble with airline system. Please give it a retry.
                                            </span>
                                            <div class="inv-btn mt-4 text-center">
                                                <a  href="javascript:void(0)" (click)=getSSR()>Retry</a>
                                            </div>
                                        </span>
                                    </div>
                                </ng-container>
                                <app-addon-meal [ssrAvailability]="ssrAvailability" [showRequestLoader]="showRequestLoader" [responseData]="responseData" (callMealEvent)="getMealEvent($event)"></app-addon-meal>
                            </mat-tab>
                            <mat-tab label="Add Baggage">
                                                                    <ng-container>
                                                                        <div class="mt-5 seat-loader" *ngIf="!showAddons">
                                                                            <div class="lds-roller" *ngIf="!showSSRError"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                                                            <span *ngIf="showSSRError">
                                                                                    <span>
                                                                                        We're experiencing some trouble with airline system. Please give it a retry.
                                                                                    </span>
                                                                                    <div class="inv-btn mt-4 text-center">
                                                                                        <a  href="javascript:void(0)" (click)=getSSR()>Retry</a>
                                                                                    </div>
                                                                            </span>
                                                                        </div>
                                                                    </ng-container>
                                                                    <app-addon-baggage [responseData]="responseData" [showRequestLoader]="showRequestLoader" [ssrAvailability]="ssrAvailability" (callBaggageEvent)="getBaggageEvent($event)"></app-addon-baggage>
                                                                </mat-tab>
                            <mat-tab label="Add Seats">
                                                                    <ng-container>
                                                                        <div class="mt-5 seat-loader" *ngIf="!showAddons">
                                                                            <div class="lds-roller" *ngIf="!showSeatError"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                                                            <span *ngIf="showSeatError">
                                                                                <span>
                                                                                    We're experiencing some trouble with airline system. Please give it a retry.
                                                                                </span>
                                                                                <div class="inv-btn mt-4 text-center">
                                                                                    <a href="javascript:void(0)" (click)=getSSR()>Retry</a>
                                                                                </div>
                                                                            </span>
                                                                        </div>
                                                                    </ng-container>
                                                                    <app-addon-seat [responseData]="responseData" [showRequestLoader]="showRequestLoader" [seatMapArr]="seatMapArr" [passengerArr]="passengerArr" (callSeatEvent)="getSeatEvent($event)">
                                                                    </app-addon-seat>
                                                                </mat-tab>
                        </mat-tab-group>
                    </div>
                </div> -->
                
                <div class="separator"></div>
    
                <div class="  d-flex align-items-center justify-content-between mr-2 ml-2" *ngIf="showProceedToPay">
                    <div (click)="toggleAddonSideBar()" class="add-on-total-amt border-radius-10 cursor-pointer hover-shadow  p-2 pl-3 pr-3 transition">
                        <small class="text-black text-bold" >Total Add-Ons</small>
                        <h4 class="m-0 text-dark-pink text-bold font-20 ">₹ {{selectedSsrInRedux && selectedSsrInRedux.totalAmount ? selectedSsrInRedux.totalAmount : 0 | number : '0.0-0'}} <small><i class="fa fa-info-circle ml-2 text-dark-green" aria-hidden="true"></i></small>
                        </h4>
                    </div>
                    <a class="btn btn-lg btn-green" href="javascript:void(0)" *ngIf="!showRequestLoader" (click)=updateSSR()>Continue to Payment <i class="fas fa-chevron-right ml-2"></i></a>
                    <a class="btn btn-lg btn-green" href="javascript:void(0)" *ngIf="showRequestLoader">Processing <i class="fas fa-circle-notch fa-spin"></i></a>
                </div>
            </div>

        </div>

        <!-- <div class="booking-history card mb-3 mb-2">
            <div class="card-header d-flex justify-content-between align-items-center flex-dir-row">
                <h6 class="text-bold text-black   ">Booking Activity</h6>
                <span class="btn btn-sm btn-success"><a class="text-white" href="javascript:void(0)" (click)="showHistory()"><i class="fa fa-link mr-2" aria-hidden="true"></i>Open</a></span>
                </div>
            <div class="card-body m-0 p-2 row fadeInTop easet2" *ngIf="historyShow" style="max-height: 250px;
            overflow-y: scroll;">
                <div class="col-md-12">
                    <app-booking-activity [bookingActivity]="bookingActivity" [bookingActivityReference]="bookingArr[0]?.json_doc.booking_ref"></app-booking-activity>
                </div>
            </div>
        </div> -->


        <div class=" clearfix inv-btn mt-4 mb-5" *ngIf="bookedWithAffiliateCreds && bookingArr[0].json_doc.pnr_status == 'FAILED'">
           <div class="form-group w-25">
               <label for="my-input">PNR</label>
               <input id="my-input" class="form-control" oninput="this.value = this.value.toUpperCase()" (input)="setAirlinePNR($event)" type="text" name="">
               <span class="text-danger" *ngIf="invalidMsg !=''" >{{invalidMsg}}</span>
           </div>
        </div>
        <div class="d-flex align-items-center justify-content-center flex-wrap mt-4 mb-5" *ngIf="bookingArr">           
            <div class="shimmer p-0  download-shimmer">
                <div class="wrapper">
                  <!-- <div class="stroke animate d-flex align-items-center justify-content-center title m-0"><span>Download Policy</span> </div> -->
                  <a class="btn pt-2 pb-2 pl-3 pr-3 text-white text-bold btn-sm  transition ticket " [href]="bookingArr[0].json_doc.insurance_policy.policy_docs.insurance_pdf_file_url" *ngIf="bookingArr[0].json_doc.insurance_policy && bookingArr[0].json_doc.insurance_policy.status=='issued' && bookingArr[0].json_doc.insurance_policy.policy_docs.insurance_pdf_file_url.length>0">Download Policy</a>
                  </div>
            </div>
            <button class="btn pt-2 pb-2 pl-3 pr-3 text-white text-bold btn-sm btn-green" (click)="isBookingHistoryActive = !isBookingHistoryActive">View Booking Act.</button>
            <a class="btn pt-2 pb-2 pl-3 pr-3 text-white text-bold btn-sm  invoice transition" href="javascript:void(0)" *ngIf="bookingArr[0].json_doc.pnr_status != 'FAILED' && bookingArr[0].json_doc.pnr_status != 'HOLD' && bookingArr[0].json_doc.pnr_status != 'REFUNDED'" (click)="printInvoice()">View Invoice</a>
            <a class="ml-2 btn pt-2 pb-2 pl-3 pr-3 text-white text-bold btn-sm  transition btn-info" href="javascript:void(0)" *ngIf="bookingArr[0].json_doc.pnr_status != 'FAILED' && bookingArr[0].json_doc.pnr_status != 'HOLD' && bookingArr[0].json_doc.pnr_status != 'REFUNDED'"(click)="printTicket()">View Ticket</a>
            <a class="ml-2 btn pt-2 pb-2 pl-3 pr-3 text-white text-bold btn-sm  transition re-issue" href="javascript:void(0)" *ngIf="bookingArr[0].json_doc.pnr_status == 'TICKETED' &&
                !bookingArr[0].isFullyTravelled" (click)="openReIssueModal()">Re-Issue</a>
            <a class="ml-2 btn pt-2 pb-2 pl-3 pr-3 text-white text-bold btn-sm  transition cancel-ticket" href="javascript:void(0)" *ngIf="bookingArr[0].json_doc.pnr_status == 'TICKETED' &&  !bookingArr[0].sectorTravelledCount" (click)="cancelReq('TICKET',showVoid ? 'void' : 'cancel')">{{showVoid ? 'Void' : 'Cancel'}} Ticket</a>
            <a class="ml-2 btn pt-2 pb-2 pl-3 pr-3 text-white text-bold btn-sm  transition cancel-sector" href="javascript:void(0)" *ngIf="bookingArr[0].json_doc.pnr_status == 'TICKETED' && bookingArr[0].json_doc.segments.length > 1 && !bookingArr[0].isFullyTravelled && !showVoid" (click)="cancelReq('SECTOR','cancel')" >Cancel Sector</a>
            <a class="ml-2 btn pt-2 pb-2 pl-3 pr-3 text-white text-bold btn-sm  transition split-traveller" href="javascript:void(0)" *ngIf="bookingArr[0].json_doc.pnr_status == 'TICKETED' && bookingArr[0].json_doc.travellers.length > 1 && getTravellerCountByType(bookingArr[0].json_doc.travellers).ADT>1 && !bookingArr[0].isFullyTravelled" (click)="openSplitTravellerModal()" >Split Travellers</a>

            <a class="ml-2 btn pt-2 pb-2 pl-3 pr-3 text-white text-bold btn-sm  transition re-attempt-booking bg-danger" href="javascript:void(0)" *ngIf="showReattemptButton" (click)="reAttemptBooking()">Re-Attempt Booking</a>
            <a class="ml-2 btn pt-2 pb-2 pl-3 pr-3 text-white text-bold btn-sm  transition re-issue" href="javascript:void(0)" *ngIf="!bookedWithAffiliateCreds && bookingArr[0].json_doc.pnr_status == 'HOLD' && !invalidCredit" (click)="issueTicket()">Issue Ticket</a>
            <a class="ml-2 btn pt-2 pb-2 pl-3 pr-3 text-white text-bold btn-sm  transition cancel-ticket" href="javascript:void(0)" *ngIf="bookedWithAffiliateCreds && (bookingArr[0].json_doc.pnr_status == 'HOLD' || bookingArr[0].json_doc.pnr_status == 'FAILED')" (click)="issueFailedTicket(bookingArr[0])">Issue Ticket</a>
            <a class="ml-2 btn pt-2 pb-2 pl-3 pr-3 text-white text-bold btn-sm  transition split-traveller" href="javascript:void(0)" *ngIf="bookingArr[0].json_doc.pnr_status == 'REFUNDED' && bookingArr[0].json_doc.invoice_no" (click)="viewCreditNote()">View credit note</a>
            <a class="ml-2 btn pt-2 pb-2 pl-3 pr-3 text-white text-bold btn-sm  transition split-traveller" href="javascript:void(0)" (click)="viewFareRules()">View Fare Rules</a>
            
        </div>

    
</ng-container>

<button type="button" #openModal hidden class="btn btn-info btn-lg d-none"  data-backdrop="static" data-keyboard="false" data-toggle="modal" data-target="#ErrorModal"></button>

<div id="ErrorModal"  class="modal fade" role="dialog">
    <div class="modal-dialog">
  

        
      <!-- Modal content-->
 <div class="modal-content">
    <div class="warn-icon"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></div>
    <!-- <div class="warning-con">
        The cancellation request service is only available on Mon-Sat between  <strong>08:00 AM</strong> to <strong>08:00 PM</strong> and before  <strong>4 hrs</strong> from journey date.
    </div> -->
    <div class="warning-con" [innerHtml]="cancellationWarningMessage">
    </div>
    <div class="modal-footer float-center">
            <button type="button" class="btn btn-red btn-close" data-dismiss="modal">Close</button>
          </div>
 </div>
  
    </div>
  </div>


<button type="button" #openSuccessModal hidden class="btn btn-info btn-lg d-none" data-toggle="modal" data-target="#SuccessModal"></button>

<div id="SuccessModal"  class="modal fade" role="dialog">
    <div class="modal-dialog">
  
      <!-- Modal content-->
 <div class="modal-content">
    <div class="success-icon"><i class="fa fa-check" aria-hidden="true"></i></div>
    <div class="warning-con">
        {{successAddonMessage}}
    </div>
    <div class="modal-footer float-center">
            <button type="button" class="btn btn-close" data-dismiss="modal" (click)="reloadPage()">Okay</button>
          </div>
 </div>
  
    </div>
  </div>


<!-- booking history Modal -->
<div class="custom-modal-box top-right transition add-on-modal-box p-3 " style="min-width:350px;" [ngClass]="isBookingHistoryActive ? 'active' : ''"  >
    <div class="filter-header ">
        <img (click)="isBookingHistoryActive = !isBookingHistoryActive "  src="../../../assets/v3images/icons/red-close.svg" alt="" srcset="" class="custom-modal-close-btn position-absolute grayscale-filter no-grayscale-filter-hover cursor">
        <small class="filterName m-0 text-uppercase text-pinkish-red "> <span clss="text-bold">{{bookingArr[0]?.json_doc.booking_ref}}</span></small>
        <h5 class="mt-0 text-bold text-capitalize">Booking Activities</h5>
        <!-- <small class="filter-result-counts"> 1424 Results Found</small> -->
    </div>  
    <div class="  booking-activities-area p-2 border-radius-10">
        <app-booking-activity [bookingActivity]="bookingActivity" [bookingActivityReference]="bookingArr[0]?.json_doc.booking_ref"></app-booking-activity>
    </div>
  </div>

<!-- Re-issue modal -->
<button type="button" #openReIssuanceModal hidden class="btn btn-info btn-lg d-none"  data-backdrop="static" data-toggle="modal" data-target="#reIssuanceModal"></button>

<div id="reIssuanceModal"  class="modal fade" role="dialog">
    <div class="modal-dialog modal-xl custom-modal modal-dialog-centered">
        <div class="modal-content w-100">
            <form >
                <div class="modal-header pb-4">
                    <h5 class="modal-title">Select Sector(s) to Re-Issue
                        <small class="mt-2 d-block">Edit sector(s) & select it to re-issue your flight ticket.</small>
                    </h5>
                        <button type="button" class="close" #closeReIssuanceModal data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                </div>
                <div class="modal-body">
                    <form [formGroup]="searchForm">
                        <ng-container formArrayName="city">
                        <ng-container *ngFor="let searchControl of DynamicFormControls.controls; let i = index;">
                        <div class="row ml-0 mr-0 mb-3 align-center-middle" [formGroupName]="i" [ngClass] = "(searchControl.value.isTravelled) ? 'not-select-sector':''">
                            <div class="col-sm-1 form-group">
                                <label for="fromAirport"></label>
                                <!-- <div class="departure-field field " 
                                [ngClass]="{'not-select-sector': (searchControl.value.originConnectionType == 'X' || searchControl.value.destinationConnectionType == 'X')}"
                                >
                                   <input (change)="reIssueSectorSelection($event,i)" formControlName="isSelected" type="checkbox" name="" id="">
                                </div> -->
                                <div class="departure-field field " *ngIf="!isBeforeThanCurrentDate(searchControl.value.depDate)" >
                                   <input (change)="reIssueSectorSelection($event,i)" formControlName="isSelected" type="checkbox" name="" id="">
                                </div>
                              </div>
                            <div class="col-sm-4 form-group">
                                <label for="fromAirport"></label>
                                <div *ngIf="searchControl.value.originConnectionType =='X' else editableOrigin" class="destination-field field ng-autocomplete">
                                    <input type="text" formControlName="from" class="form-control custom-input" autocomplete="off" readonly> 
                                </div>
                                <ng-template #editableOrigin>
                                    <div class="departure-field field ng-autocomplete">
                                        <ng-autocomplete formControlName="from"   [data]="departureCity" [searchKeyword]="keyword"
                                            (selected)='changeDestination($event, "departure",i)' (inputChanged)='bindDestination($event,"departure",i)'
                                             [itemTemplate]="itemTemplate" class="dep-field"
                                            [notFoundTemplate]="notFoundTemplate" [disabled]="searchControl.value.isSelected == false || isBeforeThanCurrentDate(searchControl.value.depDate)" placeHolder="Select Departure City">
                                        </ng-autocomplete>
                
                                        <ng-template #itemTemplate let-item>
                                            <a [innerHTML]="item.result"></a>
                                        </ng-template>
                
                                        <ng-template #notFoundTemplate let-notFound>
                                            <div [innerHTML]="notFound"></div>
                                        </ng-template>
                                    </div>
                                </ng-template>
                              </div>
                              <div class="col-sm-4 form-group">
                                <label for="toAirport"></label>
                                <div *ngIf="searchControl.value.destinationConnectionType == 'X' else editableDestination" class="destination-field field ng-autocomplete">
                                    <input type="text" formControlName="to" class="form-control custom-input" autocomplete="off" readonly> 
                                </div>
                               <ng-template #editableDestination>
                                <div class="destination-field field ng-autocomplete">
                                    <ng-autocomplete formControlName="to" [data]="destinationCity" [searchKeyword]="keyword"
                                    (selected)='changeDestination($event, "destination",i)'
                                    (inputChanged)='bindDestination($event,"destination",i)'
                                         [itemTemplate]="itemTemplate" [disabled]="searchControl.value.isSelected == false || isBeforeThanCurrentDate(searchControl.value.depDate)" 
                                        [notFoundTemplate]="notFoundTemplate" placeHolder="Select Arrival City">
                                    </ng-autocomplete>
        
                                    <ng-template #itemTemplate let-item>
                                        <a [innerHTML]="item.result"></a>
                                    </ng-template>
        
                                    <ng-template #notFoundTemplate let-notFound>
                                        <div [innerHTML]="notFound"></div>
                                    </ng-template>
                                </div>
                               </ng-template>
                              </div>
                              <div class="col-sm-3 form-group">
                                <label for="flightDate"></label>
                                <input *ngIf="searchControl.value.destinationConnectionType == 'X' else editableDate" type="text" class="form-control" autocomplete="off" [ngClass]="{'d-none': searchControl.value.originConnectionType != 'X'}"  formControlName="depDateText" readonly="{{searchControl.value.isSelected == false}}">
                                <ng-template #editableDate>
                                    <input bsDatepicker autocomplete="off" #fDate formControlName="depDate"
                                    #dpYMD="bsDatepicker" [bsConfig]="bsConfig" type="text" name="depdate-round" class="form-control"  id="flightDateto" placeholder=""
                                    [minDate]="departureMinDate" (ngModelChange)="departureDateChange(searchForm.value,i);" disabled="{{searchControl.value.isSelected == false}}" 
                                    placeholder=" " />
                                </ng-template>
                               
                              </div>
                        </div>
                    </ng-container>
                </ng-container>
                    </form>
                </div>
        
    </form>
    <blockquote class="card ml-4 mr-4 p-2 pl-3 pr-3 bg-light text-black mt-5">
        <p>
            <b class="text-pinkish-red mr-2">Note: </b> <span class="text-black"> By selecting selector(s) and clicking <b>Proceed button</b>, system will redirect you to search page where you can search your desired flight(s).</span>
        </p>
    </blockquote>
    <div class="modal-footer">
            <button type="button" class="btn btn-green btn-lg" data-dismiss="modal" (click)="submit(searchForm.value)">Proceed</button>
          </div>
 </div>
  
    </div>
  </div>


<!-- Re-issue error modal -->
<button type="button" #openReIssuanceErrorModal hidden class="btn btn-info btn-lg d-none" data-toggle="modal"  data-backdrop="static" data-target="#reIssuanceErrorModal"></button>


<div class="modal fade" id="reIssuanceErrorModal" tabindex="-1" role="dialog" aria-labelledby="reIssuanceErrorModalTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered re-issuance-modal custom-modal" role="document">
        <div class="modal-content w-100">
        <div class="modal-header text-center" style="background:#7c68d4!important; color: #fff !important;">
          <h5 class="modal-title" id="exampleModalLabel" *ngIf="reissuePossible">Manual Steps Required!</h5>
          <h5 class="modal-title" id="exampleModalLabel" *ngIf="!reissuePossible">Please Wait!</h5>
          <button type="button" class="close"  (click)="closeReissueErrorModalFn()" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" #closeReIssuanceErrorModal data-dismiss="modal">&times;</span>
          </button>
        </div>
        <div class="modal-body text-center">
            <div class="highlight">
                {{reIssueErrorMessage}}
            </div>
            <ul class="progressbar" *ngIf="reissuePossible">
                <li class="active first">
                    <small>PNR is already CheckedIn</small>
                </li>
                <li class="second active {{bookingArr[0].json_doc.segments[0].booking_supplier}}" >
                  <!-- <p class="imgs">
                      <img src="assets/images/flight/6E.png" width="35px" height="35px" style="border-radius: 5px;" />
                  </p> -->
                      <a href="{{airlinePnrRetrieveUrl}}" class="btn btn-primary" target="_blank">Web Check out</a>
                </li>
                <!-- <p><small>Web Checkout is Successfull</small></p> -->
                <li *ngIf="modalBodyMsg === 're-issuance'" class="third">
                  <a  href="javascript:void(0)" (click)="openReIssueModal()" class="btn btn-primary" >Retry</a>
                </li>
               <li *ngIf= "modalBodyMsg === 'cancellation' && cancellationTypeValue === 'SECTOR'" class="third" > 
                    <a href="javascript:void(0)" (click)="cancelReq('SECTOR')" class="btn btn-primary" >Retry</a>
                </li>
              <!---->
               <li *ngIf= "modalBodyMsg === 'cancellation' && cancellationTypeValue === 'TICKET'" class="third" > 
                    <a href="javascript:void(0)"  (click)="cancelReq('TICKET')" class="btn btn-primary" >Retry</a>
                </li> 
                <li *ngIf="modalBodyMsg === 'split'" class="third">
                  <a  href="javascript:void(0)"(click)="openSplitTravellerModal()" class="btn btn-primary" >Retry</a>
                </li>              
            </ul>
        </div>
      </div>
    </div>
  </div>

<!-- SPLIT TRAVELLERS MODAL AND BUTTONS -->


<button type="button" #openSplitPnrModal hidden class="btn btn-info btn-lg d-none"  data-backdrop="static" data-toggle="modal" data-target="#splitPnrModal"></button>

<div id="splitPnrModal" class="modal fade" role="dialog">
    <div class="modal-dialog modal-xl custom-modal modal-dialog-centered" >
        <div class="modal-content w-100">
            <div class="modal-header">
                <h5 class="modal-title" *ngIf="!splitTravellersList3 && !showSplitResponse">Proceed to Split Traveller</h5>
                <h5 class="modal-title" *ngIf="splitTravellersList3 && showSplitResponse && newSplitBooking && newSplitBooking['json_doc']">Booking split successfully done!</h5>
                <h5 class="modal-title" *ngIf="splitTravellersList3 && showSplitResponse && !newSplitBooking">Booking split failed!</h5>

                <button type="button" class="close" (click)="resetModal()" #closeSplitPnrModal data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" *ngIf="splitTravellersForm">
                <div class="container" *ngIf="splitTravellersList1">
                    <div class="row">
                        <div class="col-sm-6">
                            <form [formGroup]="splitTravellersForm" class="split-travellers">
                            <ng-container formArrayName="travellers" *ngIf="DynamicSplitTravellersFormControls">
                                <ng-container
                                    *ngFor="let travellerForm of DynamicSplitTravellersFormControls.controls; let i = index;">
                            <ul class="m-0" >
                                <li [formGroupName]="i" [ngClass]="travellerForm.value.status == 'REQUESTED' ? 'active' : ''"
                                title="{{getSplittravellerInfo(travellerForm.value)}}">
                                    <label for="traveller{{i}}" class="d-flex align-item-center d-block" 
                                    [ngClass]="{disable_pointer: travellerForm.value.type == 'INF'}">
                                    <div class="  form-group mr-1">
                                        <!-- <label for="traveller{{i}}"></label> -->
                                        <div class="departure-field field ">
                                            <input formControlName="paxSelected" (change)="selectTraveller($event,i)" id="traveller{{i}}" type="checkbox" name="paxSelected">
                                        </div>
                                    </div>
                                    <div>
                                        <h5 class="m-0 text-uppercase"> {{travellerForm.value.title}} 
                                            {{travellerForm.value.firstName}}
                                            {{travellerForm.value.lastName}} 

                                            <span *ngIf="travellerForm.value.type == 'INF'">
                                                <i class="fas fa-info-circle"  ></i></span>
                                                
                                        </h5>
                                            <p class="m-0 text-grey"><small>Type: {{travellerForm.value.type}}</small> | <small>TKT NO: {{travellerForm.value.ticketNo}}</small> </p>
                                            
                                    </div>
                                </label>
                                </li>
                            </ul>
                            </ng-container>
                        </ng-container>
                    </form>
                        </div>
                        <div class="col-sm-6">
                            <div class="split-traveller-card">
                                <h4>Split booking helps you with Following:</h4>
                            <ul>
                                <li>
                                    <h5>1. Cancel Specific Traveller(s)</h5>
                                    <small class="text-grey">In your booking with multiple travellers, specfic traveller would like to cancel and request for refund, You can split booking for specific traveller and proceed with partial cancellation.</small>
                                </li>
                                <li>
                                    <h5>2. Reschedule Specific Traveller(s)</h5>
                                    <small class="text-grey">In your booking with multiple travellers, specfic traveller would like to travel seprately on other date  You can split booking for specific traveller and proceed with partial reschedule.</small>
                                </li>
                                <li>
                                    <h5>3. Change Itinerary for Specific Traveller(s)</h5>
                                    <small class="text-grey">In your booking with multiple travellers, specfic traveller would like to travel to other destination, You can split booking for specific traveller and proceed with partial itinerary change.</small>
                                </li>
                            </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container " *ngIf="splitTravellersList2">
                    <div class="split-traveller-card text-center ">
                        <span (click)="moveStep1()" class="cursor" style="    float: left;
                        margin-top: 5px; cursor:pointer"><i class="fas fa-arrow-left mr-2"></i> </span><h4>Following traveller(s) will be moved to new booking:</h4>
                        <div class="d-flex flex-wrap justify-content-center align-item-center mt-2" >                            
                            <div *ngFor="let travellerForm of DynamicSplitTravellersFormControls.controls; let i = index;">
                               <div class="traveller-card d-flex align-items-center" *ngIf="travellerForm.value.status=='REQUESTED'">
                                <img src="assets/images/green-tick.png" width="35px" height="35px"  />
                                <div class="ml-2">                                  
                                    <h5 class="mb-0">{{travellerForm.value.title}} 
                                        {{travellerForm.value.firstName}}
                                        {{travellerForm.value.lastName}}</h5>
                                    <p class="m-0 text-grey"><small>Type: {{travellerForm.value.type}}</small> | <small>PNR: {{travellerForm.value.ticketNo}}</small> </p>
                                </div> 
                               </div>                               
                                </div>
                        </div>
                        <p class="mt-5">After split, you can proceeed with <b>REISSUANCE</b> or <b>CANCELLATION</b></p>
                    </div>
                </div>
                <div class="container " *ngIf="splitTravellersList3">
                    <!-- <h5 *ngIf="newSplitBooking && newSplitBooking['json_doc']" style="color: green;text-align: center;">Requested travellers are moved to new  booking!</h5> -->
                    <h5 *ngIf="showSplitResponse && !newSplitBooking" style='color:red;text-align: center;'>Failed! Please contact our backend team to process split offline!</h5>

                    <div class="split-traveller-card text-center" >
                        <!-- <h4 *ngIf="showSplitResponse && newSplitBooking && newSplitBooking['json_doc']" style="color:green">Selected travellers are moved successfully to new booking <span (click)="openDetail(newSplitBooking['json_doc']['booking_ref'])" style="
                            color: blue;
                            cursor: pointer;
                        ">{{newSplitBooking['json_doc']['booking_ref']}}</span> </h4> -->
                        <h4 *ngIf="showSplitResponse && newSplitBooking && newSplitBooking['json_doc']" style="color:green"> Split is in progess. It will reflect in few mins.  </h4>
                       <div class="container">
                           <div class="row">
                               <div class="col-sm-6">
                                   <div class="booking-split-card old">
                                    <img src="assets/images/old-ticket.png"  />
                                    <h4 class="mb-0"><small class="text-grey d-block">Booking Ref:</small> <b>{{bookingArr[0]['json_doc']['booking_ref']}}</b></h4>
                                    <h4 class="mb-0"><small class="text-grey d-block">PNR Number:</small> <b>{{bookingArr[0]['json_doc']['gds_pnr']}}</b></h4>
                                    <h4 class="mb-0"><small class="text-grey d-block">Traveller(s):</small>{{bookingArr[0]['json_doc']['travellers'][0]['title']}} {{bookingArr[0]['json_doc']['travellers'][0]['first_name']}} {{bookingArr[0]['json_doc']['travellers'][0]['last_name']}}
                                    <ng-container *ngIf="bookingArr[0]['json_doc']['travellers'].length-1 > 0">
                                        + {{bookingArr[0]['json_doc']['travellers'].length-1}}
                                    </ng-container></h4>
                                    <button class="btn btn-custom old-pnr-btn" style="border-radius: 5px;" (click)="openDetail(bookingArr[0]['json_doc']['booking_ref'])">View Booking</button>
                                   </div>
                               </div>
                               <div class="col-sm-6" *ngIf="newSplitBooking && newSplitBooking['json_doc']">
                                   <div class="booking-split-card new">
                                    <img src="assets/images/old-ticket.png"  />
                                    <h4 class="mb-0"><small class="text-grey d-block">Booking Ref:</small> <b>{{newSplitBooking['json_doc']['booking_ref']}}</b></h4>
                                    <h4 class="mb-0"><small class="text-grey d-block">PNR Number:</small> <b>{{newSplitBooking['json_doc']['gds_pnr']}}</b></h4>
                                    <h4 class="mb-0"><small class="text-grey d-block">Traveller(s):</small>{{newSplitBooking['json_doc']['travellers'][0]['title']}} {{newSplitBooking['json_doc']['travellers'][0]['first_name']}} {{newSplitBooking['json_doc']['travellers'][0]['last_name']}} 
                                    <ng-container *ngIf="newSplitBooking['json_doc']['travellers'].length-1 > 0">
                                        + {{newSplitBooking['json_doc']['travellers'].length-1}}
                                    </ng-container> </h4>
                                    <button class="btn btn-custom new-pnr-btn" style="border-radius: 5px;" (click)="openDetail(newSplitBooking['json_doc']['booking_ref'])">View Booking</button>
                                   </div>
                               </div>
                           </div>
                       </div>
                    </div>
                </div>
                <!-- <form [formGroup]="splitTravellersForm">
                    <ng-container formArrayName="travellers" *ngIf="DynamicSplitTravellersFormControls">
                        <ng-container
                            *ngFor="let travellerForm of DynamicSplitTravellersFormControls.controls; let i = index;">
                            <div class="row align-center-middle" [formGroupName]="i">
                                <div class="col-sm-1 form-group">
                                    <label for="traveller{{i}}"></label>
                                    <div class="departure-field field ">
                                        <input (change)="selectTraveller($event,i)" id="traveller{{i}}" type="checkbox" name=""
                                            id="">
                                    </div>
                                </div>
                                <div class="col-sm-4 form-group">
                                    <label for="traveller{{i}}">
                                        {{travellerForm.value.title}} 
                                        {{travellerForm.value.firstName}}
                                        {{travellerForm.value.lastName}}
                                    </label>
                                </div>
                                <div class="col-sm-4 form-group">
                                    <label for="traveller{{i}}">
                                        {{travellerForm.value.type}}
                                    </label>
                                </div>
                                <div class="col-sm-3 form-group">
                                    <label for="traveller{{i}}">
                                        {{travellerForm.value.ticketNo}}
                                    </label>

                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </form> -->
            </div>
            <div class="modal-footer d-block" *ngIf="splitTravellersForm">
                <!-- <button type="button" class="btn re-issue  btn-custom"
                    (click)="submitSplitPnrRequest()">Submit Split Request</button> -->
                    <p *ngIf="showTravellerWarningMessage" class=" text-danger text-center" style=" background: #ff000014; width: max-content; margin: 8px auto; font-size: 12px; padding: 2px 10px; "><img src="../../../../assets/images/warning.svg" alt="" srcset="" width="15px" class="mr-2">{{showTravellerWarningMessage}}</p>
                    <div class=" d-flex align-items-center justify-content-center">
                        <button *ngIf="splitTravellersList1" (click)="moveStep2()" type="button" class="btn mr-2 re-issue"
                    [disabled]="!isTravellerValidSelected">Submit Split Request</button>
                    
                    <button *ngIf="splitTravellersList2" type="button" (click)="moveStep3()" class="btn re-issue mr-2 
                     re-issue  "
                    >Yes! I understand, Proceed!</button>


                    <!-- <button  (click)="submitSplitPnrRequest()" *ngIf="splitTravellersList3" type="button" class="btn re-issue  btn-custom"
                    >Re-Issue Preview Refund</button> -->
                    <button   (click)="resetModal()" #closeSplitPnrModal data-dismiss="modal" aria-label="Close"  type="button"  class="btn cancel-ticket  "
                    > Close</button>
                    </div>
            </div>
        </div>

    </div>
</div>


<!-- CANCEL SECTORS MODAL AND BUTTONS -->


<button type="button" #openCancelSectorModal hidden class="btn btn-info btn-lg d-none"  data-backdrop="static" data-toggle="modal" data-target="#CancelSectorModal"></button>

<div id="CancelSectorModal" class="modal fade" role="dialog">
    <div class="modal-dialog modal-xl custom-modal modal-dialog-centered"  style="    min-width: 1000px; justify-content: center;" >
        <div class="modal-content w-100" style="    width: calc(100vmax) !important;">
            <div class="modal-header">
                <h5 class="modal-title" *ngIf="cancelSectorForm && !splitSectorModal2">Proceed to {{refundType}} sector for all passengers.</h5>
                <h5 class="modal-title" *ngIf="splitSectorModal2">Proceed to {{refundType}} ticket.</h5>

                <button type="button" (click)="resetSectorModal()" class="close" #closeCancelSectorModal data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div
            class="modal-body  text-center d-block"
            style="background: #fafafa"
            *ngIf="cancelSectorForm && !splitSectorModal2 && !splitSectorModal1"
          >
            <div class="img-container text-center">
              <img
                src="../../../../assets/v3images/flying-flying.gif" width="300px"
                
              class="animated-loader-text d-flex align-items-center ml-auto mr-auto justify-content-center"
            >
              <small >Fetching ticket data </small>
              <div id="wave">
                <span class="dot"></span>
                <span class="dot"></span>
                <span class="dot"></span>
              </div>
            </div>
          </div>
            <div class="modal-body cancelSectorForm" *ngIf="cancelSectorForm">
                <div  *ngIf="splitSectorModal1">
                    <form [formGroup]="cancelSectorForm">
                        <table class=" mobile-scroll ">
                            <tr>
                                <th></th>
                                <th>Flight No</th>
                                <th>Origin</th>
                                <th>Destination</th>
                                <th>Departure</th>
                                <th>Arrival</th>
                            </tr>
                           <ng-container formArrayName="segments" *ngIf="DynamicCancelSectorFormControls">
                            <ng-container *ngFor="let sectorForm of DynamicCancelSectorFormControls.controls; let i = index;">
                                <tr [formGroupName]="i" [ngClass] = "bookingArr[0].json_doc.segments[i].isTravelled?'not-select-sector':''">
                                    <td>
                                        <label for="sector{{i}}"></label>
                                        <div class="departure-field field ">
                                            <label style="margin-top: -7px;" class="checkbox-container ml-3">
                                                <input formControlName="sectorSelected" (change)="selectSegment($event,i)"  id="sector{{i}}" type="checkbox" >
                                                <span class="checkmark"></span>
                                              </label>
                                            <!-- <input formControlName="sectorSelected" (change)="selectSegment($event,i)"  id="sector{{i}}" type="checkbox"> -->
                                        </div></td>
                                    <td>{{sectorForm.value.flightNumber}}</td>
                                    <td>{{sectorForm.value.originName}}({{sectorForm.value.origin}})</td>
                                    <td>{{sectorForm.value.destinationName}}({{sectorForm.value.destination}})</td>
                                    <td>{{sectorForm.value.departureDateTime}}</td>
                                    <td>{{sectorForm.value.arrivalDateTime}}</td>
                                </tr>
                            </ng-container>
                           </ng-container>
                        </table>
                    </form>                    
                </div>
                <div *ngIf="splitSectorModal2">
                    <span (click)="moveSectorStep1()" class="cursor" style="
                        cursor:pointer"><i class="fas fa-arrow-left mr-2"></i> </span>
               <div class="sector-tables-height">
                    <table class=" mobile-scroll ">
                        <tr>
                            <th>Airline</th>
                            <th >Departure</th>
                            <th >Arrival</th>
                            <th class="text-center">Code</th>
                            <th class="text-center">Class</th>
                            <th class="text-right">Date</th>
                        </tr>
                <tbody *ngIf="bookingArr && bookingArr[0] && bookingArr[0]['json_doc']['segments']">
                    <tr class="selectedcancelsector"  *ngFor="let segment of bookingArr[0]['json_doc']['segments']; let i=index;" >
                        <td *ngIf="DynamicCancelSectorFormControls.controls[i].value.status=='REQUESTED'">{{segment.legs[0].operating_airline_name}}</td>
                        <td *ngIf="DynamicCancelSectorFormControls.controls[i].value.status=='REQUESTED'">{{segment.legs[0].origin_airport_city}}({{segment.legs[0].origin_airport_code}})</td>
                        <td *ngIf="DynamicCancelSectorFormControls.controls[i].value.status=='REQUESTED'">{{segment.legs[segment.legs.length -1].destination_airport_city}}({{segment.legs[segment.legs.length -1].destination_airport_code}})</td>
                        <td class="text-center" *ngIf="DynamicCancelSectorFormControls.controls[i].value.status=='REQUESTED'">{{segment.legs[0].operating_airline_code}}</td>
                        <td class="text-center" *ngIf="DynamicCancelSectorFormControls.controls[i].value.status=='REQUESTED'">{{segment.legs[0].cabin_class}}</td>
                        <td class="text-right" *ngIf="DynamicCancelSectorFormControls.controls[i].value.status=='REQUESTED'">{{segment.legs[0].origin_timestamp | formatDate:'shortDateTime'}}</td>
                    </tr>
                </tbody>
                    </table>
                    <br />
                    <table class=" mobile-scroll ">
                            <tr>
                                <th>Sno.</th>
                                <th>TKT No</th>
                                <th>Name</th>
                                <th>Type</th>
                                <th class="text-right">Base</th>
                                <th class="text-right">YQ</th>
                                <th class="text-right">YR</th>
                                <th class="text-right">K3</th>
                                <th class="text-right">OC</th>
                                <th class="text-right">OT</th>
                                <th class="text-right">Seat</th>
                                <th class="text-right">Meal</th>
                                <th class="text-right">Baggage</th>
                                <th class="text-right">Addnl. Bg</th>
                                <th class="text-right">Ancillary</th>
                                <th class="text-right"> Total</th>
                            </tr>
                    <tbody *ngIf="bookingArr && bookingArr[0] && bookingArr[0]['json_doc']['travellers'] && mergedCancelledSegmentFare && travellerFareOfCancelledSeg">
            
                        <tr *ngFor="let trav of bookingArr[0]['json_doc']['travellers']; let i = index;"> 
                            <td>{{i+1}}.</td>
                             <td>{{trav.ticket.number}}</td>
                              <td>{{trav.first_name}} {{trav.last_name}}</td>
                             <td>{{trav.type}}</td>
                             <td class="text-right">₹{{travellerFareOfCancelledSeg[i].base  |number:'1.2-2'}}</td>
                             <td class="text-right">₹{{travellerFareOfCancelledSeg[i].YQ | number :'1.2-2'}}</td>
                             <td class="text-right">₹{{travellerFareOfCancelledSeg[i].YR | number :'1.2-2'}}</td>
                             <td class="text-right">₹{{travellerFareOfCancelledSeg[i].K3 | number :'1.2-2'}}</td>
                             <td class="text-right">₹{{travellerFareOfCancelledSeg[i].OC | number :'1.2-2'}}</td>
                             <td class="text-right">₹{{travellerFareOfCancelledSeg[i].OT | number :'1.2-2'}}</td>
                             <td class="text-right">₹{{travellerFareOfCancelledSeg[i].ssr.seat | number :'1.2-2'}}</td>
                             <td class="text-right">₹{{travellerFareOfCancelledSeg[i].ssr.meal | number :'1.2-2'}}</td>
                             <td class="text-right">₹{{travellerFareOfCancelledSeg[i].ssr.baggage | number :'1.2-2'}}</td>
                             <td class="text-right">₹{{travellerFareOfCancelledSeg[i].ssr.additionalBaggage | number :'1.2-2'}}</td>
                             <td class="text-right">₹{{travellerFareOfCancelledSeg[i].ssr.ancillary | number :'1.2-2'}}</td>
                             <td class="text-right">₹{{travellerFareOfCancelledSeg[i].total | number:'1.2-2' }}</td>

                     </tr>
                     <!-- <tr> 
                        <td></td>
                         <td></td>
                          <td></td>
                         <td></td>
                         <td class="text-right">₹{{mergedCancelledSegmentFare.base}}</td>
                         <td class="text-right">₹{{mergedCancelledSegmentFare.YQ}}</td>
                         <td class="text-right">₹{{mergedCancelledSegmentFare.YR}}</td>
                         <td class="text-right">₹{{mergedCancelledSegmentFare.K3}}</td>
                         <td class="text-right">₹{{mergedCancelledSegmentFare.OC}}</td>
                         <td class="text-right">₹{{mergedCancelledSegmentFare.OT}}</td>
                         <td class="text-right">₹0.00</td>
                         <td class="text-right">₹0.00</td>
                         <td class="text-right">₹0.00</td>
                         <td class="text-right">₹{{mergedCancelledSegmentFare.total}}</td>

                 </tr> -->

                    </tbody>
                    </table>
                    <div class="row ml-0 mr-0" *ngIf="previewRefund && previewRefund['status'] == 'PREVIEW_WITHOUT_CANCELLATION'">
                        <div class="col-sm-8 pl-0">
                            <div class="narration-height"></div>
                            <div class="grand-total refund-amt d-flex justify-content-between align-items-center">
                                <h3 class="m-0 text-uppercase">REFUND AMT. <small>(IN WORDS)</small></h3>
                                <!-- <h3 class="m-0 text-uppercase">FIVE THOUSAND SEVEN HUNDRED AND SEVENTY FIVE RUPEE ONLY</h3> -->
                                <h3 class="m-0 text-uppercase">{{previewRefund.refundedAmount | numberToWords}} ONLY</h3>
                            </div>
                        </div>
                        <div class="col-sm-4 pr-0 pl-0" >
                            <div class="d-flex amount-items justify-content-between align-items-center">
                                <h4 class="m-0">Total
                                </h4>
                                <h4 class="mt-0 mb-0" style="    margin-right: 10px!important;">₹ {{mergedCancelledSegmentFare.total  | number :'1.2-2'}}
                                </h4>
                            </div>
                            <div class="d-flex amount-items justify-content-between align-items-center">
                                <h4 class="m-0">Cancellation Charges
                                </h4>
                                <h4 class="mt-0 mb-0" style="    margin-right: 10px!important;">₹ {{previewRefund.client['XXL'] | number :'1.2-2'}}
                                </h4>
                            </div>
                            <div class="d-flex amount-items justify-content-between align-items-center">
                                <h4 class="m-0">RAF Charges
                                </h4>
                                <h4 class="mt-0 mb-0" style="    margin-right: 10px!important;">₹ {{previewRefund.rafCharges | number :'1.2-2'}}
                                </h4>
                            </div>
                            <div class="d-flex amount-items justify-content-between align-items-center">
                                <h4 class="m-0">GST (on RAF)
                                </h4>
                                <h4 class="mt-0 mb-0" style="    margin-right: 10px!important;">₹ {{previewRefund.gstOnRaf | number :'1.2-2'}}
                                </h4>
                            </div>
                            <div class="d-flex amount-items justify-content-between align-items-center">
                                <h4 class="m-0">COMMISSION
                                </h4>
                                <h4 class="mt-0 mb-0" style="    margin-right: 10px!important;">₹ {{(previewRefund.fareBreakup?previewRefund.fareBreakup.commission_paid:0) | number:'1.2-2'}}
                                </h4>
                            </div>

                            <div class="d-flex amount-items justify-content-between align-items-center">
                                <h4 class="m-0">TDS
                                </h4>
                                <h4 class="mt-0 mb-0" style="    margin-right: 10px!important;">₹ {{(previewRefund.fareBreakup?previewRefund.fareBreakup.tds_recieved:0) |number:'1.2-2'}}
                                </h4>
                            </div>
                            <div class="d-flex amount-items justify-content-between align-items-center">
                                <h4 class="m-0"> Round Off
                                </h4>
                                <h4 class="mt-0 mb-0" style="    margin-right: 10px!important;">₹ {{previewRefund.refundAmtRoundOff | number:'1.2-2'}}
                                </h4>
                            </div>
                            <div class="d-flex amount-items refund-amt justify-content-between align-items-center" style="position: sticky; bottom: 0;">
                                <h4 class="m-0 text-green">REFUND AMT. 
                                </h4>
                                <h4 class="mt-0 mb-0 text-green" style="    margin-right: 10px!important;"> ₹ {{previewRefund.refundedAmount | number}}
                                </h4>
                            </div>
                            <!-- <div class="d-flex amount-items new-grand-total justify-content-between align-items-center">
                                <h4 class="m-0 text-uppercase">NEW GRAND TOTAL  
                                </h4>
                                <h4 class="mt-0 mb-0" >₹15,000.00
                                </h4>
                            </div> -->
                        </div>
                       
                    </div>
               </div>
                </div>
            </div>
            <div class="flex-dir-column modal-footer justify-content-center" *ngIf="cancelSectorForm">

                <div  *ngIf="splitSectorModal2" class="d-flex align-items-center mb-2">
                    <input  type="checkbox" name="" id="confirmSectorCancellation" (change)="confirmSectorCancellation($event)">
                    <label for="confirmSectorCancellation" style="color: red; margin-right: 10px;"> &nbsp; After your confirmation tickets will be cancelled at airline backend and cancellation charges will apply!</label>
                </div>
              
               <div class="d-flex">
                <button type="button" class="btn re-issue mr-3" 
                [ngClass]="isConfirmationChecked ? 'active' : 'inactive'" *ngIf="splitSectorModal2"
                (click)="submitCancelSectorRequest()"  [disabled]="!sectorCancellationConfirmation">Submit Request</button>
        
                <button *ngIf="splitSectorModal1"  (click)="moveSectorStep2()" type="button" class="btn re-issue   mr-3"
                [disabled]="getRequestedSegmentCount()==0">Preview Refund</button>

                <!-- <button  (click)="submitSplitPnrRequest()" *ngIf="splitTravellersList3" type="button" class="btn re-issue  btn-custom"
                >Re-Issue Preview Refund</button> -->
                <button (click)="resetSectorModal()"  #closeSplitPnrModal data-dismiss="modal" aria-label="Close"  type="button"  class="btn cancel-ticket  "
                > Close</button>
               </div>

            </div>
        </div>
       

    </div>
</div>



<!-- Additional Markup Modal -->

<button type="button" #openMessageModal hidden class="btn btn-info btn-lg d-none"  data-backdrop="static" data-toggle="modal" data-target="#messageModal"></button>

<div id="messageModal" class="modal fade" role="dialog">
    <div class="modal-dialog modal-xl  modal-dialog-centered" >
        <div class="modal-content w-50" style="margin: 0 auto">
            <div class="modal-header" style="border: none;">
                <button type="button" class="close" #closeMessageModal data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <!-- <div style="text-align: center;margin: 20px;">
                <img src="assets/images/green-tick.png" alt="" srcset="" width="50px" height="50px">
            </div> -->
           <div class="text-center" style="    padding-left: 20px;padding-right: 20px;padding-bottom: 20px;
           text-align: center;">
            <h5 class="shadow" style="text-align: center;
            font-size: 16px;
            font-weight: 600;
            border-left: 10px solid #4caf50;
            color: #377539;
            padding-left: 20px;
            padding: 20px;background:
            #00800008;" 
            *ngIf='apiSuccessMessage'>{{apiSuccessMessage}}</h5>
            <h5 class="shadow" style="text-align: center;font-size: 16px;font-weight: 600;color: red;border-left: 10px solid red;padding-left: 20px;padding: 20px;background: #ff110012;" *ngIf='apiErrorMessage'>{{apiErrorMessage}}</h5>
            <p style="padding: 10px;
                background: #f1f1f1;
                margin-top: 16px;" *ngIf="previewRefund && previewRefund.errorMessage && showErrorMessage">
                {{previewRefund.errorMessage}}
            </p>
            <div class="d-flex justify-content-center align-items-center">
                <button type="button"  class="btn btn-success btn-sm re-issue mr-2" style="width: max-content; font-weight: 600;" (click) = "toggleErrorMessage()" *ngIf="previewRefund && previewRefund.errorMessage">View Detail</button>
             <button  #closeMessageModal data-dismiss="modal" aria-label="Close"  type="button"  class="btn btn-danger btn-sm re-issue" style="width: max-content; font-weight: 600;">Close</button>
            </div>
           </div>

            </div> 
        </div>
       

</div>

    <button type="button" #openAddonMarkupModel hidden class="btn btn-info btn-lg d-none"  data-backdrop="static" data-toggle="modal" data-target="#addonMarkupModel"></button>

    <div id="addonMarkupModel"  class="modal fade" role="dialog">
        <div class="modal-dialog modal-xl custom-modal modal-dialog-centered" >
            <div class="modal-content" style="margin: 0 auto">
                <form>
                <div class="modal-header" style="border: none;">
                    <button type="button" class="close" #closeAddonMarkupModel data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true" (click)="resetAdonMarkupForm()">&times;</span>
                    </button>
                </div> 
                <div class="modal-header-box">
                    <div class="img-icon">
                         <img src="../../../assets/images/notification.png" alt="" srcset="">
                    </div>
                    <h4>Wait</h4>
                 </div>
                <div class="modal-body pt-4">
                    <h4 style="font-size:20px;" class="text-center">Additional markup noticed in previous transaction. Would you like to edit? </h4>
                    <!-- <span style="background: #ffffff; padding: 5 px 20 px ; border-radius: 5px ; margin-top: 10px ; display: inline-block; font-size: 16px; color: #ff8100;"> Previous markup <b>{{affiliateOldAdonMarkup}}</b></span>  -->
                    
                    <form  [formGroup]="searchForm" #form="ngForm" class="mt-2">
                        <label for="" class="text-center" >Edit Markup</label>
                      <input  formControlName="newAddonMarkup" id="addonMarkup" type="number" placeholder="Enter Amount" />
                   </form>   
                </div>
                </form>
             
                <div class="modal-footer d-flex justify-content-center align-items-center">
                    <button #closeAddonMarkupModel  data-dismiss="modal" type="button"  class="btn btn-danger btn-large mr-3"   (click) = "issueTicketWithoutMarkup(searchForm.value)"><h5>NO</h5> <small>Issue Ticket <br>Without Markup</small></button>
                    <button  #closeAddonMarkupModel data-dismiss="modal" aria-label="Close"  type="button"  class="btn btn-success btn-large "   (click)="issueTicketWithMarkup(searchForm.value)"><h5>YES</h5> <small>Issue Ticket <br> With Markup</small></button>
                </div>
            </div>
        </div> 
    </div>

<button type="button" #openFareRuleModal hidden class="btn btn-info btn-lg d-none"  data-backdrop="static" data-toggle="modal" data-target="#fareRuleModal"></button>

<div id="fareRuleModal" class="modal mt-2" role="dialog">
    <div class="modal-dialog modal-xl" >
        <div class="modal-content" style="margin: 0 auto">
            <div class="modal-header" style="border: none;">
                <button type="button" class="close" #closeFareRuleModal data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <ng-container *ngIf="fareRules;else noFareRules" class="modal-dialog modal-xl  modal-dialog-centered" style="margin: 0 auto">
                <ng-container class="modal-content" *ngIf="fareRules['fareRule']">
                    <h3 class="mx-auto my-5 scroll text-center"  *ngIf="isString(fareRules['fareRule']);else structuredFareRule">
                        <div class="fare-rule" style="font-size:15px;" [innerHTML]="fareRules['fareRule']"></div>
                    </h3>
                    <ng-template #structuredFareRule>
                        <h4 class="text-center" *ngIf="bookingArr && bookingArr.length > 0 && bookingArr[0].json_doc && bookingArr[0].json_doc.fare_type">
                            {{bookingArr[0].json_doc.fare_type}} Fare Rules:
                        </h4>          
                        <div class="amedius-fare-rule-area ml-3 mr-3 scroll">
                           <div class="fare-rules-area" *ngIf="bookingArr && bookingArr.length>0 && bookingArr[0].json_doc && bookingArr[0].json_doc.fare_type">   
                                <div class="" >
                                   <div id="{{bookingArr[0].json_doc.fare_type}}" class="tab-pane fadein show active">                                
                                       <ng-container *ngIf="!showLegacyFareRule">
                                            <div *ngFor="let obj of fareRules['fareRule']">
                                                <h5 class="sector"><span>{{obj.seg}}</span></h5>
                                                <div class="fare-rule-section" *ngFor="let item of obj.fareRule | keyvalue">
                                                    <h5>#<span>{{item.key}}</span></h5>
                                                    <table>
                                                        <tbody>
                                                            <tr *ngFor="let fareRuleArr of item.value">
                                                                <td *ngFor="let fareRule of fareRuleArr">
                                                                    <span>{{fareRule}}</span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </ng-container>
                                        
                                        <ng-container *ngIf="showLegacyFareRule && fareRules['legacy']" >
                                           <mat-accordion>
                                            <div class="parent-card-tab"  *ngFor="let item of fareRules['legacy'] | keyvalue;let lfrIndex = index;">
                                                <mat-expansion-panel class="relative m-0" [expanded]="parentIndex === lfrIndex">
                                                    <mat-expansion-panel-header >
                                                        <mat-panel-title class="parent-header-title">
                                                            <ng-container *ngIf="item.key">
                                                                Sector : {{item.key}}
                                                            </ng-container>
                                                            <ng-container *ngIf="item.value.fareDetails">
                                                                <ng-container *ngIf="item.value.fareDetails['fareBasis']">
                                                                   | Fare Basis :{{item.value.fareDetails['fareBasis']}}
                                                                </ng-container>
                                                                <ng-container *ngIf="item.value.fareDetails['bookingClass']">
                                                                   | Booking Class :{{item.value.fareDetails['bookingClass']}}
                                                                </ng-container>
                                                            </ng-container>
                                                        </mat-panel-title>
                                                    </mat-expansion-panel-header>
                                                    <div class="mt-3">
                                                        <mat-accordion>
                                                            <div class="card-tab" *ngFor="let legacyRule of item.value.fareRule">
                                                                <mat-expansion-panel class="relative m-0" >
                                                                    <mat-expansion-panel-header >
                                                                        <mat-panel-title class="child-header-title">
                                                                            {{legacyRule.fareRuleCategoryName}} {{legacyRule.fareRuleCategoryId}}
                                                                        </mat-panel-title>
                                                                    </mat-expansion-panel-header>
                                                                    <div>
                                                                        <pre [innerHTML]="legacyRule.fareRule">
                                                                        </pre>
                                                                    </div>
                                                                    <div style="clear: both;"></div>
                                                                </mat-expansion-panel>
                                                            </div>
                                                        </mat-accordion>
                                                    </div>
                                                    <div style="clear: both;"></div>
                                                </mat-expansion-panel>
                                            </div>
                                        </mat-accordion>
                                        </ng-container>
                                    </div>
                                </div>
                           </div>
                       </div>
                       <div class="d-flex justify-content-center" *ngIf="fareRules['fareRule'] && fareRules['fareRule'].length > 0;else noFareRule">
                            <button class="btn btn-green btn-sm my-1" type="button" (click)="showLegacyFareRule = !showLegacyFareRule" *ngIf="fareRules['legacy']"> Show {{showLegacyFareRule ? 'Mini' : 'Legacy'}} fare rule</button>
                       </div>
                        <ng-template #noFareRule>
                            No Fare Rule Found
                        </ng-template>
                    </ng-template>
                </ng-container>
            </ng-container>
            <ng-template #noFareRules>
                <h3 class="text-center">
                    No Fare Rule Found
                </h3>
            </ng-template>
        </div>
       
    </div>
</div>

<button type="button" hidden #showPriceChange class="btn btn-primary btn-lg" data-toggle="modal"
    data-target="#priceChangedModal">
</button>

<div id="priceChangedModal" class="modal fade" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Things move quite fast in this biz. Fares are changed!</h4>
                <button type="button" class="close" #closeBtn data-dismiss="modal">&times;</button>

            </div>
            <div class="modal-body">
                <div class="fare-info">
                    <span class="fare-txt"><i class="fas fa-tags mr-2"></i>Fare Information</span>

                    <span class="flex-fare-col">
                        <span class="was-fare-col" *ngIf="priceChangedAmountObj && priceChangedAmountObj.oldTtotalNetPayable">
                            <span class="of-txt">was</span>
                            <span class="fare-amt old-fare">&#8377; {{priceChangedAmountObj.oldTtotalNetPayable}}</span>
                        </span>

                        <span class="divider"></span>

                        <span class="now-fare-col" *ngIf="priceChangedAmountObj && priceChangedAmountObj.netTotalayable">
                            <span style="color: #ed5437;" class="of-txt">now</span>
                            <span class="fare-amt new-fare">&#8377; {{priceChangedAmountObj.netTotalayable}}</span>
                        </span>
                    </span>

                </div>
            </div>
            <div class="modal-footer float-center">
                <button type="button" class="popup-btn modify-btn inactive" 
                    [ngClass]="{'btn-disable': !isReAttemptSearchEnabled}" 
                    [disabled]="!isReAttemptSearchEnabled" 
                    [title]="!isReAttemptSearchEnabled ? 'Not Allowed' : 'Search Again' " 
                    routerLink="" 
                    data-dismiss="modal">Modify Flights</button>
                <button type="button" class="popup-btn accept-btn" data-dismiss="modal" (click)="reAttemptBooking()">Accept & Continue</button>
            </div>
        </div>

    </div>
</div>    
<div id="snackbar">{{copyFor}} copied..</div>
