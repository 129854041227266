import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
@Component({
	selector: 'app-sidemenu',
	templateUrl: './sidemenu.component.html',
	styleUrls: ['./sidemenu.component.css']
})
export class SidemenuComponent implements OnInit {
	menuStatus: boolean = false;
	menuSubscription: Subscription;
	environmentName: string;
	isWhiteLabel: boolean = false;
	isGroupFareEnabled: boolean;
	constructor(private commonService: CommonService) { }

	ngOnInit() {
		this.menuStatus = false
		this.menuSubscription = this.commonService.viewMenuStatus.subscribe(data => {
			this.environmentName = environment.name
			this.menuStatus = data;
		})
		let loginUserDetails = localStorage.user_details && JSON.parse(localStorage.user_details);
		if(loginUserDetails && loginUserDetails['enabled_group_fare'] == "true") {
			this.isGroupFareEnabled = true;
		}
		if(loginUserDetails && loginUserDetails['is_whitelabel'] == 'true') {
		  this.isWhiteLabel = true;
		}
		else{
		  this.isWhiteLabel = false;
		}
	}
	ngOnDestroy() {
		this.menuSubscription.unsubscribe();
	}
}

