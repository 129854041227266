import { Injectable } from '@angular/core';
import { CommonService } from '../common.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private commonService: CommonService) { }

  createUser(data) {
		return this.commonService.commonPostApi('tenant_user/create', data);
	}

  signUpformUser(data) {
		return this.commonService.commonPostApi('tenant_user/signup', data);
	}

  loginUser(data) {
		return this.commonService.commonPostApiWithoutLoader('tenant_user/login', data);
	}

  promoCodes() {
		return this.commonService.commonGetApi('discount');
  }	

  applyPromoCodes(data) {
		return this.commonService.commonPostApi('discount/apply', data);
  }	



}
