import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { AffiliateService } from 'src/app/services/affiliate.service';
import { BookingService } from 'src/app/services/booking/booking.service';
import { MatTabChangeEvent } from '@angular/material';
import { PostService } from 'src/app/services/post.service';
import { BookingService as BookingRequest } from './../traveller/booking.service';
import { environment } from '../../../environments/environment'
import { TravellerService } from 'src/app/services/traveller/traveller.service';
import { BookingRequestService } from 'src/app/services/booking-request.service';
import { ExcelService } from 'src/app/services/excel.service';
import { CalculatePriceService } from 'src/app/services/calculate-price.service';
import { AngularFireDatabase, AngularFireObject } from "@angular/fire/database";
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-re-attempt-ticket',
  templateUrl: './re-attempt-ticket.component.html',
  styleUrls: ['./re-attempt-ticket.component.scss']
})
export class ReAttemptTicketComponent implements OnInit {
	environment = environment
 
	@ViewChild('closeactiveBtn') closeactiveBtn: ElementRef;

	seatCharges = 0;
	mealCharges =0;
	baggageCharges=0;
	
	selectedGST : any

	active = false;
	color = 'primary';
	mode = 'indeterminate';
	value = 50;
	travellerForm: FormGroup;
	myDepControl = new FormControl();
	panelOpenState = false;
	departure: any;
	arrival: any;
	departureInboud: any;
	arrivalInbound: any;
	date;
	dateInbound;
	total;
	isOffer = false;
	netPayable: number = 0;
	// selectedFare;
	selectedFares;
	baseFare: any;
	netCommission: number = 0;
	totalFare: number = 0;
	adultBaseFare: number = 0;
	adultTotalFare: number = 0;
	legs = [];
	Inboundlegs = [];
	airlineCode: any;
	adultTaxFare: number = 0;
	childBaseFare: number = 0;
	childTotalFare: number = 0;
	totaltax: number = 0;
	arrivalDate: string;
	departureDate: string;
	departureTime: string;
	arrivalTime: string;
	isDomestic: boolean = true;
	passengerList: FormArray;
	nationalityArr: any = [];
	childTaxFare: number = 0;
	noOfAdult: any;
	noOfChild: any;
	noOfInfant: any;
	tds: number = 0;
	creditDetail: any = [];
	creditLimit: string;
	invalidCredit: boolean = false;
	inBoundCreditLimit;
	inBountInvalidCredit: boolean = false;
	passengerDetails: any;
	isSpiceJet: boolean = false;
	totalPassengerCount: number = 0;
	totalPaxArr: any = [];
	openTravellerTab: boolean = false;
	infBaseFare: number = 0;
	infTotalFare: number = 0;
	infTaxFare: number = 0;
	baggageArr: any = [];
	noOfAdultInbound: any;
	noOfChildInbound: any;
	noOfInfantInbound: any;
	tdsInbound: number = 0;
	netPayableInbound: number = 0;
	selectedFaresInbound;
	baseFareInbound: any;
	netCommissionInbound: number = 0;
	totalFareInbound: any;
	adultBaseFareInbound: any;
	adultTotalFareInbound: any;
	adultTaxFareInbound: number = 0;
	childBaseFareInbound: any;
	childTotalFareInbound: any;
	childTaxFareInbound: number = 0;
	totaltaxInbound: any;
	totalPassengerCountInbound: number = 0;
	totalPaxArrInbound: any = [];
	totalInbound;
	arrivalDateInbound: string;
	departureDateInbound: string;
	departureTimeInbound: string;
	arrivalTimeInbound: string;
	totalBookingPrice: number = 0;
	airlineCodeInbound: any;
	step: number = 0;
	infBaseFareInbound: any;
	infTotalFareInbound: any;
	infTaxFareInbound: number = 0;
	sectorArr: any = [];
	sectorInboundArr: any = [];
	adminMarkup: number = 0;
	affMarkUp: number = 0;
	adminInboundMarkup: number = 0;
	affInboundMarkUp: number = 0;
	dummyAdultNameArr: any = [];
	selectedBaggage: any = [];
	showBaggage: boolean = false;
	gstArr: any = [];
	myGstControl = new FormControl();
	outboundSeatMap: any = [];
	inboundSeatMap: any = [];
	showseatloadError: boolean;
	flightRequest: any;
	InwardflightRequest: any;
	isLCC: boolean = false;
	inInBoundLCC: boolean = false;
	outBoundTicketIssueStatus: boolean = false;
	inboundTicketIssueStatus: boolean = false;
	request;
	OnWardsBookingResJson;
	supplierName: string;
	inWardSupplierName: string;
	ssrAvailability=[];
	showloadError: boolean;
	seatMapArr = [];
	InBoundSSRAvailability: any[];

	netTotalayable = 0;
	inBoundNetTotalayable = 0;
	holdTicket;

	firbaseData = [];
	offerSubscription: Subscription;
	allgstArr: any = [];
	airlineArr: any;

	minDate: Date = new Date();

	paxSubmitted: boolean = false;
	contactSubmitted: boolean = false;
	affiliateMarkup: number;
	isDiAccount: boolean;
	limitType: any;

	paymentIndex = 0
	currentPaymentAmount = 0
	warningMessage: string;
	isSME: boolean = false;
	fareData = [];
	
	
	selectedPaymentMethod = [];

	@ViewChild('showPaymentInfo') showPaymentInfo: ElementRef;
	onlinePaymentAmount=[];
	onlinePaymentCharge=[];
	selectedFlightDirectionForPayment: string;
	errorMessage: string;
	isResponse: boolean;
	paymentStatusResponse: any;
	affiliateDetails: any;
	originalNetPayable=[];
	canHold=[];
	
	@ViewChild('showDuplicateBooking') showDuplicateBooking: ElementRef;
	duplicateBookingMsg: any;
	disableBtn: boolean;
	bookingId: any;
	valid: boolean;
	bookingData: any;
	selectedBaggageArr: any;
	selectedMealArr: any;
	selectedSeatArr: any;
	hasFreeBaggage: boolean;
	passengerArr = []

	constructor(private router: Router,
		private commonService: CommonService,
		private fb: FormBuilder,
		private affiliateService: AffiliateService,
		private bookingService: BookingService,
		private bookingServiceRequest: BookingRequest,
		private postService: PostService,
		private returnBookingService: BookingRequest,
		private bookingRequestService: BookingRequestService,
		private travellerService: TravellerService,
		private excelServc: ExcelService,
		private db: AngularFireDatabase,
		private calculatePriceService: CalculatePriceService,
		private activatedRoute: ActivatedRoute) {
	
	}
	data: any = [];
	InboundData: any = [];
	passengers = [1, 2, 3]
	ngOnInit() {
		window.scroll(0, 0);
		// this.selectedPaymentMethod = {
		// 	'outbound' : "agent-balance",
		// 	'inbound' : "agent-balance"
		// }
		// this.onlinePaymentAmount = {
		// 	'outbound' : 0,
		// 	'inbound' : 0
		// }
		// this.onlinePaymentCharge = {
		// 	'outbound' : 0,
		// 	'inbound' : 0
		// }
		// this.originalNetPayable = {
		// 	'outbound' : 0,
		// 	'inbound' : 0
		// }
		this.affiliateDetails = JSON.parse(localStorage.user_details)
		if (!this.affiliateDetails.hasOwnProperty('emulated_by')) {
			this.router.navigateByUrl('/')
		}
		this.travellerForm = this.fb.group({
			"corporateGstNumber": ['', Validators.pattern("^([0][1-9]|[1-2][0-9]|[3][0-7])([A-Z]{5})([0-9]{4})([A-Z]{1}[1-9A-Z]{1})([Z]{1})([0-9A-Z]{1})+$")],
			"corporateName": [''],
			"corporateMobile": ['', [Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(10)]],
			"corporateEmail": [''],
			'additionalComments': [''],
			"passengerList": this.fb.array([]),
			"email": [Validators.required],
			"phone": ['', [Validators.maxLength(10), Validators.minLength(10), Validators.pattern('^[0-9]*$')]],
			"mobile": [Validators.required, [Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(10)]],
			"paxDetail": this.fb.array([])
		})

		this.bookingRequestService.getLimit.subscribe(res => {
			this.creditLimit = res['creditLimit'];
			this.invalidCredit = res['invalidCredit'];
			this.limitType = res['type'];
		})

		//
		if(localStorage.user_details != undefined) {
			if(JSON.parse(localStorage.user_details)['is_di_account'] != null) {
				this.isDiAccount = (JSON.parse(localStorage.user_details)['is_di_account'] == 'true')
			}
			else{
				this.isDiAccount = false;
			}
		}
		if(localStorage.user_details != undefined) {
			this.affiliateDetails = JSON.parse(localStorage.user_details)
		}else{
			this.affiliateDetails = null
		}
		// this.getCreditLimit();	// get credit limit of affiliate
    this.getNationality();	// bind countries into passport issuing country and nationality
    this.bookingId = this.activatedRoute.snapshot.params['bookingId'];
		this.makeOutboundData(this.bookingId); // bind outbound data

		

		this.commonService.getAirline(`airline`).subscribe(res => {
			this.airlineArr = JSON.parse(res['_body']);
			// console.log('Airline MAster', this.airlineArr)
		});

		var url = window.location.href.slice(window.location.href.indexOf('?') + 1);
		this.checkPayment(url)
		
	}
	checkPayment(url){
		if (url.includes("paymentIndex=1")) {	
			this.active = true
			this.activatedRoute.queryParams.subscribe(params => {	
				let amount = params['amount'];
				let orderID = params['orderId'];
				let transId = params['referenceNumber'];
				let hasKey = params['key'];
	
				var formValue = JSON.parse(localStorage.getItem('formValue'));
				setTimeout(() => {
					this.setFormValue(formValue)
				}, 3000);
				if (!(location.href.includes('error=true')) && formValue != null) {
					let obj = {
						"orderId" : orderID,
						"trackingId" : transId,
						"amount" : amount,
						"key" : hasKey,
						"status" : "success"
					}	
					formValue['chequeNumber'] = orderID;
					formValue['transactionRef'] = transId;
					let selectedFlightIndexForPayment = localStorage.getItem('selectedFlightIndexForPayment')
					if (!params.hasOwnProperty('ft-cash')) {
						this.commonService.hdfcPayment(obj, 'getPaymentStatus').subscribe(data => {
							if (data['_body'] != "") {
								let response = JSON.parse(data['_body'])
								if (response.length > 0) {
									this.isResponse = true;
									this.paymentStatusResponse = response[0].json_doc_response;
									window.history.replaceState({}, document.title, "/#/pages/" + "multi-city-lcc-traveller");
									if (selectedFlightIndexForPayment != null) {
										this.doBooking(formValue, false, selectedFlightIndexForPayment)
									}
								}
								else {
									this.isResponse = false;
									console.log('Failed Payment')
								}


							}
							else {
								this.router.navigate([`error?paymentIndex=0&error=true&referenceNumber=${transId}&orderId=${orderID}&amount=${amount}&key=${hasKey}`]);
								this.errorMessage = "Sorry ! Unable to process now, please try again.";
								console.log(this.errorMessage)
							}
						}, error => {
							console.log(error)
						})
					} else {
						console.log('Booking with ft-Cash')
						window.history.replaceState({}, document.title, "/#/pages/" + "multi-city-lcc-traveller");
						this.doBooking(formValue, false, selectedFlightIndexForPayment)
					}
					
				}
				else {
					this.router.navigate([`error?paymentIndex=0&error=true&referenceNumber=${transId}&orderId=${orderID}&amount=${amount}&key=${hasKey}`]);
					this.errorMessage = "Sorry ! Unable to process now, please try again.";
					console.log(this.errorMessage)
				}
				console.log(params); // Print the parameter to the console. 
			});																																																																																																																																																																																																																																																																				
		}
	}
	setFormValue(formValue){
		console.log(formValue)
		  
		if (formValue.gstDetail!= null && formValue.gstDetail != undefined) {
			this.travellerForm.controls.corporateName.setValue(formValue.gstDetail.companyName );
			this.travellerForm.controls.corporateGstNumber.setValue(formValue.gstDetail.gstNumber);
			this.travellerForm.controls.corporateMobile.setValue(formValue.gstDetail.phone);
			this.travellerForm.controls.corporateEmail.setValue(formValue.gstDetail.email);
		}
		this.travellerForm.controls.email.setValue(formValue.contactdetails.email);
		this.travellerForm.controls.mobile.setValue(formValue.contactdetails.phone);
		for (let i = 0; i < this.bookingData.travellers.length; i++) {
			const values = this.bookingData.travellers[i];
			this.travellerForm.controls.passengerList['controls'][i].controls.firstName.setValue(values.first_name);
			this.travellerForm.controls.passengerList['controls'][i].controls.lastName.setValue(values.last_name);
			this.travellerForm.controls.passengerList['controls'][i].controls.title.setValue(values.title);
			this.setGender(values.title, i)
			// this.travellerForm.controls.passengerList['controls'][i].controls.gender.setValue(values.gender);
			if (!this.isDomestic) {
				if (values.passport != null) {
					this.travellerForm.controls.passengerList['controls'][i].controls.passportNo.setValue(values.passport.number);
					this.travellerForm.controls.passengerList['controls'][i].controls.nationality.setValue(values.passport.issuing_country_name);
					this.travellerForm.controls.passengerList['controls'][i].controls.issuingCountry.setValue(values.passport.issuing_country_code);
					if (values.passport.expiry_date != null && values.passport.expiry_date != undefined) {
						this.travellerForm.controls.passengerList['controls'][i].controls.expiryDate.setValue(new Date(values.passport.expiry_date));
					}
				}
				this.travellerForm.controls.passengerList['controls'][i].controls.nationality.setValidators(null);
				if (values.date_of_birth != "") {
					this.travellerForm.controls.passengerList['controls'][i].controls.dob.setValue(new Date(values.date_of_birth));
				}
			}
			this.travellerForm.controls.passengerList['controls'][i].controls.travellerId.setValue(i);
		}
	}
	setPersonalDetails(){
		let user_details = JSON.parse(localStorage.user_details)
		if (!this.travellerForm.controls.email.touched) {
			this.travellerForm.controls.email.setValue(user_details.primaryEmail)
		}
		if (this.travellerForm.controls.email.value == null) {
			this.travellerForm.controls.email.setValue(user_details.primaryEmail)
		}
		this.travellerService.viewAllGSTbyid().subscribe(res=>{
			this.allgstArr = res
			if (this.allgstArr.length > 0) {
				// console.log('All Saved GST',this.allgstArr)
			}
		},
		err=>{

		})
	}
	selectGST(item){
		this.selectedGST = {}
		this.selectedGST = item
	}
	getTravellers(val) {
		val = val.currentTarget.value;
		let valueLength  = 3
		if(this.supplierName == 'AMADEUS_V4' || this.supplierName == 'GALILEO'){
		   valueLength = 2;
		}
			if (val.length >= valueLength) {
				this.travellerService.viewAllTravellersForBooking(val).subscribe(res => {
					let data: any
					data = res;
					let uniqueArr = [];
					data.forEach(el => {
						let filteredArr = uniqueArr.filter((v) => {
							if (v.firstname == el.firstname) {
								return v;
							}
						})
						if (filteredArr.length == 0) {
							uniqueArr.push(el)
						}
					});
					this.passengerDetails = uniqueArr;
				})
			}
	}
	public getSeatEvent(data) {
		this.seatCharges = 0;
		data.forEach(v=>{ 
			if(v.seats  != null && v.seats != undefined) {
				v.seats.forEach(el=>{ 
					if(el.price != undefined) {
						this.seatCharges += Number(el.price)
					}
				}) 		
			}
		})
		this.selectedSeatArr = data;
		this.calculateNetPayable(true,0);
	}
	public getBaggageEvent(data) {
		this.baggageCharges = 0;
		data.forEach(v=>{ 
			if(v.baggage  != null && v.baggage != undefined) {
				v.baggage.forEach(el=>{ 
					if(el.price != undefined) {
						this.baggageCharges += Number(el.price)
					}
				}) 
			}
			
			
		})
		this.selectedBaggageArr = data;
		this.calculateNetPayable(true,0);
	}
	public getMealEvent(data) {
		this.mealCharges = 0;
		data.forEach(v=>{ 
			if(v.meals != null && v.meals != undefined) {
				v.meals.forEach(el=>{ 
					if(el.price != undefined) {
						this.mealCharges += Number(el.price)
					}
				}) 
			}
		})
		
		this.selectedMealArr = data;
		this.calculateNetPayable(true,0);
	}
	calculateNetPayable(inbound,i) {
		let totalFare = this.data[i].fares[0].total
		this.netPayable = totalFare;
		this.affiliateMarkup = Math.round(this.data[i].fares[0].fareComponents.AFFILIATE_MARKUP);

		if(this.data[i].fares[0].fareComponents.hasOwnProperty('NET_PAYABLE')) {
			this.netPayable = Math.round(this.data[i].fares[0].fareComponents.NET_PAYABLE);
			this.netPayable = Math.round(this.netPayable+ this.mealCharges+this.seatCharges+this.baggageCharges);
			// this.selectedFare[0].fareComponents.NET_PAYABLE = this.netPayable;
		}
		else{
			this.netPayable = Math.round(this.netPayable+ this.mealCharges+this.seatCharges+this.baggageCharges);

		}
		this.originalNetPayable[i]= this.netPayable
			if (this.selectedPaymentMethod[i] != 'agent-balance') {
				this.onlinePaymentAmount[i] = Math.round(Number(this.netPayable) + (Number(this.netPayable) * Number(environment.payment_gateway_charges)) / 100);
				this.onlinePaymentCharge[i] = Math.round((Number(this.netPayable) * Number(environment.payment_gateway_charges)) / 100);
			}
		this.data[i]['bookingAmount'] = this.netPayable
		this.netTotalayable = Math.round(this.totalFare + this.mealCharges + this.seatCharges + this.baggageCharges);
		totalFare = Math.round(this.data[i].fares[0].total + this.mealCharges + this.seatCharges + this.baggageCharges);
		this.fareData[i]['netTotalayable'] = Math.round(totalFare);
		this.fareData[i]['netPayable'] = this.data[i]['bookingAmount']
		this.data[i].fares[0].total = this.netTotalayable
		this.bookingRequestService.getCreditLimit(this.data[i]['isLCC'], this.netTotalayable, this.affiliateDetails.affiliateId, 'outbound');		
		this.totalBookingPrice =  this.netTotalayable;

	}
	refreshCreditLimit(i){
		this.bookingRequestService.getCreditLimit(this.data[i]['isLCC'], this.netTotalayable, this.affiliateDetails.affiliateId, 'outbound');		
	}
	ngOnDestroy() {
	}

	// get controls value of passengerlist formarray
	get DynamicFormControls() {
		return <FormArray>this.travellerForm.get('passengerList');
	}
	// function to create passengerlist formarray object for traveller form
	buildItem(passengerType,i) {
	
		return this.fb.group({
			"title": ['', Validators.required],
			"firstName": ['', Validators.required],
			'lastName': ['', Validators.required],
			'gender': ['', Validators.required],
			'dob': [''],
			'passportNo': [''],
			'nationality': [''],
			'issuingCountry': [''],
			'associateInfo': [''],
			'expiryDate': [''],
			'passengerType': [passengerType],
			'frequentflyers': [''],
			'frequentflyersNumber': [''],
			'specialServices': [''],
			'mealServices': [''],
			'seatPrefences': [''],
			'baggageServices': [''],
			'travellerId': [''],
		})
	}
	setValidator(i){
		if (!this.isDomestic) {
			this.travellerForm.controls.passengerList['controls'][i].controls.dob.setValidators([Validators.required]);
		}else{
			this.travellerForm.controls.passengerList['controls'][i].controls.dob.setValidators(null);
		}
		this.travellerForm.controls.passengerList['controls'][i].controls.dob.updateValueAndValidity();
	}
	// function to bind pax details 
	bindTraveller(val, param) {
		val = val.currentTarget.value;
		if (val.length >= 3) {
			this.travellerService.viewAllTravellersForBooking(val).subscribe(res => {
				let data: any
				data = res;
				let uniqueArr = [];
				data.forEach(el => {
					let filteredArr = uniqueArr.filter((v) => {
						if (v.firstname == el.firstname) {
							return v;
						}
					})
					if (filteredArr.length == 0) {
						uniqueArr.push(el)
					}
				});
				this.passengerDetails = uniqueArr;
			})
		}
	}
	// fucntion to set pax details for passengers after first name selection
	setPaxDetails(values, i) {
		this.paxSubmitted = false
		this.travellerForm.controls.passengerList['controls'][i].controls.firstName.setValue(values.firstname);
		this.travellerForm.controls.passengerList['controls'][i].controls.lastName.setValue(values.lastname);
		this.travellerForm.controls.passengerList['controls'][i].controls.title.setValue(values.title);
		this.travellerForm.controls.passengerList['controls'][i].controls.gender.setValue(values.gender);
		if (!this.isDomestic) {
			this.travellerForm.controls.passengerList['controls'][i].controls.passportNo.setValue(values.passport_no);
			this.travellerForm.controls.passengerList['controls'][i].controls.nationality.setValue(values.nationality);
			this.travellerForm.controls.passengerList['controls'][i].controls.issuingCountry.setValue(values.issuing_country);
			this.travellerForm.controls.passengerList['controls'][i].controls.expiryDate.setValue(values.expiry_date);
			this.travellerForm.controls.passengerList['controls'][i].controls.dob.setValue(values.dob);
		}
		this.travellerForm.controls.passengerList['controls'][i].controls.seatPrefences.setValue(values.seat_prefences);
		this.travellerForm.controls.passengerList['controls'][i].controls.specialServices.setValue(values.special_services);
		this.travellerForm.controls.passengerList['controls'][i].controls.mealServices.setValue(values.meal_prefences);
		this.travellerForm.controls.passengerList['controls'][i].controls.baggageServices.setValue(values.baggage_services);
		this.travellerForm.controls.passengerList['controls'][i].controls.travellerId.setValue(values.id);
		this.bindDummyAdultArr("")
	}
	// function to get countries list
	getNationality() {
		this.affiliateService.getCountries().subscribe(res => {
			this.nationalityArr =  JSON.parse(res["_body"]);;
			// console.log('counties',res)
		})
	}
	// function to bind traveller first name
	bindTravellerFirstName(e, i, type, passenger) {
		// if (type == 'Infant') {
		// 	this.bindDummyAdultArr(e.target.value)
		// }
		this.travellerForm.controls.passengerList['controls'][i].controls.firstName.setValue(e.target.value);
		if (e.currentTarget.value.length > 2 && e.key == 'Tab') {
			if (passenger.length > 0 && passenger != undefined) {
				this.setPaxDetails(passenger[0], i);
			}
		}
		if (type == 'Adult') {
			this.bindDummyAdultArr(e.target.value)
		}
	}
	setGender(value, i) {
		this.travellerForm.controls.passengerList['controls'][i].controls.gender.setValue('Male');
		if (value == 'Mr') {
			this.travellerForm.controls.passengerList['controls'][i].controls.gender.setValue('Male');
		}
		if (value == 'Mrs') {
			this.travellerForm.controls.passengerList['controls'][i].controls.gender.setValue('Female');
		}
		if (value == 'Ms') {
			this.travellerForm.controls.passengerList['controls'][i].controls.gender.setValue('Female');
		}
	}

	// function to issue ticket for booking request
	async issueTicket(values, isHold,i,forceBooking,checkDuplicate) {
		this.active = true;
		const invalid = [];
		 
		localStorage.setItem('selectedFlightIndexForPayment',i)
		const controls = this.travellerForm.controls;
			for (const name in controls) {
				if (controls[name].invalid && name != "passengerList") {
					invalid.push(name);
				}
			}
			// console.log('invalid field on submit',invalid)
			if(invalid.length  > 0) {
				this.panelOpenState = true;
				this.openTravellerTab = true;
				this.setStep(1)
				this.active =false;
				return false;
			}
		if (this.invalidCredit && !isHold) {
			this.active = false;
			return false
		}
		if(this.isSME) {
			if(values.corporateName == "" || values.corporateEmail == "" || values.corporateGstNumber == "" || values.corporateMobile == "") {
				this.setStep(3)
				this.active =false;
				return false;
			}
		}
		// FIX ME
		// if(this.data[0].priceType == "SPICEMAX") {
		// 	if(this.selectedSeatArr == undefined) {
		// 		this.setStep(4)
		// 		this.active =false;
		// 		this.warningMessage = "Seat is mandatory with spicemax fare";
		// 		return false;
		// 	}
		// 	else if(this.selectedSeatArr[0].seats.length  == 0){
		// 		this.setStep(4)
		// 		this.active =false;
		// 		this.warningMessage = "Seat is mandatory with spicemax fare";
		// 		return false;

		// 	}
		// 	else{
		// 		this.warningMessage = "";
		// 	}
		// }
		// else{
		// 	this.warningMessage = "";
		// }
		values.isHold = isHold;
		values.passengerName = values.passengerList[0].firstName + ' ' + values.passengerList[0].lastName;
		values.bookingDate = new Date();
		if (this.isDomestic == true) {
			values.isDomestic = 'true';
		}
		else {
			values.isDomestic = 'false';
		}
		values.sCode = '';
		values.seats = this.selectedSeatArr;
		values.baggage = this.selectedBaggageArr;
		values.meals = this.selectedMealArr;
		values.xlCode = JSON.parse(localStorage.getItem('user_details'))['xlAccountCode'];
		values.passengerList = this.createPassengerData(values.passengerList, 'TICKET-');	// create passenger/pax data and added dynamic tickets for per pax
		this.data[i]['netPayable'] = this.data[i]['bookingAmount'];  //FIX ME
		localStorage.setItem('formValue',JSON.stringify(values))
		if (checkDuplicate == true) {
			let duplicateBookingRes = {};
			if (environment.enableBooking) {
				duplicateBookingRes = await this.doDuplicateBookingCheck(values, this.data,i); 
			}
			console.log('Duplicate booking check  response', duplicateBookingRes)
			if (duplicateBookingRes.hasOwnProperty('duplicate')) {
				if (duplicateBookingRes['duplicate'] == true) {
					this.duplicateBookingMsg = {
						text: duplicateBookingRes['errorMessage'],
						reference_no: duplicateBookingRes['duplicateReference'],
						direction : i,
						isHold : isHold,
						status : duplicateBookingRes['status']
					}
					this.disableBtn = false
					this.active = false;
					this.openDuplicateBookingModal()
					return false
				} else {
					if (this.selectedPaymentMethod[i] == 'agent-balance') {
						this.doBooking(values,isHold,i)
					}else{
						this.active =false;
						this.selectedFlightDirectionForPayment = i
						this.openPaymentInfoModal()
					}
				}
			}
		}else{
			values['forceBooking'] = forceBooking
			if (forceBooking) {
				values['duplicateReference'] = this.duplicateBookingMsg['reference_no']
			}
			if (this.selectedPaymentMethod[i] == 'agent-balance') {
				this.doBooking(values,isHold,i)
			}else{
				this.active =false;
				this.selectedFlightDirectionForPayment = i
				this.openPaymentInfoModal()
			}

		}
	}
	private openPaymentInfoModal(): void {
		this.showPaymentInfo.nativeElement.click();
	}
	saveTravellers(values) {
		values.userDetails = JSON.parse(localStorage.getItem('user_details'));
		values.travellers = this.travellerForm.controls.passengerList.value;
		for (let i = 0; i < values.travellers.length; i++) {
			const element = values.travellers[i];
			if (i == 0) {
				element['mobile_number'] = this.travellerForm.controls.mobile.value
			}
			else{
				element['mobile_number'] = ''
			}
			this.checkifExist(element)
		}
	}
	checkifExist(values) {
		let tArr;
		this.travellerService.checkifExist(values).subscribe(res => {
			tArr = res
			if (tArr.length == 0) {
				this.travellerService.saveAllTraveller(values).subscribe(res => {
					// console.log('saved', res)
				})
			}
		})
	}

	doDuplicateBookingCheck(value, flightData,i) {
		return new Promise((resolve, reject) => {
			let obj = this.bookingServiceRequest.multiCityBookingRequest(value, flightData, this.limitType,i);
			this.request = obj;
      console.log('Create booking request : ',obj)
			this.postService.postMethod(`${environment.url}/book/checkduplicate`, obj).subscribe(res => {
				
				if (res != "") {
					resolve(res);
				}
				else {
					resolve('');
				}
			}, err => {
				resolve('');
			})

		})
	}


	creatingBookingRequest(value, flightData,i) {
		return new Promise((resolve, reject) => {
			let obj = this.bookingServiceRequest.multiCityBookingRequest(value, flightData, this.limitType,i);
			this.request = obj;
      console.log('Create booking request : ',obj)
			this.postService.postMethod(`${environment.url}/book/test`, obj).subscribe(res => {
				
				if (res != "") {
					resolve(res);
				}
				else {
					resolve('');
				}
			}, err => {
				resolve('');
			})

		})
	}

	// function to view offer
	viewOfffer() {
		if (this.isOffer) {
			this.isOffer = false;
		}
		else {
			this.isOffer = true;
		}
	}

	// traveller form validation
	get email() {
		return this.travellerForm.controls.email;
	}
	get mobile() {
		return this.travellerForm.controls.mobile;
	}
	// create outbound data request
	getFlightData(flight){
		let x = []
			x.push(flight)
			return x
	}
  async	makeOutboundData(bookingId) {
    let retrivedBooking = await this.getBooking(bookingId)
	console.log('Retrieved Data : ',retrivedBooking)
	let retrivedSearchedData = await this.getSearchData(bookingId)
	if (retrivedSearchedData[0] != undefined && retrivedBooking[0] != undefined) {
		this.valid = true;
		this.bookingData = retrivedBooking[0].json_doc
		this.data.push(retrivedSearchedData[0].selected_flight_json_doc)
		// this.flightRequest = JSON.parse(sessionStorage.getItem('ActiveSearchRequest'));
		this.total = this.data[0].selFlightPrice;
		this.totalBookingPrice += this.total;
		
		if (this.bookingData.is_domestic == "true") {
			this.isDomestic = true
		}else{
			this.isDomestic = false
		}

		this.airlineCode = this.data[0].airline.code;
		let segmentLength = this.data[0].segments.length
		let legLength = this.data[0].segments[segmentLength - 1].legs.length
		this.minDate = new Date(this.data[0].segments[segmentLength - 1].legs[legLength-1].departureDateTime)
		let expiry_month = this.minDate.getMonth()+6
		this.minDate = new Date(this.minDate.setMonth(expiry_month))
		
		this.totaltax = (this.totalFare - this.baseFare) + this.adultTaxFare + this.childTaxFare;
		this.departure =this.data[0].segments[0].legs[0].originAirport.code;
		this.arrival = this.data[0].segments[segmentLength - 1].legs[legLength-1].destinationAirport.code
		let d = new Date(this.data[0].segments[segmentLength - 1].legs[legLength-1].departureDateTime);
		this.date = d.toDateString();
		this.noOfAdult = 0;
		this.noOfChild = 0;
		this.noOfInfant = 0;
		this.totalPassengerCount = Number(this.noOfAdult) + Number(this.noOfChild) + Number(this.noOfInfant);
		
		for (let i = 0; i < this.bookingData.travellers.length; i++) {
			const element = this.bookingData.travellers[i];
			this.passengerList = this.travellerForm.get('passengerList') as FormArray;
			if (element.type == 'ADT') {
				this.passengerList.push(this.buildItem('Adult', i));
				this.setValidator(i);
				this.noOfAdult++
			}
			if (element.type == 'CHD') {
				this.passengerList.push(this.buildItem('Child',i));
				this.setValidator(i);
				this.noOfChild++
			}
			if (element.type == 'INF') {
				this.passengerList.push(this.buildItem('Infant', i));
				this.setValidator(i);
				this.noOfInfant++
			}
		}
		for (let i = 0; i < this.data.length; i++) {
			const element = this.data[i];
			this.makeFareObj(i)
		}
		setTimeout(() => {
			this.setFormValue(this.bookingData)
		}, 100);
		// for (let i = 1; i <= this.noOfAdult; i++) {
		// 	this.passengerList = this.travellerForm.get('passengerList') as FormArray;
		// 	this.passengerList.push(this.buildItem('Adult',i));
		// 	this.setValidator(i);
		// }
		// for (let i = 1; i <= this.noOfChild; i++) {
		// 	this.passengerList = this.travellerForm.get('passengerList') as FormArray;
		// 	this.passengerList.push(this.buildItem('Child',i));
		// 	this.setValidator(i);
		// }
		// for (let i = 1; i <= this.noOfInfant; i++) {
		// 	this.passengerList = this.travellerForm.get('passengerList') as FormArray;
		// 	this.passengerList.push(this.buildItem('Infant',i));
		// 	this.setValidator(i);
		// }

		//FIX THIS
		let paxDiscountCode = null;
		if (this.selectedFares[0].supplierInfo != null) {
			paxDiscountCode = this.selectedFares[0].supplierInfo.FareBasisCode;
		}
		if (this.noOfAdult > 0) {
			let obj = {
				"paxCount": this.noOfAdult,
				"paxDiscountCode": paxDiscountCode,
				"paxType": "ADT"
			}
			this.totalPaxArr.push(obj);
		}
		if (this.noOfChild > 0) {
			let obj = {
				"paxCount": this.noOfChild,
				"paxDiscountCode": paxDiscountCode,
				"paxType": "CHD"
			}
			this.totalPaxArr.push(obj);
		}
		if (this.noOfInfant > 0) {
			let obj = {
				"paxCount": this.noOfInfant,
				"paxDiscountCode": paxDiscountCode,
				"paxType": "INF"
			}
			this.totalPaxArr.push(obj);
		}
		
	
	
  }}
  
  getBooking(searchId){
    return new Promise((resolve, reject) => {
      this.bookingService.getBookingWithBookingRef(searchId).subscribe(res => {
        let data = JSON.parse(res["_body"])['data']
        resolve(data)
      }, err => {
        reject("");
      })
  })
  }
  getSearchData(searchId){
    return new Promise((resolve, reject) => {
      this.bookingService.getSearchedData(searchId).subscribe(res => {
        let data = JSON.parse(res["_body"])['data']
        resolve(data)
      }, err => {
        reject("");
      })
  })
  }

	makeFareObj(i){
		this.selectedFares = this.data[i].fares
		if (this.selectedFares[0].priceType == "SME") {
			this.isSME = true
		}
		this.selectedPaymentMethod[i] = 'agent-balance'
		this.onlinePaymentAmount[i] = 0
		this.onlinePaymentCharge[i]=0
		this.originalNetPayable[i]=0
		this.canHold[i] = true
		this.netCommission = this.selectedFares[0].fareComponents.COMMISSION;
		this.tds = this.selectedFares[0].fareComponents.TDS;
		this.baseFare = this.selectedFares[0].base;
		this.totalFare += this.selectedFares[0].total;
		this.data[i]['status'] = ""
		this.data[i]['bookingId'] = ""
		 
		this.isLCC = this.travellerService.checkLCC(this.data[i].airline.code);
		if (this.isLCC) {
			let sKeys = []
			let outBoundskey = {
				"fareSellKey": this.selectedFares[0].supplierInfo ? this.selectedFares[0].supplierInfo.FareSellKey : "",
				"journeyKey": this.selectedFares[0].supplierInfo ? this.selectedFares[0].supplierInfo.JourneySellKey : ""
			}
			sKeys.push(outBoundskey)
			this.data[i].supplierInfo.sKeys = sKeys
			this.getBaggage(this.data[i], i);
		}
		this.data[i]['isLCC'] = this.isLCC
		this.fareData.push([])
		this.calculateNetPayable(false,i);
		this.fareData[i]['childBaseFare'] = 0
		this.fareData[i]['childTotalFare'] = 0
		this.fareData[i]['childTaxFare'] = 0
		this.fareData[i]['infBaseFare'] = 0
		this.fareData[i]['infTotalFare'] = 0
		this.fareData[i]['infTaxFare'] = 0
		if (this.selectedFares[0].travellerFares.ADT) {
			this.fareData[i]['adultBaseFare'] = this.selectedFares[0].travellerFares.ADT.base
			this.fareData[i]['adultTotalFare'] = this.selectedFares[0].travellerFares.ADT.total
			this.fareData[i]['adultTaxFare'] = this.calculatePriceService.calculateTax(this.selectedFares[0].travellerFares.ADT.fareComponents)
			this.adultBaseFare += this.selectedFares[0].travellerFares.ADT.base;
			this.adultTotalFare += this.selectedFares[0].travellerFares.ADT.total;
			this.adultTaxFare += this.calculatePriceService.calculateTax(this.selectedFares[0].travellerFares.ADT.fareComponents)
		}
		if (this.selectedFares[0].travellerFares.CHD) {
			this.fareData[i]['childBaseFare'] = this.selectedFares[0].travellerFares.CHD.base
			this.fareData[i]['childTotalFare'] = this.selectedFares[0].travellerFares.CHD.total
			this.fareData[i]['childTaxFare'] = this.calculatePriceService.calculateTax(this.selectedFares[0].travellerFares.CHD.fareComponents)
			this.childBaseFare += this.selectedFares[0].travellerFares.CHD.base;
			this.childTotalFare += this.selectedFares[0].travellerFares.CHD.total;
			this.childTaxFare += this.calculatePriceService.calculateTax(this.selectedFares[0].travellerFares.CHD.fareComponents)
		}
		if (this.selectedFares[0].travellerFares.INF) {
			this.fareData[i]['infBaseFare'] = this.selectedFares[0].travellerFares.INF.base
			this.fareData[i]['infTotalFare'] = this.selectedFares[0].travellerFares.INF.total
			this.fareData[i]['infTaxFare'] = this.calculatePriceService.calculateTax(this.selectedFares[0].travellerFares.INF.fareComponents)
			this.infBaseFare += this.selectedFares[0].travellerFares.INF.base;
			this.infTotalFare += this.selectedFares[0].travellerFares.INF.total;
			this.infTaxFare += this.calculatePriceService.calculateTax(this.selectedFares[0].travellerFares.INF.fareComponents)
		}
		// this.bookingRequestService.getCreditLimit(this.data[i]['isLCC'], this.netPayable, this.flightRequest.affiliate, 'outbound');
		if (this.data[i].supplierCode == "6E") {
			this.supplierName = "INDIGO";
		}
		else if (this.data[i].supplierCode == "SG") {
			this.supplierName = "SPICEJET";
		}
		else if (this.data[i].supplierCode == "G8") {
			this.supplierName = "GO_AIR";
		}
		else {
			this.supplierName = this.data[i].supplierCode;
		}
		if (this.data[i].supplierCode=='airasia') {
			this.warningMessage = "Free Baggage available."
		}

		let fareRuleObj = {
			"supplierName": this.data[i].fares[0].supplierInfo ? this.data[i].fares[0].supplierInfo.SUPPLIER : "",
			"classOfService": this.data[i].fares[0].supplierInfo ? this.data[i].fares[0].supplierInfo.ClassOfService : "",
			"fareBasisCode": this.data[i].fares[0].supplierInfo ? this.data[i].fares[0].supplierInfo.FareBasisCode : "",
			"ruleNumber": this.data[i].fares[0].supplierInfo ? this.data[i].fares[0].supplierInfo.RuleNumber : "",
			"signature": this.data[i].fares[0].supplierInfo ? this.data[i].supplierInfo.Signature : "",
			"supplierInfo": this.data[i].fares[0].supplierInfo
		}

		if(this.data[i].supplierCode == "SABRE") {
			fareRuleObj.supplierName = this.data[i].supplierCode;
			let supplierInfo = {
				"Origin" : this.data[i].segments[0].originAirport.code,
				"Destination" : this.data[i].segments[0].destinationAirport.code,
				"TravelDate" : this.data[i].segments[0].legs[0].supplierInfo.DepartureDateTime
			}
			fareRuleObj["supplierInfo"] = supplierInfo;
			fareRuleObj["fareBasisCode"] = this.data[i].fares[0].supplierInfo ? this.data[i].fares[0].supplierInfo.FareBasisCode : "";
		}
		else if(this.data[i].supplierCode == "AMADEUS_V4"){
			fareRuleObj['supplierName']  = "AMADEUS_V4" 
			let supplierInfo = {
				  "travellerInfo": {
					"ADT": this.noOfAdult,
					"CHD": this.noOfChild,
					"INF": this.noOfInfant
				  },
				  "sectors": [
					{
					  "departureDateTime": this.data[i].segments[0].legs[0].departureDateTime,
					  "departureCityCode": this.data[i].segments[0].legs[0].originAirport['code'],
					  "arrivalCityCode": this.data[i].segments[0].legs[0].destinationAirport['code'],
					  "airlineCode": this.data[i].segments[0].legs[0].operatingAirlineCode,
					  "flightNum": this.data[i].segments[0].legs[0].flightNumber,
					  "bookingClass": this.data[i].segments[0].legs[0].cabinClass
					}
				  ]
				}
				fareRuleObj["supplierInfo"] = supplierInfo;
		}
		this.getOutBoundFareRule(fareRuleObj,i);
	}
	
	getBaggage(selectedFlight,i){
		console.log('Selected flight before appending baggage',selectedFlight)
		// let flightRequest = JSON.parse(sessionStorage.ActiveSearchRequest)

		let supplierInfo = selectedFlight.fares[0].supplierInfo
		if (supplierInfo == null) {
			return false
		}
		let supplierName = supplierInfo.SUPPLIER
		if (supplierName == undefined) {
			supplierName = selectedFlight.airline.code
		}
		let reqObj = {
			"supplierName": supplierName,
			"classOfService": supplierInfo.ClassOfService,
			"fareBasisCode": supplierInfo.FareBasisCode,
			"ruleNumber": supplierInfo.RuleNumber,
			"signature": selectedFlight.supplierInfo.Signature,
			"supplierInfo": {
				"FareSellKey": supplierInfo.FareSellKey,
				"JourneySellKey": supplierInfo.JourneySellKey,
				"travellers":{
					"ADT": this.noOfAdult,
					"CHD": this.noOfChild,
					"INF": this.noOfInfant
				},
				"FareMap": supplierInfo,
				"FlightNumber": selectedFlight.segments[0].legs[0].flightNumber,
				"BoardPoint": selectedFlight.segments[0].legs[0].originAirport.code,
				"OffPoint": selectedFlight.segments[0].legs[0].destinationAirport.code,
				"DepartureDateTime": selectedFlight.segments[0].legs[0].departureDateTime,
				"ArrivalDateTime": selectedFlight.segments[0].legs[0].arrivalDateTime
			}
		 }

		  
		 console.log('Baggage req :',reqObj)
		 this.travellerService.getBaggage(reqObj).subscribe(res=>{
			let freeCheckInBaggage = JSON.parse(res['_body'])
			let segment = selectedFlight.segments
			for (let i = 0; i < segment.length; i++) {
				const legs = segment[i].legs;
				for (let j = 0; j < legs.length; j++) {
					const elm = legs[j];
					if (!elm.hasOwnProperty('freeCheckInBaggage')) {
						elm['freeCheckInBaggage'] = freeCheckInBaggage
					}
					else{
						if (elm.freeCheckInBaggage == null || elm.freeCheckInBaggage == undefined) {
							elm['freeCheckInBaggage'] = freeCheckInBaggage
						}
					}
					
				}
				
			}

			selectedFlight.segments = segment
			console.log('Selected flight after appending baggage',this.data[i])
		},
		err=>{
			console.log('Error',err)
		})
	}

	getOutBoundFareRule(fareRuleObj,i){
		let fareRule = ""
		this.postService.postMethod(`${environment.url}/getFareRule`, fareRuleObj).subscribe(data => {
			try{
				if(data != ""  && data != null) {
					fareRule = data['fareRule'];
				}
				else{
					fareRule = "No Fare Rule Found";
				}
				this.data[i]['fareRule'] = fareRule
				console.log('fareRule ',data)
			}
			catch(Exception){
				console.log(Exception)
			}
			
		
		}, error => {
			fareRule = "No Fare Rule Found";
			this.data[i]['fareRule'] = fareRule
		})
	}
	// expansion panel for stepper
	async setStep(index: number) {
		this.step = index;
		window.scroll(0, 0)
		if (index == 2) {
			this.paxSubmitted = true;
			const invalid = [];
			const controls = this.travellerForm.controls.passengerList['controls'];
			for (let i = 0; i < controls.length; i++) {
				for (const name in controls[i].controls) {
					if (controls[i].controls[name].invalid && name !="passportNo" && name !="nationality" && name !="issuingCountry" && name != "expiryDate") {
						invalid.push(name);
					}
					if (name == "passportNo" && controls[i].controls['passportNo'].hasError('pattern') && controls[i].controls['passportNo'].touched) {
						invalid.push('passportNo');
					}
					if (name == "expiryDate" && (controls[i].controls['expiryDate'].errors || controls[i].controls['expiryDate'].value ==null)  && controls[i].controls['passportNo'].value != "" && controls[i].controls['passportNo'].touched) {
						invalid.push('expiryDate');
					}
				}
			}
			if (invalid.length > 0) {
				this.setStep(1)
			}
			else {
				 
				// this.setPersonalDetails()
				let requestData = await this.bookingServiceRequest.multiCityBookingRequest(this.travellerForm.value, this.data, this.limitType,0);
				this.getSSR(requestData,0)
				
			}

		}
		else if (index == 3) {
			this.contactSubmitted = true
			const invalid = [];
			const controls = this.travellerForm.controls;
			for (const name in controls) {
				if (controls[name].invalid && name != "passengerList") {
					invalid.push(name);
				}
			}
			if (invalid.length > 0 && !invalid.includes('corporateEmail')) {
				this.setStep(2)
			}
		}else if (index == 4 ) {
			const invalid = [];
			this.contactSubmitted = true
			const controls = this.travellerForm.controls;
			for (const name in controls) {
				if (controls[name].invalid && name != "passengerList") {
					invalid.push(name);
				}
			}
			console.log('invalid field in step 3',invalid)
			if(invalid.length  > 0 && invalid.includes('corporateEmail')) {
				this.setStep(3)
			}
		}
	}

	// create passenger data object for booking request
	createPassengerData(passengerList, ticketNo) {
		return passengerList.map((v, i) => {
			let count = i + 1;
			v.ticketNo = ticketNo + count;
			return v;
		})
	}
	// create after booking request

	// create after booking pax details

	// traveller form validations
	get corporateEmail() {
		return this.travellerForm.controls.corporateEmail;
	}
	get corporateMobile() {
		return this.travellerForm.controls.corporateMobile;
	}
	// create dummy object for adult
	bindDummyAdultArr(value) {
		this.dummyAdultNameArr = [];
		this.travellerForm.controls.passengerList.value.forEach(el => {
			if (el.passengerType == 'Adult') {
				this.dummyAdultNameArr.push(el.firstName);
			}
		});
	}
	// function to revert back into search form page
	onFinished() {
		let searchId = localStorage.getItem('flightSearchId');
		this.router.navigate(['pages/flight', { searchId }]);
	}

	getBaggageDetails(code) {
		this.affiliateService.getBaggageDetails(code).subscribe(res => {
			this.baggageArr = res;
			// console.log('baggage data', this.baggageArr)
		})
	}

	bindGSTInfo(val) {
		val = val.currentTarget.value;
		if (val.length >= 3) {
			this.postService.getMethod(`${environment.adminUrl}/common/suggest/gstDetails/${val}`).subscribe(res => {
				let data = JSON.parse(res['_body']);
				let uniqueArr = [];
				data.forEach(el => {
					let filteredArr = uniqueArr.filter((v) => {
						if (v.name == el.name) {
							return v;
						}
					})
					if (filteredArr.length == 0) {
						uniqueArr.push(el)
					}
				});
				this.gstArr = uniqueArr;
			})
		}
	}
	bindTravellerGstName(e) {
		this.travellerForm.controls.corporateName.setValue(e.target.value);
	}
	setGstDetails(values) {
		this.travellerForm.controls.corporateName.setValue(values.name);
		this.travellerForm.controls.corporateGstNumber.setValue(values.gst_number);
		this.travellerForm.controls.corporateMobile.setValue(values.mobile);
		this.travellerForm.controls.corporateEmail.setValue(values.email);
	}

	baggageOnChange(el) {
		let filteredData = this.baggageArr.filter((v) => {
			if (el.value == v.id) {
				return v;
			}
		})
		if (filteredData.length > 0) {
			this.selectedBaggage = filteredData;
			this.showBaggage = true;
			this.totalFare += this.selectedFares[0].total + this.selectedBaggage[0].PRICE;
			this.total = this.selectedFares[0].total + this.selectedBaggage[0].PRICE;
		}
		else {
			this.selectedBaggage = [];
			this.showBaggage = false;
			this.totalFare += this.selectedFares[0].total;
			this.total = this.selectedFares[0].total;
		}
		// console.log(filteredData)
	}

	getSSR(requestData,i) {
		let fullArr: any = [];
		const lThis = this
		if (this.data[i]['isLCC']) {
			this.commonService.getmeal('ssrAvailability', requestData).subscribe(res => {
				fullArr = res;
				let legs = [];
				// this.airlineCode
				
				// if(fullArr['legs'][0].hasOwnProperty('infant')) {
				// 	if (fullArr.legs[0].infant != null) {
				// 		let updateInfantPrice = fullArr.legs[0].infant.newPrice;
				// 		if (updateInfantPrice != lThis.data[0].fares[0].travellerFares['INF']['total']) {

				// 			this.isIncreaseInfantPrice = false;
				// 			this.currentInfantPrice = 0;
				// 			this.increasedInfantPrice = 0;

				// 			lThis.isIncreaseInfantPrice = true;
				// 			lThis.currentInfantPrice = fullArr.legs[0].infant.oldPrice;
				// 			lThis.increasedInfantPrice = updateInfantPrice;
				// 			lThis.infTotalFare = updateInfantPrice;
				// 			lThis.infTaxFare = updateInfantPrice;
				// 			try {
				// 				if(fullArr.legs[0].infant.hasOwnProperty('actualCurrencyValue')) {
				// 					let obj = {
				// 						actualCurrencyValue : fullArr.legs[0].infant['actualCurrencyValue']
				// 					}
				// 					lThis.data[0].fares[0].travellerFares['INF']['supplierInfo'] = obj
				// 				}
								
				// 			} catch (error) {
				// 				console.log(error);
				// 			}
				// 			let infantPriceDifference = updateInfantPrice - lThis.currentInfantPrice;
				// 			lThis.data[0].fares[0].travellerFares['INF']['total'] = updateInfantPrice
				// 			lThis.data[0].fares[0].travellerFares['INF']['fareComponents']['OT'] = updateInfantPrice;
				// 			if (lThis.data[0].fares[0].fareComponents.hasOwnProperty('NET_PAYABLE')) {
				// 				lThis.data[0].fares[0].fareComponents.NET_PAYABLE = lThis.data[0].fares[0].fareComponents.NET_PAYABLE + infantPriceDifference;
				// 			}
				// 			lThis.data[0].fares[0].total = lThis.data[0].fares[0].total + infantPriceDifference;
				// 			this.totalFare = lThis.data[0].fares[0].total;
				// 			localStorage.setItem('selectedFlight', JSON.stringify(lThis.data[0]));
				// 			lThis.calculateNetPayable();
				// 		}
				// 	}
				// }
				// this.ssrAvailability = Object.values(fullArr.legs);
				for (let i = 0; i < fullArr['legs'].length; i++) {
					const meals = Object.values(fullArr['legs'][i].meals)
					for (let j = 0; j < meals.length; j++) {
						const element = meals[j];
						element['status'] = 'notselected'
						const el = element['fare']
						if (el.hasOwnProperty("base")) {
							element['totalFare'] = Number(el.base);
						}
						else {
							element['totalFare'] = 0
						}


					}
					const baggages = Object.values(fullArr['legs'][i].baggages)
					for (let j = 0; j < baggages.length; j++) {
						const element = baggages[j];
						element['status'] = 'notselected'
						const el = element['fare']

						if (el.hasOwnProperty("base")) {
							// element['totalFare'] = Number(el.base) + Number(el.tax);
							element['totalFare'] = Number(el.base);
						}
						else {
							element['totalFare'] = 0
						}
						if (element['totalFare'] == 0) {
							this.hasFreeBaggage = true
						}
					}
					let baggageAfterSorting = baggages.sort(function (a, b) {
						return a['totalFare'] - b['totalFare'];
					})
					let obj = {};
					obj["meals"] = meals;
					obj["baggages"] = baggageAfterSorting;
					obj["arrival"] = fullArr['legs'][i].arrival;
					obj["totalSelected"] = 0;
					obj["travellerCount"] = requestData.travellers.length;
					obj["flightInfo"] = requestData
					let tempTravellerArr = [];
					for (let k = 0; k < requestData.travellers.length; k++) {
						let tempTraveller = {};
						tempTraveller = Object.assign({}, requestData.travellers[k]);
						tempTravellerArr.push(tempTraveller);
					}
					obj["travellers"] = tempTravellerArr;
					obj["departure"] = fullArr['legs'][i].departure;
					obj["flightCode"] = fullArr['legs'][i].airline;
					obj["flightNo"] = fullArr['legs'][i].flightNo;
					legs.push(obj);
				}
				lThis.ssrAvailability = legs;
				
				if (lThis.ssrAvailability.length == 0) {
					lThis.showloadError = true
				}
				console.log('Meal Array', lThis.ssrAvailability);
			}, error => {
				lThis.showloadError = true
				console.log('Fetch Error', error);
			})
		
		
		this.commonService.getSeat('seatMap', requestData).subscribe(res => {
			let seatMap = res;
			let legs = [];
			for (let i = 0; i < seatMap['leg'].length; i++) {
				if(seatMap['leg'][i].seats.rows != null) {
					const values = Object.values(seatMap['leg'][i].seats.rows)
					for (let j = 0; j < values.length; j++) {
						const element = values[j];
						let charges = Object.values(element)
						for (let k = 0; k < charges.length; k++) {
							const ch = charges[k]
							ch['totalFare'] = 0;
							ch['status'] = 'notselected'
							const el = ch.charges
							if (el.hasOwnProperty("base")) {
								ch['totalFare'] = Number(el.base);
							}
							else {
								ch['totalFare'] = 0;
							}		
						}
					}
					let obj = {};
					obj["keys"] = Object.keys(seatMap['leg'][i].seats.rows);
					obj["values"] = values;
					obj["arrival"] = seatMap['leg'][i].arrival;
					obj["totalSelected"] = 0;
					obj["travellerCount"] = requestData.travellers.length;
					let tempTravellerArr = [];
					let associateTravellerInfoMap = new Map();
					for (let k = 0; k < requestData.travellers.length; k++) {
						let tempTraveller = {};
						tempTraveller = Object.assign({}, requestData.travellers[k]);
						tempTravellerArr.push(tempTraveller);
						if(this.travellerForm.controls.passengerList['controls'][k].controls.passengerType.value == "Infant"){
							associateTravellerInfoMap.set(this.travellerForm.controls.passengerList['controls'][k].controls.associateInfo.value, "")
						}


					}
					obj['associateTravellerInfoMap'] = associateTravellerInfoMap;
					obj["travellers"] = tempTravellerArr;
					obj["flightInfo"] = requestData
					obj["departure"] = seatMap['leg'][i].departure;
					obj["flightCode"] = seatMap['leg'][i].flightCode;
					obj["flightNo"] = seatMap['leg'][i].flightNo;
					legs.push(obj);
				}
				
				
			}
			this.seatMapArr = legs;
			// console.log('Seat Map', JSON.stringify(this.seatMapArr));
		}, error => {
			this.showseatloadError = true
			console.log('Fetch Error', error);

		})
		}

	
	}
	openSearch(){
		let searchId = localStorage.searchId+"_RETURN_"+localStorage.returnSearchId+"_RETURN_"+localStorage.specialReturnSearchId
		// window.location("/#/pages/flight;searchId="+searchId);
		this.router.navigate(['/pages/flight', { searchId }]);
	}
	setGST(values){
		if (values != null && values != undefined) {
			this.setGstDetails(values);
			setTimeout(() => {
				this.closeactiveModal();
			}, 100)
		}
	}
	private closeactiveModal(): void {
		this.closeactiveBtn.nativeElement.click();
	}

	setPaymentMethod(paymentMethod,checkNetPayable,i){
		this.selectedPaymentMethod[i] = paymentMethod
		if (paymentMethod != 'agent-balance') {
			this.canHold[i] = false
		}else{
			this.canHold[i] = true
		}
		console.log('Payment method : ',this.selectedPaymentMethod)
		if (checkNetPayable == true) {
			this.calculateNetPayable(false,i)
		}
	}

	async doBooking(values,isHold,i){
		let bookingRes = await this.creatingBookingRequest(values, this.data,i);// calling to create an live booking
		if (localStorage.getItem('previuosBookingData') != null && localStorage.getItem('previuosBookingData') != undefined) {
			this.data = JSON.parse(localStorage.getItem('previuosBookingData'))
		}
		this.data[i]['bookingId'] = bookingRes['bookingId']
		// this.saveTravellers(values)
		this.bookingRequestService.checkifExistGST(values)

		if (bookingRes != "" && bookingRes['bookingResponse'] != null) {
			values.bookingRefNo = bookingRes['bookingResponse']['bookingId'];
		
			if (isHold) {
				values['bookingStatus'] = "HOLD";
				values.bookingInvoice = '';
				this.data[i]['status']  = "HOLD";
			}
			else {
				values['bookingStatus'] = "TICKETED";
				this.data[i]['status']  = "TICKETED";
				values.bookingInvoice = bookingRes['bookingResponse']['invoice_no'];
			
			}
			values.pnrNo = bookingRes['bookingId'];
			values['message'] = "Success";
			values['gdsPnr'] = bookingRes['pnr'];
			if (bookingRes['bookingResponse'].hold == true) {
				values['ticketedIssue'] = bookingRes['message']
				values['bookingStatus'] = "HOLD";
				this.data[i]['status']  = "HOLD";
			}
			values['bookingResp'] = bookingRes['bookingResponse'];
			this.OnWardsBookingResJson = bookingRes['bookingResponse'];
			this.data[i]['bookingId'] = bookingRes['bookingResponse']['bookingId']
			this.data[i]['invoice_no'] = bookingRes['bookingResponse']['invoice_no']
			localStorage.setItem('previuosBookingData',JSON.stringify(this.data))
			localStorage.setItem("MULTI_CITY_LCC_BOOKING_"+this.data[i]['bookingId'], JSON.stringify(this.data));
			// localStorage.setItem("MULTI_CITY_LCC_BOOKING_" + values.bookingRefNo, JSON.stringify(this.OnWardsBookingResJson));
			// this.bookingService.sendSms(this.OnWardsBookingResJson)
			this.active = false;
			this.paymentIndex = Number(localStorage.getItem('selectedFlightIndexForPayment'))+1
			let searchId = this.data[i]['bookingId'];
			this.step = 5
			this.setStep(5)
			if (this.data.length-1 == i) {
				localStorage.removeItem('previuosBookingData')
				localStorage.removeItem('selectedFlightIndexForPayment')
				this.router.navigate(['booking-status', { searchId }]);
			}
			this.active = false;
		}
		else {

			values['bookingStatus'] = "FAILED";
			values['message'] = bookingRes['message'];
			values.pnrNo = '';
			values['gdsPnr'] = '';
			values.bookingInvoice = '';

			values.bookingRefNo = bookingRes['bookingId'];
			// this.OnWardsBookingResJson = this.bookingRequestService.getBookingRequest(values, this.request, this.supplierName, this.data[i]['isLCC']);
			// localStorage.setItem("ONWARDS_BOOKING_"+res['bookingResponse'].bookingId, JSON.stringify(res['bookingResponse']));
			this.data[i]['status'] = "FAILED"
			localStorage.setItem("MULTI_CITY_LCC_BOOKING_"+this.data[i]['bookingId'], JSON.stringify(this.data));
			// localStorage.setItem("MULTI_CITY_LCC_BOOKING_" + values.bookingRefNo, JSON.stringify(this.OnWardsBookingResJson));
			console.log("Booking Failed");
			let searchId = this.data[i]['bookingId']
			localStorage.removeItem('previuosBookingData')
			localStorage.removeItem('selectedFlightIndexForPayment')
			this.router.navigate(['booking-status', { searchId }]);
			this.active = false;
		}
	}


	doOnlinePayment(){
		this.active = true
		let paymentMethod = ""
		localStorage.setItem('selectedFlightDirectionForPayment',this.selectedFlightDirectionForPayment)
		if (this.selectedPaymentMethod[this.selectedFlightDirectionForPayment] == 'credit-card') {
			paymentMethod = "OPTCRDC"
		}else if (this.selectedPaymentMethod[this.selectedFlightDirectionForPayment] =='netbanking') {
			paymentMethod = "netbanking"
		}else if (this.selectedPaymentMethod[this.selectedFlightDirectionForPayment] =='debit') {
			paymentMethod = "debit"
		}
		let redirectUrl = `http://localhost:4200/#/pages/multi-city-lcc-traveller`
		if (environment.name != 'default') {
			redirectUrl = `${environment.baseUrl}/#/pages/multi-city-lcc-traveller`
		}
		// let amount_charged = Number(this.netPayable) + (Number(this.netPayable) * Number(environment.payment_gateway_charges)) / 100;
		let limitType = 'cash'
		if(!this.isDiAccount && !this.isLCC){
			limitType = 'credit'
		}
		let amount = 1
		if (environment.name =='prod') {
			amount = this.onlinePaymentAmount[this.selectedFlightDirectionForPayment]
		}
		if (this.affiliateDetails != null && this.affiliateDetails != undefined) {
			let obj = {
				"affiliateCode" : this.affiliateDetails.xlAccountCode,
				"affiliateId" : this.affiliateDetails.affiliateId,
				"redirectUrl" : redirectUrl,
				"amount" : amount,
				"order_id" : "PT500069",
				"mobile" : this.affiliateDetails.mobile,
				"email" : this.affiliateDetails.primaryEmail,
				"payment_mode" : paymentMethod,
				"limit_type" : limitType,
				"original_amount" : this.originalNetPayable[this.selectedFlightDirectionForPayment]
			}

			if (this.selectedPaymentMethod[this.selectedFlightDirectionForPayment] == 'credit-card') {
				this.travellerService.doHDFCPayment(obj)
			}else{
				this.travellerService.doFTCASHPayment(obj)
			}
		}
	}


	private openDuplicateBookingModal(): void {
		this.showDuplicateBooking.nativeElement.click();
	}

	viewBooking(reference){
		window.open(`${environment.baseUrl}/#/booking-summary-detail;searchId=${reference}`, "_blank");
	}
}